import React, { useEffect, useState, useCallback, useRef } from "react"
import _ from "lodash"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Accordian from "react-bootstrap/Accordion"
import Spinner from "react-bootstrap/Spinner"
import Dropdown from "react-bootstrap/Dropdown"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import UploadFiles2 from "../courses/upload-files2"
import Blog from "../blogs/create-edit"

import { Rnd } from "react-rnd"
import SunEditor from "suneditor-react"
import Datetime from "react-datetime"

import authClient from "../../services/auth"
// import hideMessage from '../../services/hidemessage';
import utcDateTime from "../../services/utcdatetime"
import { getRequest, putRequest, postRequest } from "../../services/rest_service"
import { isBrowser } from "../../services/developer_mode"

import TimeDifference from "../utilities/timeDifference"
import UploadFiles from "../courses/upload-files"
import Btn from "../custom/Button"
import AvatarCircle from "../custom/AvatarCircle"
import PopQuizResults from "../pop-quiz/popQuiz-results"
import ViewDetailsModal from "../breakout-session-details/breakout-session-view-detali"
import OverlayTooltip from "../custom/OverlayTooltip"
import HorizontalScroll from "../custom/HorizontalScroll"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faCommentAlt,
  faEllipsisV,
  faHeart,
  faImage,
  faPlay,
  faRedo,
  faLink,
  faVideo,
  faWindowMinimize,
  faWindowMaximize,
  faChevronLeft,
  faChevronRight,
  faTimesCircle,
  faExclamationTriangle,
  faTimes,
  faClock,
  faSpinner,
  faCheck,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons"
import fullscreenSvg from "../../images/svgs/fullscreen_svg.svg"
import minimizeSvg from "../../images/svgs/minimize_svg.svg"
import CheckIcon from "../../images/svgs/check-white.svg"

import ArrowWhiteCircle from "../../images/svgs/ArrowWhiteCircle.svg"
import documents from "../../images/svgs/documentresWhite.svg"

import happy from "../../images/svgs/happy.svg"
import love from "../../images/svgs/love.svg"
import bored from "../../images/svgs/bored.svg"
import sleepy from "../../images/svgs/sleepy.svg"

import BigQuestion from "../../images/svgs/BigQuestion.svg"
import CancelWhite from "../../images/svgs/close.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import CancelIcon from "../../images/svgs/cancel-white-icon.svg"
import BreakoutSessionIcon from "../../images/svgs/lesson_board_breakout_session_icon.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"
import muralIcon from "../../images/svgs/mural-icon.svg"
import linkIcon from "../../images/svgs/link.svg"
import SearchIcon from "../../images/svgs/search.svg"

import Style from "./lesson-board.module.scss"
import StylesA from "../template-resources/breakout-session.module.scss"
import Styles from "../../modularscss/styles.module.scss"
import StylesM from "../../components/upload-resource.module.scss"
import StylesB from "../courses/course.module.scss"
import StylesC from "../template-resources/classroom-resource-section.module.scss"

import ClassRoomPoll from "../../components/class-room-training/classroom-poll"

// import loadbale component here
import Loadable from "@loadable/component"
import WhiteBlogIcon from "../../images/svgs/blog-white-icon.svg"
import WhiteQuizIcon from "../../images/svgs/quiz-white-icon.svg"
import WhitePollIcon from "../../images/svgs/polls-white-icon.svg"
import Notification from "../custom/Notification"
import { WsConnect } from "../../services/socket"
import Loader from "../custom/Loader"
import getImageToBeRendered from "../utilities/resourceIcons"
import QuestionBox from "../question-answer/QuestionBox"

import LiveDiscussion from "../live-discussion-board/live-discussion"

// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
const VideoCard2 = Loadable(() => import("../videocards/videocard-control"))
const CaseStudyModeration = Loadable(() => import("../case-study/moderation"))
const QuizModeration = Loadable(() => import("../quizzes/moderation"))
const CheckListModeration = Loadable(() => import("../checklist/moderation"))
const CheckListReportCard = Loadable(() => import("../checklist/report-card"))
const QASessionTopicPicklist = Loadable(() => import("./qa-session-topic-picklist"))

const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  // background: '#42506C',
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const DateInputField = (props) => {
  const [inputValue, setInputValue] = useState(props.datum.start_date ? new Date(props.datum.start_date) : "")

  // useEffect(() => {
  //   if ( inputValue && inputValue._isAMomentObject ) {
  //     props.handleDurationSave(props.datum, inputValue, 'start_date', 'module')
  //   }
  // }, [inputValue])

  useEffect(() => {
    if (props.selectedInput === "module_" + props.datum.id + "start_date") {
      setInputValue(props.datum.start_date ? new Date(props.datum.start_date) : "")
    }
  }, [props.datum.start_date])

  function sendQuery(name, value, datum, type) {
    props.handleDurationSave(datum, value, name, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  return (
    <Col
      lg="3"
      className={`${StylesA.border_on_hover} ml-2`}
      style={{
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#42506C",
        border: "1px solid transparent",
      }}
    >
      <Datetime
        dateFormat={false}
        timeFormat="hh:mm A"
        closeOnSelect={true}
        onChange={(e) => {
          setInputValue(e)
          delayedQuery("start_date", e, props.datum, "module")
        }}
        value={inputValue}
        inputProps={{ placeholder: "Start time", className: "video-modal p-0" }}
        style={{ background: "inherit", color: "inherit", height: "100%" }}
        className={`module_start_time_picker blue-bg video-modal`}
      />
    </Col>
  )
}

function SeekSupportInsrtuctionsReply(props) {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  // const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ''

  const [clickedSeekSuppostReplay, setClickedSeekSuppostReplay] = useState(false)
  const [trainerReplay, setTrainerReplay] = useState("")
  const [sentToStudent, setSentToStudent] = useState(false)
  const [sentToGroup, setSentToGroup] = useState(false)
  const [showSendToGroupModal, setShowSendToGroupModal] = useState(false)

  useEffect(() => {
    setSentToStudent(false)
    setSentToGroup(false)
  }, [trainerReplay])

  function handleSubmitReplay(e, type) {
    e.preventDefault()

    var formData = new FormData()
    formData.append("value", trainerReplay)
    formData.append("question", props?.data?.value)
    formData.append("group_name", props.data.group_name)
    if (type === "to_student") {
      formData.append("send_student", props.data.student_id)
    }
    if (props.data.group_id != null) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-groups-web-socket/` + props.data.group_id + "/reply_seek_support/", formData, {
          headers: DefaultHeader,
        })
        .then((res) => {
          if (type === "to_student") {
            setSentToStudent(true)
          } else if (type === "to_group") {
            setSentToGroup(true)
            setShowSendToGroupModal(false)
          }
          // setClickedSeekSuppostReplay(false)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div>
      <div className="px-0 mr-3 w-100 d-inline-flex">
        <div
          className={`px-3 py-1 pointer border_on_hover float-left`}
          style={{
            marginBottom: "0px",
            fontSize: "16px",
            color: "#E0E2E5",
            backgroundColor: "#303C54",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "4px",
          }}
          onClick={(e) => {
            setClickedSeekSuppostReplay(true)
          }}
        >
          Reply
        </div>
      </div>
      <Modal
        show={clickedSeekSuppostReplay}
        style={{ zIndex: "10000" }}
        centered
        size="lg"
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal p-0`}
      >
        <Modal.Body
          className="float-left p-3"
          style={{
            backgroundColor: "#212C42",
            borderRadius: "8px 8px 8px 8px",
            maxHeight: "400px",
            overflowY: "scroll",
          }}
        >
          <div className={`d-inline-flex w-100`}>
            <div
              className={`mr-2 ${Style.dangerous_data}`}
              style={{ fontSize: "16px", fontWeight: "500", color: "#E0E2E5" }}
              dangerouslySetInnerHTML={{ __html: props.data.value }}
            />
            <p
              className={`mb-0 mr-2`}
              style={{
                color: "#E0E2E570",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              Asked by <u style={{ fontWeight: "bold" }}>{props.data.student_name}</u> From{" "}
              <span style={{ fontWeight: "bold" }}>
                <u>{props.data.group_name}</u>
              </span>
            </p>
            {/* <p className={`mb-0`} style={{color: '#E0E2E570',fontSize:'14px',fontWeight:'500'}} >45 Mins ago</p> */}
            <img
              onClick={(e) => {
                setClickedSeekSuppostReplay(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`ml-auto mr-1 mb-0 pointer`}
              style={{ objectFit: `contain`, height: `20px` }}
            />
          </div>
          <Col
            className="px-0 border_on_hover pl-0"
            style={{
              backgroundColor: "#303C54",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "9px",
              border: "1px solid #303C54",
              marginBottom: "12px",
              marginTop: "12px",
            }}
          >
            <div className={`w-100 mb-3 d-inline-flex align-items-center`}>
              <SunEditor
                id="replay"
                placeholder="Type here"
                onChange={setTrainerReplay}
                setContents={trainerReplay}
                setOptions={{
                  height: "auto",
                  maxHeight: "100px",
                  minHeight: "100px",
                  backgroundColor: "#212C42",
                  buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
                }}
              />
            </div>
          </Col>
          <div className={`d-inline-flex`}>
            {!sentToStudent ? (
              <button
                className={`poppins_font ${Style.breakout_modal_fotter_btn_inactive} border_on_hover float-left px-3 py-1 mt-2 mr-2`}
                onClick={(e) => {
                  handleSubmitReplay(e, "to_student")
                }}
              >
                <p className={`mb-0 poppins_font`} style={{ fontSize: "14px", fontWeight: "500" }}>
                  Send To Student{" "}
                </p>
              </button>
            ) : (
              <div className="d-flex pr-3" style={{ alignItems: "center" }}>
                <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                <p style={{ fontSize: "14px", margin: "0px", color: "#E0E2E5" }}>Sent to student</p>
              </div>
            )}
            {!sentToGroup ? (
              <button
                className={`poppins_font ${Style.breakout_modal_fotter_btn_inactive} border_on_hover float-left py-1 px-3 mt-2`}
                onClick={(e) => {
                  setShowSendToGroupModal(true)
                }}
              >
                <p className={`mb-0 poppins_font`} style={{ fontSize: "14px", fontWeight: "500" }}>
                  Send To Group{" "}
                </p>
              </button>
            ) : (
              <div className="d-flex pr-3" style={{ alignItems: "center" }}>
                <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                <p style={{ fontSize: "14px", margin: "0px", color: "#E0E2E5" }}>Sent to Group</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSendToGroupModal}
        onHide={() => {
          setShowSendToGroupModal(false)
        }}
        style={{ zIndex: "10000" }}
        centered
        size="md"
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal p-0`}
      >
        <Modal.Body
          className="float-left text-center p-3"
          style={{
            backgroundColor: "#121B2B",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <p className={`mb-3`} style={{ color: "#E0E2E5", fontSize: "19px" }}>
            Are you sure you want to send to {props.data.group_name} ?
          </p>
          {/* <p className={`mb-3`} style={{color: '#E0E2E5',fontSize:'16px'}} >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et</p> */}
          <img
            onClick={(e) => {
              setShowSendToGroupModal(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`ml-auto mr-1 mb-0 pointer`}
            style={{
              objectFit: `contain`,
              height: `20px`,
              position: "absolute",
              top: "7px",
              right: "7px",
            }}
          />
          <div className={`w-100 mb-2`}>
            <button
              className={`poppins_font mx-auto px-4 py-2 border_on_hover`}
              onClick={(e) => {
                handleSubmitReplay(e, "to_group")
              }}
              style={{
                backgroundColor: "#42506C",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "9px",
                border: "1px solid #E0E2E5",
                color: "#E0E2E5",
              }}
            >
              <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
                Yes, send to group
              </p>
            </button>
          </div>
          <div className={`w-100`}>
            <button
              className={`poppins_font px-5 py-2 ${Style.breakout_modal_fotter_btn_inactive} border_on_hover`}
              onClick={(e) => {
                setShowSendToGroupModal(false)
              }}
              style={{
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "9px",
              }}
            >
              <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
                No, don't send
              </p>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

function ExtendTimeReply(props) {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  // const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ''

  const [clickedExtendTime, setClickedExtendTime] = useState(false)
  const [duration, setDuration] = useState(null)

  useEffect(() => {
    if (props.duration) {
      setDuration(parseInt(props.duration))
    }
  }, [props.duration])

  function handleSubmitReplay(e, type) {
    e.preventDefault()
    let new_duration = parseInt(duration) + parseInt(props.breakoutSessionActive.duration)
    let dummy = { ...props.modulesData }
    let session_topic = dummy.module_Doing.topics.filter((topic) => topic.id === props.breakoutSessionActive.topic)[0]
    if (session_topic) {
      var index = session_topic.breakout_sessions.findIndex((res) => res.id === parseInt(props.session_id))
      session_topic.breakout_sessions[index].duration = new_duration
      props.updateModulesData(dummy)
    }
    var formData = new FormData()
    formData.append("duration", new_duration)
    formData.append("template", props.template_id)
    if (props.session_id != null) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-session/` + props.session_id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          setClickedExtendTime(false)
          props.openNotification({
            type: "success",
            title: "Breakout Session time extended",
          })
          let dummy1 = { ...res.data }
          dummy1.duration = new_duration
          dummy1.start_time = props.breakoutSessionActive.start_time
          props.handleActiveBreakoutSession({ ...dummy1 })
          props.handleBreakoutSessionActive({ ...dummy1 })
          props.dateTime(dummy1)
          props.setShowSuggestionModal(false)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  return (
    <div className={`w-100 align-items-center`}>
      <div className="px-0 d-inline-flex w-100">
        <div
          className={`px-3 pointer border_on_hover py-1 float-left`}
          style={{
            marginBottom: "0px",
            fontSize: "16px",
            color: "#E0E2E5",
            backgroundColor: "#303C54",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "4px",
          }}
          onClick={(e) => {
            setClickedExtendTime(true)
          }}
        >
          Extend Time
        </div>
      </div>
      <Modal
        show={clickedExtendTime}
        onHide={() => {
          setClickedExtendTime(false)
        }}
        style={{ zIndex: "10000" }}
        centered
        size="md"
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal p-0`}
      >
        <Modal.Body
          className="float-left text-center p-3"
          style={{
            backgroundColor: "#212C42",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          {props.type === "suggestion" ? null : (
            <p className={`mb-3`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
              <u style={{ fontWeight: "bold" }}>{props.student_name}</u> From{" "}
              <span style={{ fontWeight: "bold" }}>
                <u>{props.group_name}</u>
              </span>{" "}
              needs {props.duration} mins more
            </p>
          )}
          <img
            onClick={(e) => {
              setClickedExtendTime(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`ml-auto mr-1 mb-0 pointer`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          />
          <div
            className={`d-inline-block text-center py-3 px-5`}
            style={{
              backgroundColor: "#42506C",
              borderRadius: "9px",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
              Extend time by
            </p>
            <Form
              onSubmit={(e) => {
                handleSubmitReplay(e, "to_group")
              }}
            >
              <input
                type="number"
                min="1"
                defaultValue={parseInt(duration)}
                onChange={(e) => {
                  setDuration(e.target.value)
                }}
                className={`${StylesA.session_input_field} mx-auto form-control shadow-none px-2 ${StylesA.border_on_hover}`}
                style={{ width: "60px", fontSize: "36px", fontWeight: "500" }}
              />
              <p className={`mb-3`} style={{ color: "#E0E2E570", fontSize: "16px" }}>
                Mins
              </p>
              <button type="submit" className={`poppins_font px-4 py-1 ${Style.breakout_modal_fotter_btn_inactive} border_on_hover`}>
                <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
                  Extend{" "}
                </p>
              </button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Col className='border_on_hover px-2 py-3 d-inline-block' style={{backgroundColor:'#303C54',boxShadow:'0px 3px 6px #00000029',borderRadius:'9px',border:'1px solid #303C54', marginBottom: '12px', marginTop: '12px'}}>
          <div className={`w-100 mb-2 d-inline-flex align-items-center`}>
            <div style={{display:'flex',alignItems:'center',borderRadius:'8px',cursor:'text'}} className={`${StylesA.session_input_field} ${StylesA.session_number_field} px-2 ${StylesA.border_on_hover} ${Styles.font_16}`}>
              Duration: <input type='number' defaultValue={duration} onChange={(e) => {setDuration(e.target.value)}}  className={`${StylesA.session_input_field} form-control shadow-none ${StylesA.border_on_hover} px-3 mx-2 ${Styles.font_16}`} style={{width:'80px'}} /> Mins
            </div>
          </div>
          <div>
            <button className={`poppins_font ${Style.breakout_modal_fotter_btn_inactive} border_on_hover float-left px-3`} onClick={(e) => {handleSubmitReplay(e,'to_group')}}><p className={`mb-0 poppins_font`} style={{fontSize:'14px',fontWeight:'500'}}>Extend </p></button>
          </div>
        </Col> */}
    </div>
  )
}

function durationFormat(duration) {
  if (duration !== 0) {
    let seprateDuration = duration.split(":")
    if (seprateDuration[0] === "00") {
      return duration.substring(3)
    }
  } else return null
  return duration
}

const DurationInputField = (props) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [timeType, setTimeType] = useState("Mins")
  const [inputValue, setInputValue] = useState(props.datum.duration)

  // useEffect(() => {
  //   if (inputValue !== props.datum.duration) {
  //     let current_value = timeType === 'Mins' ? inputValue : inputValue * 60
  //     props.handleDurationSave(props.datum,current_value,'duration','module')
  //   }
  // }, [inputValue])
  useEffect(() => {
    if (props.selectedInput === "module_" + props.datum.id + "duration") {
      setInputValue(props.datum.duration)
    }
  }, [props.datum.duration])

  function sendQuery(name, value, datum, type) {
    props.handleDurationSave(datum, value, name, type)
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => {
      let current_value = timeType === "Mins" ? value : value * 60
      sendQuery(name, current_value, datum, type)
    }, 1000),
    []
  )

  return (
    <div className="col-3 px-0" style={{ position: "relative" }}>
      {showDropDown ? (
        <input
          type="number"
          min="1"
          name="duration"
          autoFocus
          autoComplete="anyrandomstring"
          id={props.datum.id + `_module_duration`}
          value={inputValue}
          onChange={(e) => {
            e.preventDefault()
            setInputValue(e.target.value)
            delayedQuery("duration", e.target.value, props.datum, "module")
          }}
          placeholder={"Session duration"}
          className={`${StylesA.session_input_field} ${StylesA.session_number_field} form-control px-3 ${Styles.font_16}`}
          onClick={() => setShowDropDown(!showDropDown)}
          onBlur={() => setShowDropDown(false)}
          disabled={props.datum.session_type && props.datum.session_type === "2"}
          required
        />
      ) : (
        <div
          disabled={props.datum.session_type && props.datum.session_type === "2"}
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            cursor: "text",
          }}
          onClick={() => {
            if (props.datum.session_type && props.datum.session_type !== "2") {
              setShowDropDown(!showDropDown)
            }
          }}
          className={`${StylesA.session_input_field} ${StylesA.border_on_hover} ${StylesA.session_number_field} px-2 ${Styles.font_16}`}
        >
          Duration: {inputValue} {timeType}
        </div>
      )}
      <Dropdown.Menu show={showDropDown} className={`${showDropDown ? StylesA.active_input_drop_down : ""}`}>
        {timeType === "Mins" ? (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(10)
                setShowDropDown(false)
              }}
            >
              <p>10 mins</p>
            </div>
            <div
              onClick={() => {
                setInputValue(20)
                setShowDropDown(false)
              }}
            >
              <p>20 mins</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(1)
                setShowDropDown(false)
              }}
            >
              <p>1 hrs</p>
            </div>
            <div
              onClick={() => {
                setInputValue(2)
                setShowDropDown(false)
              }}
            >
              <p>2 hrs</p>
            </div>
          </React.Fragment>
        )}
        <div
          style={{
            fontSize: "12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <p onClick={() => setTimeType("Mins")} style={{ opacity: timeType === "Mins" ? 1 : 0.5 }}>
            Mins
          </p>
          <p
            onClick={() => setTimeType("Hours")}
            style={{
              marginLeft: "10px",
              opacity: timeType === "Hours" ? 1 : 0.5,
            }}
          >
            Hours
          </p>
        </div>
      </Dropdown.Menu>
    </div>
  )
}
const SessionObjective = (props) => {
  const [showViewObjective, setShowViewObjective] = useState(false)

  function getTextWithBreaks(text) {
    let result = text.replace(/\r?\n/g, "<br />")
    return result
  }

  return (
    <>
      <Modal
        scrollable={true}
        size="lg"
        show={showViewObjective}
        onHide={() => setShowViewObjective(false)}
        centered
        dialogClassName={`${StylesA.activities_modal_width}`}
        contentClassName={`bg-transparent border-0 ml-0`}
      >
        <Modal.Body className={`pt-3 px-4 pb-4 ${StylesA.activities_modal}`}>
          <div className="d-flex justify-content-between align-items-center">
            <div>Objective</div>
            <div>
              <img
                onClick={() => setShowViewObjective(false)}
                src={CancelWhite}
                alt="Close"
                className={`ml-auto pointer mb-1`}
                style={{
                  objectFit: `cover`,
                }}
                width="20"
                height="20"
                layout="fixed"
              />
            </div>
          </div>
          <hr className="my-2 badge-secondary" />
          <div id="ssession_objective" style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: getTextWithBreaks(props?.sessionData?.description) }} />
        </Modal.Body>
      </Modal>
      {props.sessionData.description && (
        <div onClick={() => setShowViewObjective(true)}>
          <u style={{ fontSize: "16px" }}> View Objective</u>
        </div>
      )}
    </>
  )
}

const ReportBox = (props) => {
  return (
    <div className={`d-flex text-center flex-column ml-3 ${Style.cs_ques_box}`} style={{ flex: "1" }}>
      {props.header && (
        <div className={`fs-14px py-1`} style={{ borderBottom: "1px solid #6b7894" }}>
          {props.header}
        </div>
      )}
      {props.children}
      {props.footer && (
        <div className={`fs-14px py-1`} style={{ borderTop: "1px solid #6b7894" }}>
          {props.footer}
        </div>
      )}
    </div>
  )
}

const StudentListDropdown = (props) => {
  return (
    <Dropdown className={"session-time-drop-down px-0"}>
      <Dropdown.Toggle as="div" id="dropdown-custom-components">
        <div
          className={`inner-element-box-shadow px-2 mx-2 pointer border_on_hover`}
          style={{
            position: "relative",
            borderRadius: "6px",
            backgroundColor: "#586886",
            color: "#E0E2E5",
          }}
        >
          {props?.title}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${Style.active_input_drop_down}`}>
        <div className={`${Style.dropdown_list} font-weight-bold py-2`} style={{ fontSize: "16px" }}>
          {props?.title}
        </div>
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {props?.studentListData.results && props?.studentListData.results.length > 0
            ? props?.studentListData.results.map((student, index) => {
                return (
                  <div key={`student_${index}`} className={`d-flex align-items-center ${Style.dropdown_list}`}>
                    <div
                      className={`circle text-center font-weight-bolder`}
                      style={{
                        height: "35px",
                        width: "35px",
                        padding: "6px",
                        backgroundColor: "#212C42",
                      }}
                    >
                      {student?.first_name?.charAt(0) ?? "S"}
                    </div>
                    <div className={`ml-2`}>{student?.first_name}</div>
                  </div>
                )
              })
            : null}
          {props?.studentListData.next !== null && (
            <div
              style={{
                fontSize: "12px",
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "0px",
              }}
            >
              {props?.type === "casestudy" && (
                <div
                  className={`underline pointer`}
                  onClick={() => {
                    props.fetchGroupMemberListNext.fetchCaseStudyGroupMemberListNext(props?.studentListData.next)
                  }}
                >
                  See More
                </div>
              )}
              {props?.type === "checklist" && (
                <div
                  className={`underline pointer`}
                  onClick={() => {
                    props.fetchGroupMemberListNext.fetchChecklistGroupMemberListNext(props?.studentListData.next)
                  }}
                >
                  See More
                </div>
              )}
              {props?.type === "quiz" && (
                <div
                  className={`underline pointer`}
                  onClick={() => {
                    props.fetchGroupMemberListNext.fetchQuizGroupMemberListNext(props?.studentListData.next)
                  }}
                >
                  See More
                </div>
              )}
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const LessonBoard = (props) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""
  const winWidth = useRef(774)
  const winHeight = useRef(536)

  const grid = 8

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    outline: "none",
    padding: grid,
    margin: `0`,
    borderRadius: "20px",
    textAlign: "left", //center
    color: "#fff",

    // change background colour if dragging
    background: isDragging ? "#000" : "#303C54",

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#000" : "#303C54",
    border: isDraggingOver ? "1px dotted white" : "0px solid white",
    padding: grid,
  })

  const [boardData, setBoardData] = useState([{ title: "To Do" }, { title: "Doing" }, { title: "Done" }, { title: "Progress" }])

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }
  const [sessions, setSessions] = useState([])
  const [sessionsNext, setSessionsNext] = useState(null)
  const [sessionsFetched, setSessionsFetched] = useState(false)
  const [activeSession, setActiveSession] = useState(null)

  // each session full data in sessions array
  const [sessionsData, setSessionsData] = useState({})
  const [sessionTopicsFetching, setSessionTopicsFetching] = useState(false)

  const [modules, setModules] = useState([])
  const [modulesFetched, setModulesFetched] = useState(false)
  const [modulesNext, setModulesNext] = useState(null)

  // each module full data in modules array
  const [modulesData, setModulesData] = useState({})
  const [modulesDataFetched, setModulesDataFetched] = useState(false)
  const [topicsFetching, setTopicsFetching] = useState(false)

  const [modulesIds, setModulesIds] = useState({})

  // each resource type full data in allResources array
  const [topicsResources, setTopicsResources] = useState({})
  const [topicsResourcesNext, setTopicsResourcesNext] = useState({})
  const [topicsResourcesFetched, setTopicsResourcesFetched] = useState(false)

  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedTopicToggle, setSelectedTopicToggle] = useState({})
  const [selectedTopicToggleChange, setSelectedTopicToggleChange] = useState(false)

  const [dataUpdated, setDataUpdated] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  // const [intialFetch, setIntialFetch] = useState(false)

  // const [feedbackData, setFeedbackData] = useState([])
  const [sessionModal, setSessionModal] = useState("")
  const [endSessionModal, setEndSessionModal] = useState("")

  const [showSessionList, setShowSessionList] = useState(false)
  const [fetchDataType, setFetchDataType] = useState({})
  const [topicsRefetched, setTopicsRefetched] = useState(false)
  const [bulkTopicsRefetched, setBulkTopicsRefetched] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const [draggableHash, setDraggableHash] = useState({
    x: 40,
    y: 40,
    width: winWidth.current,
    height: winHeight.current,
  })
  const [restoreDraggableHash, setRestoreDraggableHash] = useState({
    x: 40,
    y: 40,
    width: winWidth.current,
    height: winHeight.current,
  })
  const [makeCenter, setMakeCenter] = useState(false)
  const [positionUpdated, setPositionUpdated] = useState(false)

  const [updateInputData, setUpdateInputData] = useState({})
  const [topicEdited, setTopicEdited] = useState(false)
  const [topicDataModified, setTopicDataModified] = useState(false)
  const [selectedInput, setSelectedInput] = useState("")

  const [deleteItem, setDeleteItem] = useState("")
  const [displayRnd, setDisplayRnd] = useState(false)
  const [resource_type_to_show, setResourceTypeToShow] = useState("")
  const [selected_resource, setSelectedResource] = useState({})
  const [gResource, setGResource] = useState({})
  const [blog_fetched, setBlogFetched] = useState(false)
  // const [rnd_width, setRndWidth] = useState(0)
  // const [rnd_height, setRndHeight] = useState(0)
  const [window_screen, setWindowScreen] = useState(true) //true - Maximize, false - Minimize

  const [showQuestionsModal, setShowQuestionsModal] = useState(null)
  const [questionReply, setQuestionReply] = useState(null)
  const [questionsData, setQuestionsData] = useState({})
  const [questionsDataFetched, setQuestionsDataFetched] = useState(false)
  const [questionExpand, setQuestionExpand] = useState({})
  const [questionsNextUrl, setQuestionsNextUrl] = useState({})
  const [questionReplyInput, setQuestionReplyInput] = useState("")
  const [questionReplyData, setQuestionReplyData] = useState({})
  const [questionReplyNextUrl, setQuestionReplyNextUrl] = useState({})
  const [questionLoader, setQuestionLoader] = useState(null)
  const [createdReply, setCreatedReply] = useState(null)
  const [replyInputLoader, setReplyInputLoader] = useState(false)
  const [draftReply, setDraftReply] = useState({})
  const [questionSocketData, setQuestionSocketData] = useState(null)
  const [wsMessage, setWsMessage] = useState(null)
  const [isDrafting, setIsDrafting] = useState(true)
  const [editReplyData, setEditReplyData] = useState({})

  const [showBreakoutSessionModal, setShowBreakoutSessionModal] = useState(false)
  const [activeBreakoutBtn, setActiveBreakoutBtn] = useState("overall_progress")
  const [breakoutGroupsProgress, setBreakOutGroupsProgress] = useState("")
  // const [sharedResources, setSharedResources] = useState([1,2,3,4,5,6,7,8])
  const [showInstructions, setShowInstructions] = useState(false)
  const [activeFotterBtn, setActiveFotterBtn] = useState(null)
  const [clickedSharedResourceInstructions, setClickedSharedResourceInstructions] = useState(false)
  const [showEndBreakoutSessionModal, setShowEndBreakoutSessionModal] = useState(false)

  const [breakoutSessionWS, setBreakoutSessionWS] = useState(null)
  const [breakoutSessionWSConn, setBreakoutSessionWSConn] = useState(null)

  const [breakoutGroupsIds, setBreakoutGroupsIds] = useState([])
  const [fetchedGroupIds, setFetchedGroupIds] = useState(false)

  const [showNotificationsModal, setShowNotificationsModal] = useState(false)
  const [showSuggestionModal, setShowSuggestionModal] = useState(false)

  /*  const [showSeekSuppportModal,setShowSeekSupportModal] = useState(false)
  const [showRequestTimeModal,setShowRequestTimeModal] = useState(false)*/

  const [groupActivityData, setGroupActivityData] = useState({})

  const [notificationsData, setNotificationsData] = useState([])

  /*  const [originalSeekSupportData, setOriginalSeekSupportData] = useState([])
  const [originalRequestTimeData, setOriginalRequestTimeData] = useState([])*/

  const [activityDataUpdated, setActivityDataUpdated] = useState(false)
  const [activeBreakoutSession, setActiveBreakoutSession] = useState(null)
  const [breakoutSessionActive, setBreakoutSessionActive] = useState(null)
  const [breakoutActivities, setBreakoutActivities] = useState([])
  const [breakoutActivitiesNextUrl, setBreakoutActivitiesNextUrl] = useState(null)
  const [breakoutActivitiesNextFetched, setBreakoutActivitiesNextFetched] = useState(true)
  const [isBreakoutActivitiesFetched, setIsBreakoutActivitiesFetched] = useState(false)
  const [showBreakoutActivities, setShowBreakoutActivities] = useState(false)
  const [activeBreakoutActivity, setActiveBreakoutActivity] = useState(null)
  const [activityWiseBreakoutGroupsProgress, setActivityWiseBreakoutGroupsProgress] = useState("")
  const [activityWiseProgressFetched, setActivityWiseProgressFetched] = useState(false)

  //Instructions

  const [sessionLevelInstructions, setSessionLevelInstructions] = useState("")
  const [sessionInstructionsList, setSessionInstructionsList] = useState([])
  const [sessionInstructionsFetched, setSessionInstructionsFetched] = useState(false)
  const [sessionInstructionsCount, setSessionInstructionsCount] = useState(null)

  const [extendDurationValue, setExtendDurationValue] = useState(30)
  const [timeDifference, setTimeDifference] = useState(0)
  const [minLeft, setMinLeft] = useState("00")
  const [secLeft, setSecLeft] = useState("00")
  const [hourLeft, setHourLeft] = useState("0")

  // Shared Resources
  const [currentSharedResources, setCurrentSharedResources] = useState({})
  const [srResourcesFetched, setSrResourcesFetched] = useState({})
  const [files, setFiles] = useState([])
  const [highlight, setHighlight] = useState("unhighlight")
  const [isShareResActive, setIsShareResActive] = useState(false)
  const [sharedResInstruction, setSharedResInstruction] = useState("")
  const [sharedResources, setSharedResources] = useState({})
  const [sharedResourcesNextUrl, setSharedResourcesNextUrl] = useState({})
  const [sharedResourcesCount, setSharedResourcesCount] = useState({})

  const [showNegativeError, setShowNegativeError] = useState(false)

  const [slidePage, setSlidePage] = useState(1)
  const [googleDocsEditable, setGoogleDocsEditable] = useState(false)

  const [activityResources, setActivityResources] = useState({})
  const [activityResourcesNextUrl, setActivityResourcesNextUrl] = useState({})
  const [activityResourcesLoader, setActivityResourcesLoader] = useState(false)
  const [showActivityResources, setShowActivityResources] = useState(false)
  const [activityResourcesFetched, setActivityResourcesFetched] = useState(false)
  const [activeActivityResource, setActiveActivityResource] = useState(null)

  const [caseStudyActivitiesModal, setCaseStudyActivitiesModal] = useState(false)
  const [activityWiseCaseStudyGroups, setActivityWiseCaseStudyGroups] = useState({})
  const [activityWiseCaseStudyGroupsFetched, setActivityWiseCaseStudyGroupsFetched] = useState({})
  const [caseStudyGroupData, setCaseStudyGroupData] = useState({})
  const [caseStudyGroupDataFetched, setCaseStudyGroupDataFetched] = useState({})
  const [caseStudyFetchLimit, setCaseStudyFetchLimit] = useState(8)
  const [caseStudyGroupDataExpandData, setCaseStudyGroupDataExpandData] = useState({})
  const [caseStudyGroupDataLoader, setCaseStudyGroupDataLoader] = useState(false)
  const [activeCaseStudyGroupData, setActiveCaseStudyGroupData] = useState(null)
  const [activeCaseStudyGroup, setActiveCaseStudyGroup] = useState({})
  const [activeRemarkText, setActiveRemarkText] = useState("")
  const [caseStudyGroupStudentList, setCaseStudyGroupStudentList] = useState({})
  const [caseStudyGroupClassAverage, setCaseStudyGroupClassAverage] = useState({})
  const [caseStudyGroupStudentListLoader, setCaseStudyGroupStudentListLoader] = useState(false)
  const [publishMsg, setPublishMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const [quizActivitiesModal, setQuizActivitiesModal] = useState(false)
  const [activityWiseQuizGroups, setActivityWiseQuizGroups] = useState({})
  const [activityWiseQuizGroupsFetched, setActivityWiseQuizGroupsFetched] = useState({})
  const [quizGroupData, setQuizGroupData] = useState({})
  const [quizGroupDataFetched, setQuizGroupDataFetched] = useState({})
  const [quizFetchLimit, setQuizFetchLimit] = useState(8)
  const [quizGroupDataExpandData, setQuizGroupDataExpandData] = useState({})
  const [quizGroupDataLoader, setQuizGroupDataLoader] = useState(false)
  const [activeQuizGroupData, setActiveQuizGroupData] = useState(null)
  const [activeQuizGroup, setActiveQuizGroup] = useState({})
  const [quizGroupStudentList, setQuizGroupStudentList] = useState({})
  const [quizGroupStudentListLoader, setQuizGroupStudentListLoader] = useState(false)
  const [quizGroupClassAverage, setQuizGroupClassAverage] = useState({})

  const [checklistActivitiesModal, setChecklistActivitiesModal] = useState(false)
  const [activityWiseChecklistGroups, setActivityWiseChecklistGroups] = useState({})
  const [activityWiseChecklistGroupsFetched, setActivityWiseChecklistGroupsFetched] = useState({})
  // const [checklistGroupData, setChecklistGroupData] = useState({})
  // const [checklistGroupDataFetched, setChecklistGroupDataFetched] = useState({})
  // const [checklistFetchLimit, setChecklistFetchLimit] = useState(8)
  // const [checklistGroupDataExpandData, setChecklistGroupDataExpandData] = useState({})
  // const [checklistGroupDataLoader, setChecklistGroupDataLoader] = useState(false)
  const [activeChecklistGroupData, setActiveChecklistGroupData] = useState(null)
  const [activeChecklistGroup, setActiveChecklistGroup] = useState({})
  const [checklistGroupStudentList, setChecklistGroupStudentList] = useState({})
  const [checklistGroupStudentListLoader, setChecklistGroupStudentListLoader] = useState(false)
  const [activeChecklistGroupRefresh, setActiveChecklistGroupRefresh] = useState(0)
  const [wsData, setWSData] = useState(null)

  //states for match-the-folloings
  const [activityWiseMTFGroups, setActivityWiseMTFGroups] = useState({})
  const [activityWiseMTFGroupsFetched, setActivityWiseMTFGroupsFetched] = useState({})
  const [activeMTFGroupData, setActiveMTFGroupData] = useState(null)
  const [activeMTFGroup, setActiveMTFGroup] = useState()
  const [showViewDetailsModal, setShowViewDetailsModal] = useState(false)

  const [triggeredResource, setTriggeredResource] = useState(null)
  //poll
  const [showPollModal, setShowPollModal] = useState(false)
  const [createNewPoll, setCreateNewPoll] = useState(false)
  const [activeTopic, setActiveTopic] = useState(null)
  const [notificationData, setNotificationData] = useState(null)

  const [uploadInTopic, setUploadInTopic] = useState(null)
  const [uploadInModule, setUploadInModule] = useState(null)
  const [uploadDataSequence, setUploadDataSequence] = useState(0)
  const [addBlog, setAddBlog] = useState(false)
  const [showSection, setShowSection] = useState("video")
  //const [files, setFiles] = useState([])
  const quizWin = useRef(null)
  const checklistWin = useRef(null)
  const casestudyWin = useRef(null)
  const [attrForResources, setAttrForResources] = useState(null)

  const [repliesResources, setRepliesResources] = useState({})
  const [repliesResourcesNextUrl, setRepliesResourcesNextUrl] = useState({})
  const [updatedResource, setUpdatedResource] = useState(null)
  //const [wsTrainingConnection, setSsTrainingConnection] = useState(props.trainingWsConnection)
  const [rndDragDisableFlag, setRndDragDisableFlag] = useState(false)
  const breakoutSessionTimer = useRef(null)
  const [breakoutSessWindowScreen, setBreakoutSessWindowScreen] = useState("maximize")
  const videoPlayer = useRef(null)

  const [popQuizModal, setPopQuizModal] = useState(false)
  const [popQuizDetail, setPopQuizDetail] = useState(null)
  const [sessionsQA, setSessionsQA] = useState([])
  const [sessionQASelected, setSessionQASelected] = useState("all sessions")
  const [sessionQAIdSelected, setSessionQAIdSelected] = useState(0)
  const [sessionsTopicsQA, setSessionsTopicsQA] = useState([])
  const [selectedQATopic, setSelectedQATopic] = useState({})
  const [questionReplyExpand, setQuestionReplyExpand] = useState(true)
  const [showScrollLeft, setShowScrollLeft] = useState(false)
  const [showScrollRight, setShowScrollRight] = useState(false)
  const [questionAnswerSearch, setQuestionAnswerSearch] = useState("")
  const [qaSessionTopicSearch, setQaSessionTopicSearch] = useState("")

  const [showExtendTimeModal, setShowExtendTimeModal] = useState(false)

  const [breakoutTimeAlterationModal, setBreakoutTimeAlterationModal] = useState("dontShow")
  const [breakoutTimeAltered, setBreakoutTimeAltered] = useState(false)
  const [breakoutSession, setBreakoutSession] = useState(false)
  const [ready, setReady] = useState(false)
  const [ongoingActivityData, setOngoingActivityData] = useState(null)

  const fetchDataWs = useRef()
  const breakoutGroupsWs = useRef()

  useEffect(() => {
    fetchSessionModules()
    connectSocket()
    setReady(true)
  }, [])

  useEffect(() => {
    if (props.trainingWsConnection) {
      SocketConnection()
    }
  }, [props.trainingWsConnection])

  const SocketConnection = () => {
    if (props.trainingWsConnection) {
      props.trainingWsConnection.onmessage = function (e) {
        let data = JSON.parse(e.data)
        if (data.value === "echo") return
        let responseData = JSON.parse(data.value)
        if (responseData.event_type) {
          // Based on event type calling function dynamically
          try {
            const event_fn = eval(responseData.event_type)
            event_fn(responseData)
          } catch (error) {
            // pass
          }
        }
      }
    }
  }
  function setWinWidthHeight() {
    if (isBrowser() && document && document?.body) {
      let width = (document?.body?.clientWidth || 974) - 150,
        height = (document?.body?.clientHeight || 686) - 150
      winWidth.current = width
      winHeight.current = height
      setDraggableHash({ x: 40, y: 40, width: width, height: height })
      setRestoreDraggableHash({ x: 40, y: 40, width: width, height: height })
    }
  }

  const closePollModal = (isEnd) => {
    setShowPollModal(false)
    isEnd && handleCloseTriggeredResourceWebsocket()
  }

  //This useEffect is for opening ongoing activity on refresh
  useEffect(() => {
    if (ongoingActivityData) {
      let datum = ongoingActivityData?.current_active_activity

      // This IF is for opening ongoing breakout session
      if (ongoingActivityData?.active_breakout_session) {
        triggerBreakoutSession(datum, datum.topic)
      } else {
        // This ELSE is for opening ongoing activity
        if (
          datum &&
          (datum?.resource?.resource_type === "casestudy" || datum?.resource?.resource_type === "quiz" || datum?.resource?.resource_type === "checklist")
        ) {
          setIsUpdated(false)
          setMakeCenter(true)
          displayResourceInPopUp(datum?.resource)
          setTriggeredResource(datum?.resource)
          if (datum?.resource?.resource_data?.data_type === "1") {
            setShowPollModal(true)
          }
          setActiveTopic(datum?.resource?.topic)
        } else if (datum && datum?.resource) {
          // updateTriggereCount(datum?.resource, datum?.resource?.topic)
          setActiveTopic(datum?.resource?.topic)
          displayResourceInPopUp(datum?.resource)
        }
      }
    }
  }, [ongoingActivityData])

  useEffect(() => {
    if (typeof updatedResource != "object") {
      setTopicsResources(updatedResource)
    } else if (updatedResource?.id && topicsResources[`topic_` + activeTopic]) {
      var index = topicsResources[`topic_` + activeTopic].findIndex((res) => res.id === updatedResource.id)
      let dummy = { ...topicsResources }
      dummy[`topic_` + activeTopic][index] = updatedResource
      setTopicsResources(dummy)
    }
  }, [updatedResource])

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", handleNewWinMessages, false)
      window.addEventListener("close-poll-modal", handlePollModalMsgs, false)
      window.addEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs, false)
      window.addEventListener("resize", setWinWidthHeight, false)
      window.addEventListener("my-view-details", handleViewDetailsForLeftNavResources, false)
      document.addEventListener("visibilitychange", function () {
        if (document.hidden) {
          // clearTimer()
        } else {
          if (breakoutSessionTimer.current) {
            fetchSessionModules()
          }
        }
      })

      setWinWidthHeight()
      return () => {
        window.removeEventListener("message", handleNewWinMessages)
        window.removeEventListener("close-poll-modal", handlePollModalMsgs)
        window.removeEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs)
        window.removeEventListener("resize", setWinWidthHeight, false)
        window.removeEventListener("my-view-details", handleViewDetailsForLeftNavResources, false)
      }
    }
  }, [isBrowser()])

  function handleViewDetailsForLeftNavResources(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    let datum = evt.detail
    setWinWidthHeight()
    displayResourceInPopUp(datum, true)
    //setTriggeredResource(datum)

    if (datum?.resource_type !== "quiz" && datum?.resource_data?.data_type === "1") {
      setShowPollModal(true)
    }
  }

  useEffect(() => {
    if (breakoutSessionWS) {
      // connectGroupActivitySocket()
    } else {
      if (breakoutSessionWSConn) {
        setTimeout(breakoutSessionWSConn.close(), 1000)
      }
    }
  }, [breakoutSessionWS])

  useEffect(() => {
    if (questionSocketData !== null) {
      //Only Workshop Stages Questions has to be created
      if (questionSocketData?.type === "question_created") {
        if (
          questionSocketData.data.workshop_module === null ||
          (questionSocketData.data.workshop_module && questionSocketData.data.workshop_module?.module_type === "3")
        ) {
          let quesData = { ...questionsData }
          if (quesData["topic_all_questions"]) {
            quesData["topic_all_questions"] = [questionSocketData?.data, ...quesData["topic_all_questions"]]
          }
          if (questionSocketData?.topicData?.module !== null) {
            if (quesData["topic_" + questionSocketData?.data?.object_id]) {
              quesData["topic_" + questionSocketData?.data?.object_id] = [questionSocketData?.data, ...quesData["topic_" + questionSocketData?.data?.object_id]]
            }
            if (questionSocketData?.topicData?.module !== "") {
              let topics = { ...modulesData }
              let data = topics["module_" + questionSocketData?.topicData?.module.replace(" ", "_")].topics
              let topicIndex = data.map((topic) => topic.id).indexOf(questionSocketData?.topicData?.id)
              if (data[topicIndex]) {
                data[topicIndex] = {
                  ...data[topicIndex],
                  questions: questionSocketData?.topicData?.questions,
                }
                setModulesData(topics)
              }
            }
          }
          setQuestionsData(quesData)
        }
      } else if (questionSocketData?.type === "question_deleted") {
        let quesData = { ...questionsData }
        if (quesData["topic_all_questions"]) {
          let allIndex = quesData["topic_all_questions"].map((ques) => ques.id).indexOf(questionSocketData?.data?.question)
          if (quesData["topic_all_questions"][allIndex]) {
            quesData["topic_all_questions"].splice(allIndex, 1)
          }
        }
        if (questionSocketData?.data?.module !== null) {
          if (quesData["topic_" + questionSocketData?.data?.topic]) {
            let index = quesData["topic_" + questionSocketData?.data?.topic].map((ques) => ques.id).indexOf(questionSocketData?.data?.question)
            if (quesData["topic_" + questionSocketData?.data?.topic][index]) {
              quesData["topic_" + questionSocketData?.data?.topic].splice(index, 1)
            }
          }
          if (questionSocketData?.topicData?.module !== "") {
            let topics = { ...modulesData }
            let data = topics["module_" + questionSocketData?.data?.module.replace(" ", "_")].topics
            let topicIndex = data.map((topic) => topic.id).indexOf(questionSocketData?.data?.topic)
            if (data[topicIndex]) {
              data[topicIndex] = {
                ...data[topicIndex],
                questions: questionSocketData?.data?.questions_count,
              }
              setModulesData(topics)
            }
          }
        }
        if (questionReply?.id === questionSocketData?.data?.question) {
          setQuestionReply(null)
          setShowQuestionsModal(null)
        }
        setQuestionsData(quesData)
      } else if (questionSocketData?.type === "vote") {
        let quesData = { ...questionsData }
        if (quesData["topic_all_questions"]) {
          let allIndex = quesData["topic_all_questions"].map((ques) => ques.id).indexOf(questionSocketData?.data?.question_id)
          if (quesData["topic_all_questions"][allIndex]) {
            //need to be updated
            quesData["topic_all_questions"][allIndex].votes.count = questionSocketData?.data?.like_count
          }
        }
        if (quesData[`topic_${questionSocketData?.data?.topic_id}`]) {
          let index = quesData[`topic_${questionSocketData?.data?.topic_id}`].map((ques) => ques.id).indexOf(questionSocketData?.data?.question_id)
          if (quesData[`topic_${questionSocketData?.data?.topic_id}`][index]) {
            quesData[`topic_${questionSocketData?.data?.topic_id}`][index].votes.count = questionSocketData?.data?.like_count
          }
        }
        setQuestionsData(quesData)
      }
      if (questionSocketData?.type === "topic_feedback") {
        let topicData = { ...modulesData }
        let index = topicData["module_Done"]["topics"].map((topic) => topic.id).indexOf(questionSocketData?.data?.topic_id)
        if (topicData["module_Done"]["topics"][index]) {
          topicData["module_Done"]["topics"][index].feedbacks = {
            ...questionSocketData?.data?.feedback,
          }
        }
        setModulesData(topicData)
      } else if (questionSocketData?.type === "reply_created") {
        let replyData = { ...questionReplyData }
        let quesData = { ...questionsData }
        if (replyData[`ques_${questionSocketData?.topicData?.question?.id}`]) {
          replyData[`ques_${questionSocketData?.topicData?.question?.id}`].push(questionSocketData?.data)
          setQuestionReplyData(replyData)
        }
        if (questionSocketData?.topicData?.module !== null) {
          let topicData = { ...modulesData }
          if (questionSocketData?.topicData?.module !== "") {
            let data = topicData["module_" + questionSocketData?.topicData?.module.replace(" ", "_")].topics
            let topicIndex = data.map((topic) => topic.id).indexOf(questionSocketData?.topicData?.topic?.id)
            if (data[topicIndex]) {
              data[topicIndex] = {
                ...data[topicIndex],
                questions: questionSocketData?.topicData?.questions_count,
              }
              setModulesData(topicData)
            }
          }
          if (quesData[`topic_${questionSocketData?.topicData?.topic?.id}`]) {
            let quesIndex = quesData[`topic_${questionSocketData?.topicData?.topic?.id}`].findIndex((q) => q.id === questionSocketData?.topicData?.question?.id)
            if (quesData[`topic_${questionSocketData?.topicData?.topic?.id}`][quesIndex]) {
              quesData[`topic_${questionSocketData?.topicData?.topic?.id}`][quesIndex].reply_count += 1
            }
          }
        }
        let allQuesIndex = quesData[`topic_all_questions`].findIndex((q) => q.id == questionSocketData?.topicData?.question?.id)
        if (quesData[`topic_all_questions`][allQuesIndex]) {
          quesData[`topic_all_questions`][allQuesIndex].reply_count += 1
        }
        setQuestionsData(quesData)
      } else if (questionSocketData?.type === "reply_deleted") {
        let quesData = { ...questionsData }
        if (questionSocketData?.data?.module !== null) {
          let topicData = { ...modulesData }
          let data = topicData["module_" + questionSocketData?.data?.module.replace(" ", "_")].topics
          let topicIndex = data.map((topic) => topic.id).indexOf(questionSocketData?.data?.topic)
          if (data[topicIndex]) {
            data[topicIndex] = {
              ...data[topicIndex],
              questions: questionSocketData?.data?.questions,
            }
            setModulesData(topicData)
          }
          if (quesData[`topic_${questionSocketData?.data?.topic}`]) {
            let quesIndex = quesData[`topic_${questionSocketData?.data?.topic}`].findIndex((q) => q.id === questionSocketData?.data?.question)
            if (quesData[`topic_${questionSocketData?.data?.topic}`][quesIndex]) {
              quesData[`topic_${questionSocketData?.data?.topic}`][quesIndex].reply_count -= 1
            }
          }
        }

        let allQuesIndex = quesData[`topic_all_questions`].findIndex((q) => q.id === questionSocketData?.data?.question)
        if (quesData[`topic_all_questions`][allQuesIndex]) {
          quesData[`topic_all_questions`][allQuesIndex].reply_count -= 1
        }

        let replyData = { ...questionReplyData }
        if (replyData[`ques_${questionSocketData?.data?.question}`]) {
          let index = replyData[`ques_${questionSocketData?.data?.question}`].findIndex((reply) => reply.id == questionSocketData?.data?.id)
          if (replyData[`ques_${questionSocketData?.data?.question}`][index]) {
            replyData[`ques_${questionSocketData?.data?.question}`].splice(index, 1)
            setQuestionReplyData(replyData)
          }
        }
        setQuestionsData(quesData)
      } else if (questionSocketData?.type === "reply_changed") {
        let replyData = { ...questionReplyData }
        let replyIndex = replyData[`ques_${questionSocketData?.topicData?.question?.id}`].findIndex((q) => q.id == questionSocketData?.data?.id)
        if (replyData[`ques_${questionSocketData?.topicData?.question?.id}`][replyIndex]) {
          replyData[`ques_${questionSocketData?.topicData?.question?.id}`][replyIndex] = {
            ...replyData[`ques_${questionSocketData?.topicData?.question?.id}`][replyIndex],
            title: questionSocketData?.data?.title,
            resources: questionSocketData?.data?.resources,
          }
        }
        setQuestionReplyData(replyData)
      } else if (questionSocketData?.type === "reply_vote") {
        let data = { ...questionReplyData }
        let socketData = questionSocketData.data
        if (data["ques_" + socketData?.question]) {
          let replyIndex = data["ques_" + socketData?.question].map((reply) => reply.id).indexOf(socketData?.reply)
          if (data["ques_" + socketData?.question][replyIndex]) {
            data["ques_" + socketData?.question][replyIndex] = {
              ...data["ques_" + socketData?.question][replyIndex],
              votes: {
                ...data["ques_" + socketData?.question][replyIndex].votes,
                count: socketData?.likes,
              },
            }
            setQuestionReplyData(data)
          }
        }
      } else if (questionSocketData?.type === "question_topic_updated") {
        if (questionSocketData.data.stage_name === null || questionSocketData.data.stage_name?.name === "Workshop") {
          let quesData = { ...questionsData }
          let topics = { ...modulesData }
          // update in all_question-data and my_question-data
          let index = quesData[`topic_all_questions`].findIndex((ques) => ques.id == questionSocketData.data.id)
          if (quesData[`topic_all_questions`][index]) {
            if (questionSocketData.data.resource_type == "topic") {
              quesData[`topic_all_questions`][index].content_object_name = questionSocketData.data.content_object_name
            } else {
              quesData[`topic_all_questions`][index].content_object_name = "Unassigned"
            }
          }

          if (questionSocketData.data.prev_resource_type == "topic") {
            if (quesData[`topic_${questionSocketData.data.prev_object_id}`]) {
              delete quesData[`topic_${questionSocketData.data.prev_object_id}`]
            }
            if (questionSocketData?.data?.previous_module_name !== "" && questionSocketData?.data?.previous_module_name !== null) {
              let data = topics["module_" + questionSocketData?.data?.previous_module_name.replace(" ", "_")].topics
              let topicIndex = data.findIndex((topic) => topic.id == questionSocketData?.data?.prev_object_id)
              if (data[topicIndex]) {
                data[topicIndex] = {
                  ...data[topicIndex],
                  questions: questionSocketData?.data?.previous_topic_questions,
                }
              }
            }
          }
          if (questionSocketData.data.resource_type == "topic") {
            if (quesData[`topic_${questionSocketData.data.object_id}`]) {
              delete quesData[`topic_${questionSocketData.data.object_id}`]
            }
            if (questionSocketData?.data?.module_name !== "" && questionSocketData?.data?.module_name !== null) {
              let data = topics["module_" + questionSocketData?.data?.module_name.replace(" ", "_")].topics
              let topicIndex = data.findIndex((topic) => topic.id == questionSocketData?.data?.object_id)
              if (data[topicIndex]) {
                data[topicIndex] = {
                  ...data[topicIndex],
                  questions: questionSocketData?.data?.questions,
                }
              }
            }
          }
          setModulesData(topics)
          setQuestionsData(quesData)
        }
      }
    }
  }, [questionSocketData])

  const question_created = (responseData) => {
    let responseQuesData = {
      data: {
        id: responseData.id,
        title: responseData.title,
        reply_count: 0,
        updated_at: responseData.created,
        content_type_name: responseData?.content_type_name,
        content_object_name: responseData.content_object_name,
        object_id: responseData.object_id,
        votes: { count: 0 },
        owner: responseData.owner,
        content_type_name: responseData.content_type_name,
        workshop_module: responseData.workshop_module,
      },
      topicData: {
        id: responseData?.object_id,
        module: responseData.module,
        questions: responseData?.questions,
      },
      type: "question_created",
    }
    setQuestionSocketData(responseQuesData)
  }

  const question_topic_updated = (responseData) => {
    let wsData = {
      data: responseData,
      type: "question_topic_updated",
    }
    setQuestionSocketData(wsData)
  }

  const question_reply_changed = (responseData) => {
    let replyData = {
      data: {
        id: responseData.id,
        title: responseData.title,
        published: true,
        owner: responseData.owner,
        updated_at: responseData.updated_at,
        votes: { count: 0 },
        resources: responseData?.resources,
      },
      topicData: {
        question: responseData?.question,
        topic: responseData?.topic,
        module: responseData?.module,
        questions_count: responseData?.questions,
      },
      type: "reply_changed",
    }
    setQuestionSocketData(replyData)
  }

  const question_vote_changed = (responseData) => {
    let responseVoteData = {
      data: {
        question_id: responseData.question,
        topic_id: responseData.topic,
        like_count: responseData.likes,
      },
      type: "vote",
    }
    setQuestionSocketData(responseVoteData)
  }

  const topic_feedback_updated = (responseData) => {
    let topicFeedbackData = {
      data: { feedback: responseData.feedback, topic_id: responseData.topic },
      type: "topic_feedback",
    }
    setQuestionSocketData(topicFeedbackData)
  }

  const question_deleted = (responseData) => {
    let deletedQuesData = {
      data: {
        question: responseData?.id,
        topic: responseData?.topic,
        module: responseData?.module,
        questions_count: responseData?.questions,
      },
      type: "question_deleted",
    }
    setQuestionSocketData(deletedQuesData)
  }

  const question_reply_created = (responseData) => {
    let replyData = {
      data: {
        id: responseData.id,
        title: responseData.title,
        published: true,
        owner: responseData.owner,
        updated_at: responseData.updated_at,
        votes: { count: 0 },
        resources: responseData?.resources,
      },
      topicData: {
        question: responseData.question,
        topic: responseData.topic,
        module: responseData.module,
        questions_count: responseData.questions,
      },
      type: "reply_created",
    }
    setQuestionSocketData(replyData)
  }

  const question_reply_deleted = (responseData) => {
    let replyData = { data: { ...responseData }, type: "reply_deleted" }
    setQuestionSocketData(replyData)
  }

  const reply_vote_changed = (responseData) => {
    let responseReplyData = { data: { ...responseData }, type: "reply_vote" }
    setQuestionSocketData(responseReplyData)
  }

  const fetch_leader_board = (responseData) => {
    setWSData({ ...responseData })
  }

  useEffect(() => {
    if (wsData?.source === "group_checklist") {
      let key = `res_${wsData?.resource_id}`
      let data = { ...activityWiseChecklistGroups }
      if (data[key]) {
        let index = data[key]?.results.findIndex((res) => res.group_id === wsData?.group_id)
        if (index !== -1) {
          data[key].results[index].report_card = wsData?.report_card_id
          setActivityWiseChecklistGroups(data)
        }
      }
    }
  }, [wsData])

  useEffect(() => {
    if (currentSharedResources[`active_${activeBreakoutSession?.id}`]?.resources.length > 0) {
      setIsShareResActive(true)
    } else {
      setIsShareResActive(false)
      setClickedSharedResourceInstructions(false)
    }
  }, [currentSharedResources[`active_${activeBreakoutSession?.id}`]?.resources.length])

  useEffect(() => {
    if (props.resourceTrigger) {
      let triggered_datam = props.resourceTrigger
      if (triggered_datam.resource_type !== "quiz" || (triggered_datam.resource_type === "quiz" && triggered_datam.resource_data.data_type !== "1")) {
        setIsUpdated(false)
        setMakeCenter(true)
        displayResourceInPopUp(props?.resourceTrigger)
        if (triggered_datam?.resource_data?.data_type === "2") {
          setUpdatedResource(null)
        }
      }
      setSlidePage(1)
      setGoogleDocsEditable(false)
    }
  }, [props?.resourceTrigger])

  useEffect(() => {
    //check whether to minimise RND modal & the modal is not already minimized
    if (
      isBrowser() &&
      props.leftNavOpen &&
      displayRnd &&
      (window_screen === false || window_screen === "maximize" || parseInt(draggableHash.width) > 324 || parseInt(draggableHash.height) > 191)
    ) {
      changeWindowScreen(false)
    }
  }, [props.leftNavOpen, props.currentNavTab])

  useEffect(() => {
    if (fetchDataType && Object.keys(fetchDataType).length > 0) {
      if (fetchDataType.type === "todosessions") {
        if (fetchDataType.session_data) {
          let topic_id = parseInt(fetchDataType.id)
          let prevSessionIndex = sessions.findIndex((res) => res.id === parseInt(fetchDataType.session_data.previos_module))
          if (fetchDataType.session_data.previos_module && sessionsData[`session_` + fetchDataType.session_data.previos_module]) {
            let t_index = sessionsData[`session_` + fetchDataType.session_data.previos_module].topics.findIndex((res) => res.id === topic_id)
            if (t_index > -1) {
              sessionsData[`session_` + fetchDataType.session_data.previos_module].topics.splice(t_index, 1)
            }
          }
          if (sessions[prevSessionIndex].session_type === "1") {
            let index = -1
            let module_index = -1
            if (modulesData[`module_` + modules[0].name.replaceAll(" ", "_")] && modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics) {
              index = modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics.findIndex((res) => res.id === parseInt(fetchDataType.id))
              if (index > -1) {
                module_index = 0
              } else if (
                modulesData[`module_` + modules[1].name.replaceAll(" ", "_")] &&
                modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics
              ) {
                index = modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics.findIndex((res) => res.id === parseInt(fetchDataType.id))
                if (index > -1) {
                  module_index = 1
                } else {
                  module_index = 0
                  index = 0
                }
              }
            }
            if (index > -1 && module_index > -1) {
              setTopicsFetching(false)
              fetchCurrentTopics(modules[module_index].id, modules[module_index].name, modules.length, false, true)
            }
          } else if (sessions[prevSessionIndex].session_type === "0" && fetchDataType.session_data.session_type === "1") {
            setTopicsFetching(false)
            fetchCurrentTopics(modules[0].id, modules[0].name, modules.length, false, true)
          }
          fetchCurrentSessionTopics(fetchDataType.session_data, true)
        }
      } else if (fetchDataType.type === "todo") {
        let index = -1
        let module_index = -1
        if (modulesData[`module_` + modules[0].name.replaceAll(" ", "_")] && modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics) {
          index = modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics.findIndex((res) => res.id === parseInt(fetchDataType.id))
          if (index > -1) {
            module_index = 0
          } else if (modulesData[`module_` + modules[1].name.replaceAll(" ", "_")] && modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics) {
            index = modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics.findIndex((res) => res.id === parseInt(fetchDataType.id))
            if (index > -1) {
              module_index = 1
            } else {
              module_index = 0
              index = 0
            }
          }
        }
        if (index > -1 && module_index > -1) {
          setTopicsFetching(false)
          fetchCurrentTopics(modules[module_index].id, modules[module_index].name, modules.length, false, true)
        }
      } else if (fetchDataType.type === "resources") {
        if (topicsResources[`topic_` + fetchDataType.id]) {
          setTopicsResourcesFetched(false)
          setTopicsRefetched(false)
          fetchTopicResources(fetchDataType.id, true)
        }
        if (fetchDataType.value !== null && topicsResources[`topic_` + fetchDataType.value]) {
          setTopicsResourcesFetched(false)
          setBulkTopicsRefetched(false)
          fetchTopicResources(fetchDataType.value, true, true)
        }
      } else if (fetchDataType.type === "topicedited") {
        if (fetchDataType.field === "breakout_sessions") {
          let bs_index = -1
          let bs_module_index = -1
          if (modulesData[`module_` + modules[0].name.replaceAll(" ", "_")] && modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics) {
            bs_index = modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics.findIndex((res) => res.id === parseInt(fetchDataType.data.id))
            if (bs_index > -1) {
              bs_module_index = 0
            } else if (modulesData[`module_` + modules[1].name.replaceAll(" ", "_")] && modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics) {
              bs_index = modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics.findIndex((res) => res.id === parseInt(fetchDataType.data.id))
              if (bs_index > -1) {
                bs_module_index = 1
              } else {
                bs_module_index = 0
                bs_index = 0
              }
            }
          }
          if (bs_index > -1 && bs_module_index > -1) {
            setTopicsFetching(false)
            fetchCurrentTopics(modules[bs_module_index].id, modules[bs_module_index].name, modules.length, false, true)
          }
        } else {
          let index = -1
          if (modulesData[`module_` + modules[0].name.replaceAll(" ", "_")] && modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics) {
            index = modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics.findIndex((res) => res.id === parseInt(fetchDataType.data.id))
            if (index > -1) {
              modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics[index][fetchDataType.field] = fetchDataType.data.name
            } else if (modulesData[`module_` + modules[1].name.replaceAll(" ", "_")] && modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics) {
              index = modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics.findIndex((res) => res.id === parseInt(fetchDataType.data.id))
              if (index > -1) {
                modulesData[`module_` + modules[1].name.replaceAll(" ", "_")].topics[index][fetchDataType.field] = fetchDataType.data.name
              }
            }
          }
          if (fetchDataType.session_data && sessionsData && sessionsData[`session_` + fetchDataType.session_data.id]) {
            index = sessionsData[`session_` + fetchDataType.session_data.id].topics.findIndex((res) => res.id === parseInt(fetchDataType.data.id))
            if (index > -1) {
              sessionsData[`session_` + fetchDataType.session_data.id].topics[index][fetchDataType.field] = fetchDataType.data.name
              setSelectedInput("topic_" + fetchDataType.data.id + fetchDataType.field)
            }
          }
        }
      } else if (fetchDataType.type === "spsessionedited") {
        if (sessions && sessions.length > 0) {
          var index = sessions.findIndex((res) => res.id === parseInt(fetchDataType.data.id))
          if (index > -1) {
            if (fetchDataType.field === "start_date") {
              sessions[index][fetchDataType.field] = new Date(fetchDataType.data.name)
            } else {
              sessions[index][fetchDataType.field] = fetchDataType.data.name
            }
            setSelectedInput("module_" + fetchDataType.data.id + fetchDataType.field)
          }
        }
      } else if (fetchDataType.type === "todo0") {
        fetchCurrentSessionTopics(fetchDataType.session_data, true)
      }
      setFetchDataType({})
      setDataUpdated(!dataUpdated)
    } else if (Object.keys(fetchDataType).length === 0) {
      setSelectedInput("")
      setTopicDataModified(!topicDataModified)
    }
  }, [Object.keys(fetchDataType).length > 0])

  function connectSocket() {
    if (authClient.isAuthenticated()) {
      let updateSocket = WsConnect(`/ws/fetch-data/${props.location.template_id}/`)
      updateSocket.onmessage = function (e) {
        let data = JSON.parse(e.data)
        if (data.value === "echo") return
        let responseData = JSON.parse(data.value)
        if (responseData.fetch_type.indexOf("lbsessions") > -1) {
          setSessionsFetched(false)
          fetchSessionModules()
        } else if (responseData.fetch_type.indexOf("todo1") > -1) {
          setFetchDataType({ type: "todo", id: responseData.id })
        } else if (responseData.fetch_type.indexOf("todo0") > -1) {
          setFetchDataType({
            type: "todo0",
            id: responseData.id,
            session_data:
              responseData.value === null
                ? { id: responseData.fetch_type.split("__")[1] }
                : {
                    id: responseData.value.split("_")[0],
                    session_type: responseData.value.split("_")[1],
                  },
          })
        } else if (responseData.fetch_type.indexOf("todo") > -1) {
          setFetchDataType({
            type: "todosessions",
            id: responseData.id,
            session_data:
              responseData.value === null
                ? null
                : {
                    id: responseData.value.split("_")[0],
                    session_type: responseData.value.split("_")[1],
                    previos_module: responseData.value.split("_").length === 3 ? responseData.value.split("_")[2] : null,
                  },
          })
        } else if (responseData.fetch_type.indexOf("resources") > -1) {
          setFetchDataType({
            id: responseData.id,
            type: "resources",
            value: responseData.value,
          })
        } else if (responseData.fetch_type.indexOf("lptopicedited") > -1 || responseData.fetch_type.indexOf("sptopicedited") > -1) {
          setFetchDataType({
            field: responseData.id.split("_$_")[2],
            type: "topicedited",
            data: {
              id: responseData.id.split("_$_")[0],
              name: responseData.id.split("_$_")[1],
            },
            session_data:
              responseData.value === null
                ? null
                : {
                    id: responseData.value.split("_")[0],
                    session_type: responseData.value.split("_")[1],
                  },
          })
        } else if (responseData.fetch_type.indexOf("spsessionedited") > -1) {
          setFetchDataType({
            field: responseData.value,
            type: "spsessionedited",
            data: {
              id: responseData.id.split("_$_")[0],
              name: responseData.id.split("_$_")[1],
            },
          })
        }
      }
      updateSocket.onclose = function (e) {
        if (!e.wasClean) {
          setTimeout(connectSocket(), 5000)
        }
      }

      updateSocket.onopen = (e) => {
        fetchDataWs.current = updateSocket
      }
    }
  }

  const showTriggeredResource = (datum, topic) => {
    let id = datum.id
    let resource = props.activeActivityData.current_active_activity.resource
    let value = topic.id
    updateTriggereCount(datum, topic)
    setActiveTopic(value)
    let topic_datam = { ...topicsResources }
    let index = topic_datam[`topic_` + value].findIndex((resource) => resource.id === id)
    if (resource.triggered_data && Object.keys(resource.triggered_data).length > 0) {
      topic_datam[`topic_` + value][index]["version_id"] = resource.triggered_data.version_id
      topic_datam[`topic_` + value][index]["triggered_time"] = resource.triggered_data.triggered_time
      topic_datam[`topic_` + value][index]["triggered_count"] = resource.triggered_data.triggered_count
    }
    setTopicsResources(topic_datam)
    displayResourceInPopUp(topic_datam[`topic_` + value][index])

    if (datum.resource_data.data_type === "1") {
      handlePollSave(datum, true, false, topic.id, "1", false)
    }
  }

  function triggerChanges(fetch_type, id = null, value = null, type = null) {
    if (fetch_type) {
      let datum_id = id === null ? `` : `?id=${id}`
      let value_data = value === null ? `` : `&value=${value}`
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/lesson-board-triggering/${props.location.template_id}/${fetch_type}/${datum_id}${value_data}&update_version=true`,
          { headers: login_credentials }
        )
        .then((res) => {
          if (fetch_type === "resource_poped") {
            setActiveTopic(value)
            let topic_datam = { ...topicsResources }
            let index = topic_datam[`topic_` + value].findIndex((resource) => resource.id === id)
            if (res.data.triggered_data && Object.keys(res.data.triggered_data).length > 0) {
              topic_datam[`topic_` + value][index]["version_id"] = res.data.triggered_data.version_id
              topic_datam[`topic_` + value][index]["triggered_time"] = res.data.triggered_data.triggered_time
              topic_datam[`topic_` + value][index]["triggered_count"] = res.data.triggered_data.triggered_count
              if (type && type === "2") {
                topic_datam[`topic_` + value][index]["end_activity"] = false
                topic_datam[`topic_` + value][index]["show_results"] = false
              }
            }
            setTopicsResources(topic_datam)
            displayResourceInPopUp(topic_datam[`topic_` + value][index])
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  const triggerLessonResource = async (fetch_type, datum, topic) => {
    let id = datum.id
    let value = topic.id
    let datum_id = `?id=${id}`
    let value_data = `&value=${value}`
    let res = await getRequest(
      `/lesson-board-triggering/${props.location.template_id}/${fetch_type}/${datum_id}${value_data}&update_version=true&training_id=${props.location.training_id}`
    )
    if (res.success) {
      setUpdatedResource(null)
      if (fetch_type === "resource_poped") {
        updateTriggereCount(datum, topic)
        setActiveTopic(value)
        let topic_datam = { ...topicsResources }
        let index = topic_datam[`topic_` + value].findIndex((resource) => resource.id === id)
        if (res.data.triggered_data && Object.keys(res.data.triggered_data).length > 0) {
          topic_datam[`topic_` + value][index]["version_id"] = res.data.triggered_data.version_id
          topic_datam[`topic_` + value][index]["triggered_time"] = res.data.triggered_data.triggered_time
          topic_datam[`topic_` + value][index]["triggered_count"] = res.data.triggered_data.triggered_count
          if (datum.resource_type === "quiz" && datum.resource_data.data_type === "2") {
            topic_datam[`topic_` + value][index]["end_activity"] = false
            topic_datam[`topic_` + value][index]["show_results"] = false
          }
        }
        setTopicsResources(topic_datam)
        displayResourceInPopUp(topic_datam[`topic_` + value][index])

        if (datum.resource_data.data_type === "1") {
          handlePollSave(datum, true, false, topic.id, "1", false)
        } else if (datum.resource_data.data_type === "2") {
          handlePollSave(datum, true, false, topic.id, "2", false)
        }
        if (datum.resource_type === "quiz" && datum.resource_data.data_type === "2") {
          props.setActiveActivityData({ current_active_activity: topic_datam[`topic_` + value][index] })
        } else {
          props.setActiveActivityData({ current_active_activity: res.data.current_active_activity })
        }
      }
    } else {
      props.setActiveActivityData({ current_active_activity: res.data.current_active_activity, active_breakout_session: res.data.active_breakout_session })
      props.setShowActiveActivity(true)
    }
  }

  // useEffect(() => {
  //   if(activityDataUpdated ){
  //     if(groupActivityData.event_type ==='seek' && originalSeekSupportData.length >0) {
  //       setShowSeekSupportModal(true)
  //     }else if(groupActivityData.event_type ==='request_more_time' && originalRequestTimeData.length > 0){
  //       setShowRequestTimeModal(true)
  //     }
  //   }
  // },[originalSeekSupportData.length,originalRequestTimeData.length])

  function fetchSharedResources() {
    axios
      .get(
        `${process.env.GATSBY_REACT_APP_API_HOST}/instructions/?instruction_type=1&published=true&show_resources=true&isEmpty=false&ordering=-id&paginate_by=4&breakout_session__id=${activeBreakoutSession?.id}`,
        { headers: login_credentials }
      )
      .then((res) => {
        let key = `as_${activeBreakoutSession?.id}`
        let data = { ...sharedResources }
        let dataUrl = { ...sharedResourcesNextUrl }
        let dataCount = { ...sharedResourcesCount }
        data[key] = res?.data?.results
        dataUrl[key] = res?.data?.next
        dataCount[key] = res?.data?.resources_count
        setSharedResources(data)
        setSharedResourcesNextUrl(dataUrl)
        setSharedResourcesCount(dataCount)
      })
      .catch((err) => {})
  }

  function fetchNextSharedResources(next_url) {
    axios
      .get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials })
      .then((res) => {
        let key = `as_${activeBreakoutSession?.id}`
        let data = { ...sharedResources }
        let dataUrl = { ...sharedResourcesNextUrl }
        data[key] = [...data[key], ...res?.data?.results]
        dataUrl[key] = res?.data?.next
        setSharedResources(data)
        setSharedResourcesNextUrl(dataUrl)
      })
      .catch((err) => {})
  }

  function handleSharedResourcesScroll(e) {
    e.stopPropagation()
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5 && sharedResourcesNextUrl["as_" + activeBreakoutSession?.id]) {
      fetchNextSharedResources(sharedResourcesNextUrl["as_" + activeBreakoutSession?.id])
    }
  }

  useEffect(() => {
    if (activeBreakoutBtn === "shared_items") {
      if (sharedResources && sharedResources[`as_${activeBreakoutSession?.id}`] === undefined) {
        fetchSharedResources()
      }
    }
  }, [activeBreakoutBtn])

  useEffect(() => {
    if (activityDataUpdated) {
      if (notificationsData.length > 0) {
        setShowNotificationsModal(true)
      }
    }
  }, [notificationsData.length])

  useEffect(() => {
    if (activityDataUpdated) {
      if (groupActivityData.event_type === "seek") {
        let dummy = [...notificationsData]
        if (dummy.length === 0) {
          dummy.push(groupActivityData)
          setNotificationsData(dummy)
        } else {
          if (groupActivityData.value === dummy[dummy.length - 1].value) {
            if (groupActivityData.student_id != dummy[dummy.length - 1].student_id) {
              dummy.push(groupActivityData)
              setNotificationsData(dummy)
            }
          } else {
            dummy.push(groupActivityData)
            setNotificationsData(dummy)
          }
        }
      } else if (groupActivityData.event_type === "request_more_time") {
        let dummy = [...modulesData["module_Doing"]["topics"]]
        if (activeBreakoutSession === "" || activeBreakoutSession === null) {
          for (let i = 0; i < dummy.length; i++) {
            for (let j = 0; j < dummy[i]["breakout_sessions"].length; j++) {
              if (parseInt(dummy[i]["breakout_sessions"][j].id) === parseInt(groupActivityData.session_id)) {
                setActiveBreakoutSession(dummy[i]["breakout_sessions"][j])
                break
              }
            }
          }
        }
        dummy = [...notificationsData]
        if (dummy.length === 0) {
          dummy.push(groupActivityData)
          setNotificationsData(dummy)
        } else {
          // if(groupActivityData.value === dummy[0].value ) {
          //   if(groupActivityData.student_id != dummy[0].student_id) {
          //     dummy.unshift(groupActivityData)
          //     setNotificationsData(dummy)
          //   }
          // }else {
          dummy.push(groupActivityData)
          setNotificationsData(dummy)
          // }
        }
      } else if (groupActivityData.event_type === "update_group_progress") {
        // Update current breakout session group progress from websocket
        let current_progress = [...breakoutGroupsProgress]
        let group_index = current_progress.findIndex((group) => group.id === groupActivityData.group_id)
        let group = current_progress[group_index]
        if (group) {
          group.breakout_session_report.completed_resources = group.breakout_session_report.completed_resources + 1
          current_progress[group_index] = group
          setBreakOutGroupsProgress(current_progress)
        }
      } else if (groupActivityData?.action === "report_card_updated") {
        if (groupActivityData?.source === "quiz") {
          fetchQuizGroupReport(groupActivityData?.report_card_id, groupActivityData?.resource)
          //update group progress here
          let key = `res_${groupActivityData?.resource}`
          let data = { ...activityWiseQuizGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === groupActivityData?.group_id)
            if (index !== -1) {
              data[key].results[index].finished_percentage = groupActivityData?.progress_percentage
              setActivityWiseQuizGroups(data)
            }
          }
        } else if (groupActivityData?.source === "case_study") {
          fetchCaseStudyGroupReport(groupActivityData?.report_card_id, groupActivityData?.resource)
          //update group progress here
          let key = `res_${groupActivityData?.resource}`
          let data = { ...activityWiseCaseStudyGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === groupActivityData?.group_id)
            if (index !== -1) {
              data[key].results[index].finished_percentage = groupActivityData?.progress_percentage
              setActivityWiseCaseStudyGroups(data)
            }
          }
        }
      } else if (groupActivityData?.action === "update_answer") {
        if (groupActivityData?.source === "checklist") {
          // (groupActivityData?.group_report_card_id)
          setActiveChecklistGroupRefresh((prev) => prev + 1)
        }
      } else if (groupActivityData?.action === "report_card_created_for_group") {
        if (groupActivityData?.source === "case_study") {
          let key = `res_${groupActivityData?.resource}`
          let data = { ...activityWiseCaseStudyGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === groupActivityData?.group_id)
            if (index !== -1) {
              data[key].results[index].report_card = groupActivityData?.report_card_id
              setActivityWiseCaseStudyGroups(data)
            }
          }
        } else if (groupActivityData?.source === "quiz") {
          let key = `res_${groupActivityData?.resource}`
          let data = { ...activityWiseQuizGroups }
          if (data[key]) {
            let index = data[key]?.results.findIndex((res) => res.group_id === groupActivityData?.group_id)
            if (index !== -1) {
              data[key].results[index].report_card = groupActivityData?.report_card_id
              setActivityWiseQuizGroups(data)
            }
          }
        } else if (groupActivityData?.event_type === "match_the_following") {
          let data = { ...activityWiseMTFGroups }
          let targetData = data[`res_${groupActivityData?.data?.resource_id}`]
          if (targetData) {
            let index = targetData.findIndex((group) => group?.group_id == groupActivityData?.group_id)
            if (targetData[index]) {
              targetData[index].report_card = groupActivityData?.data?.report_card
              targetData[index].group_status = groupActivityData?.data?.group_status
              setActivityWiseMTFGroups(data)
            }
          }
        }
      } else if (groupActivityData?.action === "submitted") {
        if (groupActivityData?.event_type === "match_the_following") {
          let data = { ...activityWiseMTFGroups }
          let targetData = data[`res_${groupActivityData?.data?.instance?.resource_id}`]
          if (targetData) {
            let index = targetData.findIndex((group) => group?.group_id == groupActivityData?.group_id)
            if (targetData[index]) {
              targetData[index].group_status = "2"
              setActivityWiseMTFGroups(data)
            }
          }
          if (groupActivityData?.group_id == activeMTFGroup?.group_id) {
            let activeData = { ...activeMTFGroup }
            activeData.group_status = "2"
            setActiveMTFGroup(activeData)
          }
        }
      }
    }
  }, [groupActivityData, activityDataUpdated])

  useEffect(() => {
    if (activeBreakoutSession?.status === "0") {
      if (breakoutSessionWSConn) {
        breakoutSessionWSConn.close()
        connectGroupActivitySocket()
      }
      // fetchSessionModules()
    }
  }, [activeBreakoutSession])

  useEffect(() => {
    if (breakoutGroupsIds) {
      if (breakoutGroupsIds.length > 0) {
        if (breakoutSessionWSConn) {
          breakoutSessionWSConn.close()
        }
        connectGroupActivitySocket()
      }
    }
    return () => {
      if (fetchDataWs && fetchDataWs.current) {
        fetchDataWs.current.close()
      }
      if (breakoutGroupsWs && breakoutGroupsWs.current) {
        breakoutGroupsWs.current.close()
      }
    }
  }, [breakoutGroupsIds])

  const connectGroupActivitySocket = () => {
    if (authClient.isAuthenticated()) {
      let updateSocket = WsConnect(`/ws/breakout-groups/`)
      updateSocket.onmessage = function (e) {
        let data = JSON.parse(e.data)
        if (data.value === "echo") return
        let responseData = JSON.parse(data.value)
        const isInBreakOutGroup = breakoutGroupsIds.includes(parseInt(responseData.group_id))
        if (isInBreakOutGroup) {
          setGroupActivityData(responseData)
          setActivityDataUpdated(true)
        }
      }
      updateSocket.onclose = function (e) {
        if (!e.wasClean) {
          setTimeout(connectGroupActivitySocket(), 5000)
        }
      }
      updateSocket.onopen = function (e) {
        setBreakoutSessionWSConn(updateSocket)
        breakoutGroupsWs.current = updateSocket
      }
    }
  }

  // useEffect(() => {
  //   if (modules && modules.length > 0 && modulesFetched) {
  //     fetchCurrentTopics(modules[0].id,modules[0].name, 0)
  //   }
  // }, [activeSession])

  async function fetchSessionModules() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/default-course-templates-modules-list/${props.location.template_id}/?module_type=3&basic_data=true&lb_sequence=true&training_id=${props.location.training_id}`,
        { headers: login_credentials }
      )
      .then((res) => {
        setActiveSession(res.data.active_session)
        setBreakoutSessionActive(res.data.breakout_active_session)
        setActiveBreakoutSession(res.data.breakout_active_session)
        setSessions(res.data.results)
        setSessionsNext(res.data.next)
        setBreakoutGroupsIds(res.data.groups)
        setFetchedGroupIds(true)
        setSessionsFetched(true)
        fetchLessonBoardModules()
        props.setTrainingBreak(res.data.training_break)
        setOngoingActivityData({ current_active_activity: res.data?.current_active_activity, active_breakout_session: res.data?.active_breakout_session })
        props.setActiveActivityData({ current_active_activity: res.data?.current_active_activity, active_breakout_session: res.data?.active_breakout_session })
        if (res.data.live_discussion_board) {
          props.setDiscussionBoard(res.data.live_discussion_board.id)
        }
      })
  }

  async function fetchNextSessionModules(next_url) {
    setSessionsFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_topic_resources = sessions
      if (res.data.results.length > 0) {
        for (var tprs = 0; tprs <= res.data.results.length - 1; tprs++) {
          next_topic_resources.push(res.data.results[tprs])
        }
        setSessions(next_topic_resources)
      }
      if (activeSession !== res.data.active_session) {
        setActiveSession(res.data.active_session)
      }
      setSessionsNext(res.data.next)
      setSessionsFetched(true)
    })
  }

  async function fetchBreakoutSessionProgress(session_id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/breakoutgroups-progress/${session_id}/?training=${props.location.training_id}&paginate_by=30`, {
        headers: login_credentials,
      })
      .then((res) => {
        setBreakOutGroupsProgress(res.data.results)
      })
  }

  async function fetchCurrentSessionTopics(session, refetch = false) {
    let session_id = session.id
    if ((sessionsData && sessionsData[`session_` + session.id] === undefined) || refetch) {
      setSessionTopicsFetching(false)
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-topics-list/?module=${session_id}&paginate_by=8`, { headers: login_credentials })
        .then((res) => {
          modulesIds[`session_` + session.id] = session_id
          let datum = sessionsData
          datum[`session_` + session.id] = {}
          datum[`session_` + session.id].topics = res.data.results
          datum[`session_` + session.id].topics_next = res.data.next
          setSessionsData(datum)
          setSessionTopicsFetching(true)
        })
        .catch((error) => {
          setSessionTopicsFetching(false)
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  async function fetchNextCurrentSessionTopics(next_url, session) {
    setSessionTopicsFetching(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let datum = sessionsData
      var next_session_topics = datum[`session_` + session.id].topics
      if (res.data.results.length > 0) {
        for (var mrs = 0; mrs <= res.data.results.length - 1; mrs++) {
          next_session_topics.push(res.data.results[mrs])
        }
        datum[`session_` + session.id].topics = next_session_topics
      }
      datum[`session_` + session.id].topics_next = res.data.next
      setSessionsData(datum)
      setSessionTopicsFetching(true)
    })
  }

  useEffect(() => {
    if (modules && modules.length > 0) {
      setModulesDataFetched(false)
      setTopicsFetching(false)
      fetchCurrentTopics(modules[0].id, modules[0].name, 0)
    }
  }, [modules])

  async function fetchLessonBoardModules() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/default-course-templates-modules-list/${props.location.template_id}/?module_type=4&ordering=id`, {
        headers: login_credentials,
      })
      .then((res) => {
        setModules(res.data.results)
        setModulesNext(res.data.next)
        setModulesFetched(true)
      })
  }

  useEffect(() => {
    if (modulesDataFetched) {
      setDataUpdated(!dataUpdated)
    }
  }, [modulesDataFetched])

  function fetchCurrentTopics(module_id, module_name, index, active_url_session = false, refetch = false) {
    if ((modulesData && modulesData[`module_` + module_name.replaceAll(" ", "_")] === undefined) || active_url_session || refetch) {
      let active_session = activeSession === null || active_url_session ? `&active_session=null` : `&active_session=${activeSession.id}`
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/basic-topics-list/?module=${module_id}&paginate_by=8${active_session}`, { headers: login_credentials })
        .then((res) => {
          modulesIds[`module_` + module_name.replaceAll(" ", "_")] = module_id
          let datum = modulesData
          datum[`module_` + module_name.replaceAll(" ", "_")] = {}
          datum[`module_` + module_name.replaceAll(" ", "_")].topics = res.data.results
          datum[`module_` + module_name.replaceAll(" ", "_")].topics_next = res.data.next
          setModulesData(datum)
          setTopicsFetching(true)
          if (index < modules.length - 1) {
            fetchCurrentTopics(modules[index + 1].id, modules[index + 1].name, index + 1, active_url_session)
          }
          if (index === modules.length - 1) {
            setModulesDataFetched(true)
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  async function fetchNextCurrentTopics(next_url, module_name) {
    setTopicsFetching(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let datum = modulesData
      var next_module_topics = datum[`module_` + module_name.replaceAll(" ", "_")].topics
      if (res.data.results.length > 0) {
        for (var mrs = 0; mrs <= res.data.results.length - 1; mrs++) {
          next_module_topics.push(res.data.results[mrs])
        }
        datum[`module_` + module_name.replaceAll(" ", "_")].topics = next_module_topics
      }
      datum[`module_` + module_name.replaceAll(" ", "_")].topics_next = res.data.next
      setModulesData(datum)
      setTopicsFetching(true)
    })
  }

  async function copyModuleToModule(session) {
    let session_id = session.id
    let sessionsQACopy = [...sessionsQA]
    sessionsQACopy[1].id = session_id
    setSessionsQA(sessionsQACopy)
    setModulesDataFetched(false)
    var index = sessions.findIndex((res) => res.id === session_id)
    let active_session = `&active_session=${session_id}`
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/session-to-doing/${session_id}/${modules[0].id}/?paginate_by=8${active_session}`, {
        headers: login_credentials,
      })
      .then((res) => {
        setActiveSession({ name: session.name, id: session_id })
        var topics_datam = modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics
        if (res.data.results.length > 0) {
          for (var t_data = 0; t_data <= res.data.results.length - 1; t_data++) {
            topics_datam.push(res.data.results[t_data])
          }
          modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics = topics_datam
        }
        modulesData[`module_` + modules[0].name.replaceAll(" ", "_")].topics_next =
          res.data.next !== null
            ? res.data.next.replace(`session-to-doing/${session_id}/${modules[0].id}/`, `basic-topics-list/?module=${modules[0].id}`).replace("?page", "&page")
            : res.data.next
        sessions[index].session_type = "1"
        triggerChanges("spsessionupdate", sessions[index].id, "1")
        setModulesDataFetched(true)
      })
  }

  useEffect(() => {
    fetchTopicResources()
  }, [selectedTopic, topicsResourcesFetched])

  useEffect(() => {
    if (bulkTopicsRefetched) {
      setTimeout(function () {
        setDataFetched(!dataFetched)
      }, 300)
    }
  }, [bulkTopicsRefetched])

  async function fetchTopicResources(id = null, refetch = false, second = false) {
    let selected_topic
    if (refetch) {
      selected_topic = id
    } else {
      selected_topic = selectedTopic
    }
    if ((selected_topic !== null && topicsResources[`topic_` + selected_topic] === undefined) || refetch) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/resources-names-list/?paginate_by=8&topic=${selected_topic}&is_poll=false&template_id=${props.location.template_id}&for_trigger=true`,
          { headers: login_credentials }
        )
        .then((res) => {
          let datum = topicsResources
          datum[`topic_` + selected_topic] = res.data.results
          setTopicsResources(datum)
          topicsResourcesNext[`topic_` + selected_topic] = res.data.next
          setTopicsResourcesFetched(true)
          setTopicsRefetched(true)
          if (second) {
            setBulkTopicsRefetched(true)
          }
        })
    }
  }

  async function fetchNextTopicResources(next_url, topic_id) {
    setTopicsResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_topic_resources = topicsResources[`topic_` + topic_id]
      if (res.data.results.length > 0) {
        for (var tprs = 0; tprs <= res.data.results.length - 1; tprs++) {
          next_topic_resources.push(res.data.results[tprs])
        }
        topicsResources[`topic_` + topic_id] = next_topic_resources
      }
      topicsResourcesNext[`topic_` + topic_id] = res.data.next
      setTopicsResourcesFetched(true)
    })
  }

  function endSession(event, datum, type = "end_session") {
    let sessionsQACopy = [...sessionsQA]
    sessionsQACopy[1].id = 0
    setSessionsQA(sessionsQACopy)
    var index = sessions.findIndex((res) => res.id === datum.id)
    datum.session_type = "2"
    event.preventDefault()
    if (datum.id) {
      datum[event.target.name] = event.target.value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k] !== null) {
          if (k === "start_date" || k === "end_date") {
            if (datum[k]) {
              formData.append(k, new Date(datum[k]).toISOString())
            }
          } else {
            formData.append(k, datum[k])
          }
        }
      }
      if (type === "to_next_session" && sessions.length > 1) {
        formData.append("next_session_id", sessions[index + 1].id)
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/modules-session-type/${datum.id}/?type=${type}`, formData, { headers: DefaultHeader })
        .then((res) => {
          sessions[index].session_type = res.data.session_type
          setDataUpdated(!dataUpdated)
          setActiveSession(null)
          setModulesDataFetched(false)
          setTopicsFetching(false)
          fetchCurrentTopics(modules[0].id, modules[0].name, 0, true)
          triggerChanges("spsessionupdate", sessions[index].id, `2_$_${type === "to_next_session" && sessions.length > 1 ? sessions[index + 1].id : type}`)
          const [removed] = sessions.splice(index, 1)
          // sessions.splice(sessions.length, 0, removed);
          setEndSessionModal("")
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function openModal(session) {
    if (session.session_type === "0") {
      fetchCurrentSessionTopics(session)
      setSessionModal(session)
    }
  }

  function endButtonPressed(e, session) {
    if (modulesData && modulesData[`module_To_Do`] && modulesData[`module_Doing`]) {
      if (modulesData[`module_To_Do`].topics.length + modulesData[`module_Doing`].topics.length === 0) {
        endSession(e, session)
      } else {
        setEndSessionModal(session)
      }
    }
  }

  function sessionsDataSet(key, session, index, type = null) {
    let desc = session?.description ?? ""
    let sessionNameDesc = session.name + (desc.length > 0 ? ` : ${desc}` : "")
    let sessionNameDescTooltip =
      "<span><b>Name: </b>" + session.name + "<br /><b>Objective: </b>" + (desc.length > 0 ? desc.replace(/\r?\n/g, "<br />") : "") + "</span>"

    return (
      <div
        key={key}
        className={`${
          type === null && index === 0 ? `d-none` : `d-flex align-items-center`
        } ${index} mx-3 my-2 px-3 py-2 justify-content-between inner-element-box-shadow ${session.session_type === "0" ? "pointer" : ""} ${
          StylesA.transparent_border
        } ${StylesA.border_on_hover} ${session.session_type === "1" && "active-session-bg"}`}
        style={{
          backgroundColor: "#303C54",
          color: "#E0E2E5",
          borderRadius: "9px",
        }}
        onClick={(e) => {
          e.preventDefault()
          dragPosition()
          openModal(session)
        }}
      >
        <div className="mr-5 flex-shrink-0 fs-16px">
          <FontAwesomeIcon icon={faClock} /> {TimeDifference.getTimeDifference(session.start_date, session.end_date)}
        </div>
        <div className="d-flex w-100 flex-grow-1 flex-wrap justify-content-between align-self-center align-items-center" style={{ fontSize: "16px" }}>
          {/*<div className="elipsis-1">{session.name}</div>*/}
          <div style={{ width: `calc(90vw - 500px)` }} className="py-2 text-truncate">
            {sessionNameDescTooltip.length > 30 ? (
              <OverlayTooltip
                tooltipText={sessionNameDescTooltip}
                text={sessionNameDesc}
                tooltipStyle={{
                  fontSize: 14,
                  backgroundColor: "#061124",
                  color: "#DFE2E5",
                }}
              />
            ) : (
              <span>{sessionNameDesc}</span>
            )}
          </div>
          {session && session.session_type && session.session_type === "0" && modulesFetched ? (
            <div className={`align-self-center`}>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  dragPosition()
                  copyModuleToModule(session)
                }}
                className={`btn inner-element-box-shadow ${Style.border_on_hover}`}
                style={{
                  backgroundColor: "#586886",
                  borderRadius: "18px",
                  color: "#E0E2E5",
                  fontSize: "14px",
                }}
                disabled={!(index === 0 || sessions[index - 1].session_type > "1") || activeSession !== null}
              >
                Start Session{" "}
              </button>
            </div>
          ) : null}
          {session && session.session_type && session.session_type === "1" && modulesFetched ? (
            <div className={`align-self-center d-inline-flex align-items-center`}>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  dragPosition()
                  endButtonPressed(e, session)
                }}
                className={`btn inner-element-box-shadow ${Style.border_on_hover}`}
                style={{
                  backgroundColor: "#586886",
                  borderRadius: "18px",
                  color: "#E0E2E5",
                  fontSize: "14px",
                }}
              >
                End Session
              </button>
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  function sessionBoard() {
    return (
      <Accordian className="px-2">
        {sessions.length > 0 ? (
          <div
            className={`d-flex ${props.minScroll ? Style.w_min : "w-100"} mx-2 my-2 flex-column inner-element-box-shadow`}
            style={{
              backgroundColor: "#212C42",
              borderRadius: "9px",
              color: "#E0E2E5",
              zIndex: "1",
            }}
          >
            <div className="d-flex px-2 justify-content-between" style={{ borderBottom: "1px solid #42506C" }}>
              <div className="align-self-center">Sessions Overview</div>
              <Accordian.Toggle as={Button} variant={"none"} className={`text-white shadow-none ${sessions.length === 1 ? `d-none` : ``}`} eventKey="1">
                <FontAwesomeIcon
                  onClick={(e) => {
                    e.preventDefault()
                    dragPosition()
                    setShowSessionList(!showSessionList)
                  }}
                  icon={showSessionList ? faChevronCircleUp : faChevronCircleDown}
                />
              </Accordian.Toggle>
            </div>
            {sessions.slice(0, 1).map((session, index) => sessionsDataSet(index + `sessions`, session, index, "active_session"))}
            <Accordian.Collapse
              eventKey="1"
              onScroll={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              style={{ maxHeight: "70vh", overflowY: "auto", zIndex: "99" }}
            >
              <>
                {sessions.length > 1 ? sessions.map((session, index) => sessionsDataSet(index + `sessions`, session, index)) : null}
                <div className={`text-center`}>
                  <Button
                    variant="none"
                    onClick={(e) => {
                      e.preventDefault()
                      fetchNextSessionModules(sessionsNext)
                    }}
                    className={`text-center px-3 py-2 mt-2 ${sessionsNext === null ? `d-none` : ``}`}
                    style={{
                      color: `#c6cff4`,
                      borderRadius: `15px`,
                      fontSize: `12px`,
                    }}
                  >
                    See More
                  </Button>
                </div>
              </>
            </Accordian.Collapse>
          </div>
        ) : null}
      </Accordian>
    )
  }

  function showSessionTopicResources(datum) {
    let { typeId, imgObj, imgType } = getImageToBeRendered(datum)
    return (
      <>
        <div className={`d-flex justify-content-start inner-element-box-shadow`} style={{ backgroundColor: "#4F6080", color: "#E0E2E5" }}>
          <div className="mb-0 pr-1">
            <img className="mb-0" style={{ height: "30px", width: "38px" }} src={imgObj} />
          </div>
          <div className="align-self-center fs-14px">{datum.resource_data?.display_name ?? datum.resource_data?.name}</div>
        </div>
      </>
    )
  }

  useEffect(() => {
    if (updateInputData && Object.keys(updateInputData).length > 0) {
      handleSaveSessions(updateInputData.name, updateInputData.value, updateInputData.datum, updateInputData.type)
    }
  }, [Object.keys(updateInputData).length > 0])

  function sendQuery(name, value, datum, type) {
    setUpdateInputData({ name: name, value: value, datum: datum, type: type })
  }

  const delayedQuery = useCallback(
    _.debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  function onBlurData(name, value, datum, type) {
    if (name !== null) {
      handleSaveSessions(name, value, datum, type)
    }
  }

  function settingLoader(module_id, index, type) {
    let datum
    if (type === "modulesData") {
      if (sessionsData[`session_` + module_id].topics[index].loader === undefined || sessionsData[`session_` + module_id].topics[index].loader !== true) {
        datum = sessionsData
        datum[`session_` + module_id].topics[index].loader = true
        setSessionsData(datum)
        setDataUpdated(!dataUpdated)
      }
    } else if (type === "module_data") {
      if (module_id === "name") {
        sessionModal.loader = true
        setDataUpdated(!dataUpdated)
      } else if (module_id === "duration") {
        sessionModal.loader1 = true
        setDataUpdated(!dataUpdated)
      } else if (module_id === "description") {
        sessionModal.loader2 = true
        setDataUpdated(!dataUpdated)
      }
    }
  }

  function sessionEndModal() {
    return (
      <Modal
        show={endSessionModal !== ""}
        onHide={(e) => {
          setEndSessionModal("")
        }}
        centered
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <Modal.Header
          className={`border-0`}
          style={{
            backgroundColor: "#121B2B",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <img
            onClick={(e) => {
              e.preventDefault()
              setEndSessionModal("")
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              position: "absolute",
              top: "7px",
              right: "5px",
            }}
          />
        </Modal.Header>
        <Modal.Body
          className="text-center pt-0"
          style={{
            backgroundColor: "#121B2B",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <p className={`mb-3 px-5`} style={{ color: "#E0E2E5", fontSize: "16px", marginBottom: "10px" }}>
            {" "}
            There are unfinished topics in this session. What would you like to do? <br />
            <span style={{ fontSize: "14px" }}>Session: {endSessionModal.name}</span>
          </p>
          <div className={`mx-auto`} style={{ width: `fit-content` }}>
            <button
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                endSession(e, endSessionModal)
              }}
              className={`w-100 d-block btn inner-element-box-shadow  mb-2 mx-auto ${Style.border_on_hover}`}
              style={{
                backgroundColor: "#303C54",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Yes, End the Session{" "}
            </button>
            {sessions.length > 1 ? (
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  endSession(e, endSessionModal, "to_next_session")
                }}
                className={`w-100 d-block btn inner-element-box-shadow  mb-2 mx-auto ${Style.border_on_hover}`}
                style={{
                  backgroundColor: "#303C54",
                  color: "#E0E2E5",
                  fontSize: "14px",
                }}
              >
                Move to Next Session{" "}
              </button>
            ) : null}
            <button
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                endSession(e, endSessionModal, "to_lesson_plan")
              }}
              className={`w-100 d-block btn inner-element-box-shadow  mb-2 mx-auto ${Style.border_on_hover}`}
              style={{
                backgroundColor: "#303C54",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Move to Unscheduled Topics{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  async function deleteItemData(e, type, current_item, move_to) {
    let index
    if (type === "modules") {
      index = sessions.findIndex((res) => res.id === current_item.id)
      let url = `/modules-session-type/`
      let filter = ""
      if (move_to === undefined) {
        url = `/modules/`
        filter = `?delete_children=true`
      }
      let next_session_id = sessions[index + 1] ? sessions[index + 1].id : null
      if (move_to === "to_next_session") {
        filter = `?type=${move_to}&next_session_id=${sessions[index + 1].id}`
      } else if (move_to === `to_lesson_plan`) {
        filter = `?type=${move_to}`
      }
      axios
        .delete(process.env.GATSBY_REACT_APP_API_HOST + url + current_item.id + `/${filter}`, { headers: DefaultHeader, body: current_item })
        .then((res) => {
          sessions.splice(index, 1)
          setDeleteItem("")
          setSessionModal("")
          triggerChanges(`lbsessiondeleted`, current_item.id, move_to === "to_next_session" ? next_session_id : move_to)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else if (type === "topics") {
      index = sessionsData[`session_` + sessionModal.id].topics.findIndex((res) => res.id === current_item.id)
      let m_index = sessions.findIndex((res) => res.id === sessionModal.id)
      let next_session_id = sessions[m_index + 1] ? sessions[m_index + 1].id : null
      var formData = new FormData()
      formData.append("id", current_item.id)
      formData.append("name", current_item.name)
      if (move_to === "to_lesson_plan") {
        formData.append("module", "getlessonplan")
        formData.append("duplicate", false)
      } else if (move_to === "to_next_session") {
        let n_index = sessions.findIndex((res) => res.id === parseInt(deleteItem.module_id))
        formData.append("module", sessions[n_index + 1].id)
        formData.append("duplicate", "add_to_do")
      }
      if (move_to === undefined) {
        axios
          .delete(process.env.GATSBY_REACT_APP_API_HOST + `/topics/` + current_item.id + "/?delete_children=true", {
            headers: DefaultHeader,
            body: current_item,
          })
          .then((res) => {
            sessionsData[`session_` + sessionModal.id].topics.splice(index, 1)
            setDeleteItem("")
            triggerChanges(`lbtopicdeleted`, current_item.id + `_$_` + sessionModal.id, move_to === "to_next_session" ? next_session_id : move_to)
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      } else {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/topics/` + current_item.id + "/", formData, { headers: DefaultHeader })
          .then((res) => {
            sessionsData[`session_` + sessionModal.id].topics.splice(index, 1)
            setDeleteItem("")
            triggerChanges(`lbtopicdeleted`, current_item.id + `_$_` + sessionModal.id, move_to === "to_next_session" ? next_session_id : move_to)
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()
              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    }
  }

  function deleteItemModal() {
    let index = ""
    if (deleteItem !== "" && deleteItem.session_type) {
      index = sessions.findIndex((res) => res.id === parseInt(deleteItem.id))
    } else if (deleteItem !== "") {
      index = sessions.findIndex((res) => res.id === parseInt(deleteItem.module_id))
    }
    return (
      <Modal
        show={deleteItem !== ""}
        onHide={(e) => {
          setDeleteItem("")
        }}
        centered
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        <Modal.Header
          className={`border-0`}
          style={{
            backgroundColor: "#121B2B",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <img
            onClick={(e) => {
              e.preventDefault()
              setDeleteItem("")
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`my-auto mr-1 pointer`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              position: "absolute",
              top: "7px",
              right: "5px",
            }}
          />
        </Modal.Header>
        <Modal.Body
          className="text-center pt-0"
          style={{
            backgroundColor: "#121B2B",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <p className={`mb-3`} style={{ color: "#E0E2E5", fontSize: "16px", marginBottom: "10px" }}>
            {" "}
            Are you sure you want to Delete the {deleteItem.session_type ? "session" : "topic"}? <br />
            <span style={{ fontSize: "14px" }}>
              {deleteItem.session_type ? `Session` : `Topic`}: {deleteItem.name}
            </span>
          </p>
          <div className={`mx-auto`} style={{ width: `fit-content` }}>
            <button
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                deleteItemData(e, deleteItem.session_type ? `modules` : `topics`, deleteItem)
              }}
              className={`w-100 d-block btn inner-element-box-shadow  mb-2 mx-auto ${Style.border_on_hover}`}
              style={{
                backgroundColor: "#303C54",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Yes, Delete the {deleteItem.session_type ? `Session` : `Topic`}
            </button>
            {sessions.length - 1 === index ? null : (
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  deleteItemData(e, deleteItem.session_type ? `modules` : `topics`, deleteItem, "to_next_session")
                }}
                className={`w-100 d-block btn inner-element-box-shadow  mb-2 mx-auto ${Style.border_on_hover}`}
                style={{
                  backgroundColor: "#303C54",
                  color: "#E0E2E5",
                  fontSize: "14px",
                }}
              >
                Move {deleteItem.session_type ? `Topics` : `Topic`} to Next Session{" "}
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                deleteItemData(e, deleteItem.session_type ? `modules` : `topics`, deleteItem, "to_lesson_plan")
              }}
              className={`w-100 d-block btn inner-element-box-shadow  mb-2 mx-auto ${Style.border_on_hover}`}
              style={{
                backgroundColor: "#303C54",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Move {deleteItem.session_type ? `Topics` : `Topic`} to Unscheduled Topics{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
  function handleDurationSave(datum, new_value, name, type) {
    if (datum.id) {
      datum[name] = new_value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k] !== null) {
          if (k === "start_date" || k === "end_date") {
            if (datum[k]) {
              formData.append(k, new Date(datum[k]).toISOString())
            }
          } else {
            formData.append(k, datum[k])
          }
        }
      }
      if (type) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}s/` + datum.id + "/?editing=true", formData, { headers: DefaultHeader })
          .then((res) => {
            if (type === "module") {
              triggerChanges(`lbsessionedited`, datum.id + "_$_" + new_value, name)
            } else {
            }
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    } else {
    }
  }

  function handleSaveSessions(name, value, datum, type) {
    if (datum.id && (datum.loader || datum.loader1 || datum.loader2)) {
      datum[name] = value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k] !== null && k !== "suggested_by") {
          if (k === "start_date" || k === "end_date") {
            if (datum[k]) {
              formData.append(k, new Date(datum[k]).toISOString())
            }
          } else if (k === "owner") {
            formData.append(k, datum[k].id)
          } else if (k === "activity_workspace") {
            formData.append("activity_workspace", JSON.stringify(datum[k]))
          } else {
            formData.append(k, datum[k])
          }
        }
      }
      if (type) {
        setTopicEdited(false)
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}s/` + datum.id + "/?editing=true", formData, { headers: DefaultHeader })
          .then((res) => {
            if (type === "topic") {
              if (sessionsData[`session_` + datum.module]) {
                let t_index = sessionsData[`session_` + datum.module].topics.findIndex((res) => res.id === datum.id)
                if (t_index > -1) {
                  let t_data = sessionsData
                  t_data[`session_` + datum.module].topics[t_index][name] = value
                  t_data[`session_` + datum.module].topics[t_index].loader = false
                  setSessionsData(t_data)
                  setTopicEdited(true)
                  let current_datam = t_data[`session_` + datum.module].topics[t_index]
                  triggerChanges(
                    `lbtopicedited`,
                    datum.id + "_$_" + datum[name] + `_$_` + name,
                    res.data.session_data === null ? res.data.session_data : res.data.session_data.id + `_` + res.data.session_data.session_type
                  )
                }
              }
            } else if (type === "module") {
              if (name === "name") {
                sessionModal.loader = false
              } else if (name === "duration") {
                sessionModal.loader1 = false
              } else if (name === "description") {
                sessionModal.loader2 = false
              }
              setTopicEdited(true)
              triggerChanges(`lbsessionedited`, datum.id + "_$_" + datum[name], name)
            }
            setUpdateInputData({})
          })
          .catch((error) => {
            let error_messages = ""
            for (var key in error.response.data) {
              error_messages += error.response.data[key] + "<br>"
            }
            if (error_messages === "Invalid token.<br>") {
              authClient.signOut()

              localStorage.removeItem("authenticity_token")
              localStorage.removeItem("username")
              window.location.reload(true)
            }
            document.getElementById("fixed-error-div").innerHTML =
              '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
              '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
              '<span aria-hidden="true">×</span></button>' +
              error_messages +
              "</div>"
          })
      }
    } else {
    }
  }

  function onDragEndModal(result) {
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "modalTopicDraggable") {
      const itemSubItemMap = sessionsData

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      // const sourceSubItems = itemSubItemMap[sourceParentId].topics;
      // const destSubItems = itemSubItemMap[destParentId].topics;

      let newItems = sessionsData[result.destination.droppableId]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(sessionsData[result.destination.droppableId].topics, sourceIndex, destIndex)
        let currentTopic = items[result.destination.index]
        let currentData = sessionsData[result.destination.droppableId].topics
        if (result.destination.index === 0) {
          currentTopic.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (result.destination.index === currentData.length - 1) {
          currentTopic.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (result.source.index < result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        currentTopic.session_id = result.destination.droppableId.split("session_")[1]
        handleDraggedItemSequence(currentTopic, "topics", "internal_reorder")
        sessionsData[result.destination.droppableId].topics = items
      }
    }
  }

  function sessionDetailModal() {
    let datum = sessionModal
    let module_id = sessionModal.id
    let index = sessions.findIndex((res) => res.id === datum.id)
    return (
      <Modal
        show={sessionModal !== ""}
        onHide={() => setSessionModal("")}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        className="classroom-poll-modal"
        style={{ paddingTop: "2rem", border: "none", color: "#E0E2E5" }}
      >
        <DragDropContext onDragEnd={onDragEndModal}>
          <Modal.Body
            onScroll={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{
              borderRadius: "10px",
              backgroundColor: "#212C41",
              padding: "0",
              border: "1px solid #929BBD",
              boxShadow: "0px 3px 12px #00000099",
              overflowY: "auto",
            }}
          >
            <div className={`text-right`}>
              <FontAwesomeIcon
                className="pointer mx-3 my-2"
                onClick={(e) => {
                  e.preventDefault()
                  setSessionModal("")
                }}
                icon={faTimesCircle}
                style={{ width: `15px`, height: `15px` }}
              />
            </div>
            <div className="justify-content-between" style={{ padding: "0px 15px 5px 15px" }}>
              <Col xs="12" sm="12" md="12" lg="12" className="d-flex pb-2 pt-3" style={{ backgroundColor: `#303C54` }}>
                {selectedInput === "module_" + datum.id + "name" ? (
                  <input
                    autoComplete="anyrandomstring"
                    type="text"
                    name="name"
                    id={datum.id + `_module_input_name`}
                    value={datum.name}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader("name", index, "module_data")
                      delayedQuery(e.target.name, e.target.value, datum, "module")
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === datum.name ? null : e.target.name, e.target.value, datum, "module")
                    }}
                    placeholder={"Enter Name"}
                    className={`mr-2 ${StylesA.border_on_hover} ${StylesA.session_input_field} form-control border-0 px-3 ${Styles.font_16}`}
                    disabled={datum.session_type && datum.session_type === "2"}
                    required
                  />
                ) : (
                  <input
                    autoComplete="anyrandomstring"
                    type="text"
                    name="name"
                    id={datum.id + `_module_input_name`}
                    defaultValue={datum.name}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader("name", index, "module_data")
                      delayedQuery(e.target.name, e.target.value, datum, "module")
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === datum.name ? null : e.target.name, e.target.value, datum, "module")
                    }}
                    placeholder={"Enter Name"}
                    className={`mr-2 ${StylesA.border_on_hover} ${StylesA.session_input_field} form-control border-0 px-3 ${Styles.font_16}`}
                    disabled={datum.session_type && datum.session_type === "2"}
                    required
                  />
                )}
                {datum.loader != undefined && datum.loader === true ? <Loader /> : null}
                <DurationInputField datum={datum} handleDurationSave={handleDurationSave} />
                <DateInputField datum={datum} handleDurationSave={handleDurationSave} />
                <Dropdown className="breakout-groups align-self-center pl-2 d-inline-flex">
                  <Dropdown.Toggle className="border-none p-0 shadow-none" style={{ backgroundColor: "transparent" }} id="dropdown-basic">
                    <div>
                      <FontAwesomeIcon
                        icon={faEllipsisV}
                        style={{
                          objectFit: `contain`,
                          height: `20px`,
                          color: `#E0E2E5`,
                        }}
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`}>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault()
                        sessionsData[`session_` + sessionModal.id].topics.length === 0 ? deleteItemData(e, `modules`, datum) : setDeleteItem(datum)
                      }}
                      className="text-white fs-14px px-2 py-1 d-inline-flex"
                      style={{ minWidth: `max-content` }}
                    >
                      <img src={DeleteIcon} alt={`topic-delete`} className={`my-auto`} style={{ objectFit: `contain`, height: `13px` }} />
                      &nbsp;Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs="12" sm="12" md="12" lg="12" className="pt-2" style={{ backgroundColor: `#303C54` }}>
                {selectedInput === "module_" + datum.id + "description" ? (
                  <textarea
                    type="text"
                    name="description"
                    id={datum.id + `_module_input_description`}
                    value={datum.description}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader("description", index, "module_data")
                      delayedQuery(e.target.name, e.target.value, datum, "module")
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === datum.description ? null : e.target.name, e.target.value, datum, "module")
                    }}
                    placeholder={"Session Objective"}
                    className={`${Styles.font_16} ${StylesA.session_text_area_field} p-2 border-none`}
                    disabled={datum.session_type && datum.session_type === "2"}
                    required
                  />
                ) : (
                  <textarea
                    type="text"
                    name="description"
                    id={datum.id + `_module_input_description`}
                    defaultValue={datum.description}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={(e) => {
                      e.preventDefault()
                      settingLoader("description", index, "module_data")
                      delayedQuery(e.target.name, e.target.value, datum, "module")
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      onBlurData(e.target.value === datum.description ? null : e.target.name, e.target.value, datum, "module")
                    }}
                    placeholder={"Session Objective"}
                    className={`${Styles.font_16} ${StylesA.session_text_area_field} ${StylesA.border_on_hover} p-2 border-none`}
                    disabled={datum.session_type && datum.session_type === "2"}
                    required
                  />
                )}
                {datum.loader2 != undefined && datum.loader2 === true ? <Loader /> : null}
              </Col>
              <Col xs="12" sm="12" md="12" lg="12" style={{ backgroundColor: `#303C54` }}>
                <div
                  style={{
                    border:
                      sessionsData && sessionsData[`session_` + sessionModal.id] && sessionsData[`session_` + sessionModal.id].topics.length > 0
                        ? "1px dotted"
                        : `0px solid`,
                    borderRadius: "8px",
                  }}
                >
                  <Droppable
                    isDropDisabled={props.disableLessonBoardDnd || showBreakoutSessionModal}
                    droppableId={`session_` + sessionModal.id}
                    type={`modalTopicDraggable`}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className={`br-9px`}>
                        {sessionsData && sessionsData[`session_` + sessionModal.id]
                          ? sessionsData[`session_` + sessionModal.id].topics.map((topic, index) => {
                              return (
                                <Draggable
                                  key={topic.id + `_topic_name_` + index}
                                  draggableId={topic.id.toString() + "-topic" + "_" + index.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`bg-transparent float-none py-1`}
                                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <div
                                        key={topic.id + `_topic_name_` + index}
                                        style={{ backgroundColor: "#42506C" }}
                                        className={`${StylesA.border_on_hover} ${StylesA.transparent_border} inner-element-box-shadow float-none`}
                                      >
                                        <div
                                          variant="none"
                                          id={"topics_" + topic.id}
                                          className={`px-2 py-1 shadow-none d-flex`}
                                          style={{
                                            color: `#F2F4FF`,
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div className="d-flex w-100 align-self-center">
                                            <img
                                              src={DragHandleIcon}
                                              alt={`drag-handle`}
                                              className={`my-auto mr-1 pointer`}
                                              style={{
                                                objectFit: `contain`,
                                                height: `13px`,
                                                width: "16px",
                                              }}
                                            />
                                            {selectedInput === "topic_" + topic.id + "name" ? (
                                              <input
                                                autoComplete="anyrandomstring"
                                                type="text"
                                                name="name"
                                                id={topic.id + `_topic_input_name`}
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  e.stopPropagation()
                                                }}
                                                value={topic.name}
                                                onChange={(e) => {
                                                  e.preventDefault()
                                                  topic.module = module_id
                                                  settingLoader(module_id, index, "modulesData")
                                                  delayedQuery(e.target.name, e.target.value, topic, "topic")
                                                }}
                                                onBlur={(e) => {
                                                  e.preventDefault()
                                                  onBlurData(e.target.value === topic.name ? null : e.target.name, e.target.value, topic, "topic")
                                                }}
                                                placeholder={"Activity name"}
                                                className={`${StylesA.activity_input_field} form-control border-0 px-2 ${Styles.font_16}`}
                                                disabled={
                                                  (module.session_type && module.session_type === "2") || (topic.topic_stage && topic.topic_stage === "disable")
                                                }
                                                required
                                              />
                                            ) : (
                                              <input
                                                autoComplete="anyrandomstring"
                                                type="text"
                                                name="name"
                                                id={topic.id + `_topic_input_name`}
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  e.stopPropagation()
                                                }}
                                                defaultValue={topic.name}
                                                onChange={(e) => {
                                                  e.preventDefault()
                                                  topic.module = module_id
                                                  settingLoader(module_id, index, "modulesData")
                                                  delayedQuery(e.target.name, e.target.value, topic, "topic")
                                                }}
                                                onBlur={(e) => {
                                                  e.preventDefault()
                                                  onBlurData(e.target.value === topic.name ? null : e.target.name, e.target.value, topic, "topic")
                                                }}
                                                placeholder={"Activity name"}
                                                className={`${StylesA.activity_input_field} form-control border-0 px-2 ${Styles.font_16}`}
                                                disabled={
                                                  (module.session_type && module.session_type === "2") || (topic.topic_stage && topic.topic_stage === "disable")
                                                }
                                                required
                                              />
                                            )}
                                            {topic.loader != undefined && topic.loader === true ? <Loader /> : null}
                                          </div>
                                          <div className={`align-self-center`}>
                                            <FontAwesomeIcon
                                              onClick={(e) => {
                                                e.preventDefault()
                                                setTopicsResourcesFetched(false)
                                                setSelectedTopic(topic.id)
                                                toggleTopic(topic.id)
                                              }}
                                              className="mr-2 pointer"
                                              icon={selectedTopicToggle[topic.id] ? faChevronCircleUp : faChevronCircleDown}
                                              style={{
                                                width: `16px`,
                                                height: `16px`,
                                                margin: `auto 0px`,
                                              }}
                                            />
                                          </div>
                                          <Dropdown className="breakout-groups">
                                            <Dropdown.Toggle
                                              className="border-none p-0 shadow-none"
                                              style={{
                                                backgroundColor: "transparent",
                                              }}
                                              id="dropdown-basic"
                                            >
                                              <div>
                                                <FontAwesomeIcon
                                                  icon={faEllipsisV}
                                                  style={{
                                                    objectFit: `contain`,
                                                    height: `20px`,
                                                    color: `#E0E2E5`,
                                                  }}
                                                />
                                              </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className={`br-9px breakout-groups ${Styles.min_fit_width}`}>
                                              <Dropdown.Item
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  topic.module_id = sessionModal.id
                                                  setDeleteItem(topic)
                                                }}
                                                className="text-white fs-14px px-2 py-1 d-inline-flex"
                                                style={{
                                                  minWidth: `max-content`,
                                                }}
                                              >
                                                <img
                                                  src={DeleteIcon}
                                                  alt={`topic-delete`}
                                                  className={`my-auto`}
                                                  style={{
                                                    objectFit: `contain`,
                                                    height: `13px`,
                                                  }}
                                                />
                                                &nbsp;Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                        <div style={{ backgroundColor: "#42506C" }} className={`px-2 py-1 ${selectedTopicToggle[topic.id] ? `` : `d-none`}`}>
                                          {topicsResources && topicsResources[`topic_` + topic.id] !== undefined
                                            ? topicsResources[`topic_` + topic.id].map((topic, index) => (
                                                <div className="mb-2" key={index + `resource` + topic.id}>
                                                  {showSessionTopicResources(topic)}
                                                </div>
                                              ))
                                            : null}
                                          {topicsResources && topicsResources[`topic_` + topic.id] !== undefined ? (
                                            <div className={`text-center`}>
                                              <Button
                                                variant="none"
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  fetchNextTopicResources(topicsResourcesNext[`topic_` + topic.id], topic.id)
                                                }}
                                                className={`text-center px-3 py-2 mt-2 ${topicsResourcesNext[`topic_` + topic.id] === null ? `d-none` : ``}`}
                                                style={{
                                                  backgroundColor: `#4b5a9b`,
                                                  color: `#c6cff4`,
                                                  borderRadius: `15px`,
                                                  fontSize: `12px`,
                                                }}
                                              >
                                                See More
                                              </Button>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            })
                          : null}
                        {provided.placeholder}
                        {sessionsData && sessionsData[`session_` + sessionModal.id] ? (
                          <div className={`text-center`}>
                            <Button
                              variant="none"
                              onClick={(e) => {
                                e.preventDefault()
                                fetchNextCurrentSessionTopics(sessionsData[`session_` + sessionModal.id].topics_next, sessionModal)
                              }}
                              className={`text-center px-3 py-2 mt-2 ${sessionsData[`session_` + sessionModal.id].topics_next === null ? `d-none` : ``}`}
                              style={{
                                backgroundColor: `#4b5a9b`,
                                color: `#c6cff4`,
                                borderRadius: `15px`,
                                fontSize: `12px`,
                              }}
                            >
                              See More
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </Droppable>
                </div>
              </Col>
            </div>
          </Modal.Body>
        </DragDropContext>
      </Modal>
    )
  }

  function toggleTopic(datum_id) {
    if (selectedTopicToggle[datum_id] === undefined) {
      selectedTopicToggle[datum_id] = true
    } else {
      selectedTopicToggle[datum_id] = !selectedTopicToggle[datum_id]
    }
    setSelectedTopicToggleChange(!selectedTopicToggleChange)
  }

  function updateBreakoutSessionStatus(session_id, status, topic_id = null) {
    axios
      .post(
        process.env.GATSBY_REACT_APP_API_HOST + `/update-breakout-session-status/${props.location.training_id}/${session_id}/${status}/`,
        {},
        { headers: DefaultHeader }
      )
      .then((res) => {
        if (status == 2) {
          setBreakoutTimeAlterationModal("dontShow")
          setShowEndBreakoutSessionModal(false)
          setShowBreakoutSessionModal(false)
          setBreakoutSession(null)
          setBreakoutSessionActive(null)
          setActiveBreakoutSession(null)
          props.setDisableScroll(false)
          props.setShowAllResources(false)
          if (props.triggerSession) {
            props.setTriggerSession(null)
          }
          setNotificationData({
            type: "success",
            title: "Ended Session Sucessfully",
          })
          clearBreakoutSessionTimer()
          setShowSuggestionModal(false)
          props.trainingWsConnection.send(JSON.stringify({ event_type: "breakout_session_status", data: { breakout_session_id: session_id, status: status } }))
          setBreakoutActivities(null)
          setActiveBreakoutBtn("overall_progress")
        }

        props.setActiveActivityData({})
        let dummy = { ...modulesData }
        let session_topic = dummy.module_Doing.topics.filter((topic) => topic.id === topic_id)[0]
        if (session_topic) {
          var index = session_topic.breakout_sessions.findIndex((res) => res.id === session_id)
          session_topic.breakout_sessions[index].status = status === 1 ? "1" : status === 2 ? "2" : "0"
          setModulesData(dummy)
        }

        if (res.data?.error && res.data?.error !== undefined) {
          if (res.data["error"] === "No breakout rooms") {
            setNotificationData({ type: "error", title: res.data["error"] })
            props.setActiveSection("BreakoutGroup")
          } else if (res.data["error"] === "Active session exists") {
            setNotificationData({ type: "error", title: res.data["error"] })
          }
        } else {
          //Open modal
          if (status == 1) {
            let dummy = { ...breakoutSession, ...res.data }
            setBreakoutSessionActive(dummy)
            setActiveBreakoutSession(dummy)
            setBreakoutSession(dummy)
            setShowBreakoutSessionModal(true)
            fetchBreakoutSessionProgress(session_id)
            props.setDisableScroll(true)
            props.setShowAllResources(true)
            setBreakoutSessionWS(true)
            setNotificationData({
              type: "success",
              title: "Session Started Sucessfully",
            })
            props.trainingWsConnection.send(
              JSON.stringify({ event_type: "breakout_session_status", data: { breakout_session_id: session_id, status: status } })
            )
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.current_active_activity) {
          setBreakoutTimeAlterationModal("dontShow")
          setShowBreakoutSessionModal(false)
          setBreakoutSession(null)
          props.setActiveActivityData({
            current_active_activity: error?.response?.data?.current_active_activity,
            active_breakout_session: error?.response?.data?.active_breakout_session,
          })
          props.setShowActiveActivity(true)
        } else {
          let err = error?.response?.data["error"]
          if (err && err.length > 0) {
            setBreakoutTimeAlterationModal("dontShow")
            setShowBreakoutSessionModal(false)
            setBreakoutSession(null)
            setNotificationData({
              type: "error",
              title: error?.response?.data["error"],
            })
          }
        }
      })
  }

  function convertToTime(x) {
    let totalSec = x * 60
    let [min, sec] = [Math.floor(totalSec / 60), Math.round(totalSec % 60)]
    return min.toString() + ":" + sec.toString()
  }

  async function triggerResource() {
    setTimeout(function () {
      axios
        .get(
          `${process.env.GATSBY_REACT_APP_API_HOST}/resources-names-list/?paginate_by=1&topic=${selectedTopic}&is_triggered=true&is_poll=false&template_id=${props.location.template_id}&for_trigger=true`,
          { headers: login_credentials }
        )
        .then((res) => {})
        .catch((error) => {})
    }, 300)
  }

  const displayResourceInPopUp = (resource, viewDetails = false) => {
    setGResource(resource)
    if (resource.resource_data !== selected_resource) {
      let data = { ...draggableHash }
      data.width = winWidth.current
      data.height = winHeight.current
      setPositionUpdated(false)
      if (viewDetails) {
        changeWindowScreen(true)
      } else {
        setWindowScreen("maximize")
      }
      if (resource.resource_type === "link") {
        let isMuralLink = resource.resource_data.link.startsWith("https://app.mural.co")
        let isAdobeLink = resource.resource_data.link.startsWith("https://xd.adobe.com")
        if (isMuralLink || isAdobeLink) {
          window.open(resource.resource_data.link)
        } else {
          setDisplayRnd(true)
          setSelectedResource(resource.resource_data)
        }
      } else {
        setDisplayRnd(true)
        setSelectedResource(resource.resource_data)
      }
      if (resource.resource_type === "video") {
        data.width = (1.75 * parseInt(winWidth.current)) / 2
        data.height = parseInt(winHeight.current) - 100
        data.x = 50
        data.y = 10
        setRndDragDisableFlag(true)
        setDisplayRnd(true)
        setSelectedResource(resource.resource_data)
      }
      if (resource.resource_type === "turientimage") {
        if (resource.resource_data.file_type === "0") {
          setResourceTypeToShow("image")
        } else if (resource.resource_data.file_type === "1") {
          setResourceTypeToShow("PDF Document")
        } else if (resource.resource_data.file_type === "2") {
          setResourceTypeToShow("Word Document")
        } else if (resource.resource_data.file_type === "3") {
          setResourceTypeToShow("Excel Document")
        } else if (resource.resource_data.file_type === "4") {
          setResourceTypeToShow("PPT Document")
        } else if (resource.resource_data.file_type === "5") {
          setResourceTypeToShow("Google doc Document")
        } else if (resource.resource_data.file_type === "6") {
          setResourceTypeToShow("Google ppt Document")
        } else if (resource.resource_data.file_type === "7") {
          setResourceTypeToShow("Google xls Document")
        }
        data.width = winWidth.current
        data.height = parseInt(winHeight.current) - 100
        data.x = 40
        data.y = 10
        if (resource.resource_data.file_type === "0") {
          data.width = (1.25 * winWidth.current) / 2
          data.height = parseInt(winHeight.current) - 100
          data.x = 100
          data.y = 100
        }
      } else {
        if (resource.resource_type === "quiz") {
          if (resource.resource_data.data_type === "1") {
            setDisplayRnd(false)
            setResourceTypeToShow("poll")
          } else {
            if (resource.resource_data.data_type === "2") {
              setDisplayRnd(false)
              setPopQuizModal(true)
            }
            setResourceTypeToShow(resource.resource_type)
          }
        } else {
          setResourceTypeToShow(resource.resource_type)
        }
        if (
          resource.resource_type === "casestudy" ||
          (resource.resource_type === "quiz" && resource.resource_data.data_type !== "1") ||
          resource.resource_type === "checklist"
        ) {
          data.width = winWidth.current
          data.height = parseInt(winHeight.current) - 100
          data.x = 40
          data.y = -20
        }
      }

      if (resource.resource_type === "blog" && resource.resource_data.id > 0) {
        fetchBlog(resource.resource_data.id)
        data.width = winWidth.current
        data.height = parseInt(winHeight.current) - 100
        data.x = 40
        data.y = 10
      }
      setDraggableHash(data)
      setRestoreDraggableHash(data)
    }
  }

  async function fetchBlog(id) {
    axios
      .get(`${process.env.GATSBY_REACT_APP_API_HOST}/blogs/detail/${id}/`, {
        headers: login_credentials,
      })
      .then((response) => {
        setSelectedResource(response.data[0])
        setBlogFetched(true)
      })
      .catch((error) => {
        setSelectedResource({})
        setBlogFetched(false)
      })
  }

  async function updateTriggereCount(currentItem, topic) {
    if (currentItem && currentItem.resource_type !== "casestudy" && currentItem.resource_type !== "quiz" && currentItem.resource_type !== "checklist") {
      var index = topicsResources[`topic_` + topic.id].findIndex((res) => res.id === currentItem.id)
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("resource_id", currentItem.resource_id)
      formData.append("resource_type", currentItem.resource_type)
      // formData.append('template',props.location.template_id)
      formData.append("is_triggered", false)
      // if (!currentItem.show_results || currentItem.resource_type === "casestudy" || currentItem.resource_type === "quiz") {
      formData.append("triggered_count", parseInt(currentItem.triggered_count) + 1)
      // }
      formData.append("update_version", true)
      formData.append("show_results", currentItem.show_results)
      if (currentItem.show_results) {
        formData.append("created", new Date().toISOString())
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/resources/` + currentItem.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          let dummy = { ...topicsResources }
          dummy[`topic_` + topic.id][index] = res.data
          setTopicsResources(dummy)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function showResources(datum, topic, title) {
    let { typeId, imgObj, imgType } = getImageToBeRendered(datum)
    if (typeId == 2) {
      let buttonText = datum.resource_type === "video" ? "Play" : "Open"
      buttonText = datum.resource_type === "casestudy" || datum.resource_type === "checklist" ? "Pop" : buttonText
      buttonText = datum.resource_type === "quiz" ? "Start" : buttonText
      return (
        <React.Fragment>
          <div
            className={`d-flex justify-content-start inner-element-box-shadow p-2 ${
              props.activeActivityData?.current_active_activity?.resource?.id === datum.id ? Style.bottom_green : null
            }`}
            style={{
              borderRadius: "9px",
              backgroundColor: "#4F6080",
              color: "#E0E2E5",
            }}
          >
            <div className="mb-0 mr-2 align-self-center flex-shrink-0">
              <img
                className="mb-0"
                style={{
                  borderRadius: "5px",
                  width: "38px",
                  height: "30px",
                  objectFit: "contain",
                }}
                src={imgObj}
              />
            </div>
            <div className="d-flex align-self-center flex-column">
              <div style={{ lineBreak: "auto", wordBreak: "break-word" }} className="fs-14px elipsis-2">
                {datum?.resource_data?.display_name ?? datum?.resource_data?.name}
              </div>
              {datum.resource_type === "video" && (
                <div style={{ fontSize: "12px", color: "#99A2B5" }}>{durationFormat(datum.resource_data.resource_duration)}</div>
              )}
            </div>
          </div>
          {(title === "Doing") &
          ((datum.resource_data.data_type !== "1" && datum.resource_data.data_type !== "2") |
            ((parseInt(datum?.resource_data?.data_type) === 1 || parseInt(datum?.resource_data?.data_type) === 2) & datum.end_activity) |
            (datum.triggered_time == null) && props.activeActivityData?.current_active_activity?.resource?.id != datum.id) ? (
            <Button
              variant="none"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsUpdated(false)
                setMakeCenter(true)
                setTriggeredResource(datum)
                // updateTriggereCount(datum, topic)
                triggerLessonResource("resource_poped", datum, topic)
              }}
              className={`my-2 btn text-capitalize inner-element-box-shadow py-1 ${Style.border_on_hover}`}
              style={{
                backgroundColor: "#586886",
                borderRadius: "18px",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              {datum.is_triggered ? (
                <FontAwesomeIcon
                  icon={datum.end_activity === false ? faSpinner : faCheck}
                  style={{
                    width: `18px`,
                    height: `18px`,
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                />
              ) : null}
              {parseInt(datum.triggered_count) > 0 ? `${buttonText} Again` : `${buttonText} ${imgType}`}
            </Button>
          ) : null}

          {(datum.resource_type === "casestudy" || datum.resource_type === "quiz" || datum.resource_type === "checklist") && datum.triggered_time !== null ? (
            <React.Fragment>
              {" "}
              &emsp;
              <Button
                variant="none"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setIsUpdated(false)
                  setMakeCenter(true)
                  displayResourceInPopUp(datum)
                  setTriggeredResource(datum)
                  if (datum.resource_data.data_type === "1") {
                    setShowPollModal(true)
                  }
                  setActiveTopic(topic.id)
                }}
                className={`my-2 py-1 btn text-capitalize inner-element-box-shadow ${Style.border_on_hover}`}
                style={{
                  backgroundColor: "#586886",
                  borderRadius: "18px",
                  color: "#E0E2E5",
                  fontSize: "14px",
                }}
              >
                View Detail
              </Button>
            </React.Fragment>
          ) : null}
          {props.activeActivityData?.current_active_activity?.resource?.id === datum.id && datum.triggered_time === null ? (
            <Button
              variant="none"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                // handleCloseTriggeredResourceWebsocket()
                showTriggeredResource(datum, topic)
              }}
              className={`my-2 py-1 btn text-capitalize inner-element-box-shadow ${Style.border_on_hover}`}
              style={{
                backgroundColor: "#586886",
                borderRadius: "18px",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Show Resource
            </Button>
          ) : null}
        </React.Fragment>
      )
    } else {
      return (
        <>
          <div
            className={`d-flex inner-element-box-shadow p-2 ${
              props.activeActivityData?.current_active_activity?.resource?.id === datum.id ? Style.bottom_green : null
            }`}
            style={{
              borderRadius: "9px",
              backgroundColor: "#4F6080",
              color: "#E0E2E5",
            }}
          >
            <div className="mb-0 mr-2 align-self-center" style={{ height: "38px", width: "38px" }}>
              <img
                className="mb-1 img-fluid"
                style={{
                  borderRadius: "5px",
                  height: "38px",
                  width: "30px",
                  objectFit: "contain",
                }}
                src={datum.resource_data.avatar}
              />
            </div>
            <div className="fs-14px ml-1 align-self-center">{datum.resource_data?.display_name}</div>
          </div>
          {title === "Doing" && props.activeActivityData?.current_active_activity?.resource?.id != datum.id ? (
            <Button
              variant="none"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsUpdated(false)
                // updateTriggereCount(datum, topic)
                triggerLessonResource("resource_poped", datum, topic)
              }}
              className={`my-2 btn inner-element-box-shadow ${Style.border_on_hover}`}
              style={{
                backgroundColor: "#586886",
                borderRadius: "18px",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              {datum.is_triggered ? (
                <FontAwesomeIcon
                  icon={datum.is_triggered === "loading" ? faSpinner : faCheck}
                  style={{
                    width: `18px`,
                    height: `18px`,
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                />
              ) : null}
              <span>{datum.is_triggered ? `Pop Again` : `Pop Image`}</span>
            </Button>
          ) : null}

          {title === "Doing" && props.activeActivityData?.current_active_activity?.resource?.id === datum.id && datum.triggered_time === null ? (
            <Button
              variant="none"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                // handleCloseTriggeredResourceWebsocket()
                showTriggeredResource(datum, topic)
              }}
              className={`my-2 py-1 btn text-capitalize inner-element-box-shadow ${Style.border_on_hover}`}
              style={{
                backgroundColor: "#586886",
                borderRadius: "18px",
                color: "#E0E2E5",
                fontSize: "14px",
              }}
            >
              Show Resource
            </Button>
          ) : null}
        </>
      )
    }
  }

  function modulesDataSet(key, topic, title) {
    let rotate = selectedTopicToggle[topic.id] == undefined || selectedTopicToggle[topic.id] === false ? `90` : `270`
    return (
      <div
        key={key}
        className={`mb-2 ${topicsResourcesFetched} ${title === "Done" ? Style.bottom_green : null}`}
        style={{
          backgroundColor: "#42506C",
          color: "#E0E2E5",
          borderRadius: "9px",
        }}
      >
        <div className="px-2 pt-2 d-flex justify-content-between">
          <div className="mr-1 fs-16px">{topic.name}</div>
          <div
            className="align-self-start pointer"
            onClick={(e) => {
              e.preventDefault()
              dragPosition()
              setTopicsResourcesFetched(false)
              setSelectedTopic(topic.id)
              toggleTopic(topic.id)
            }}
            style={{ flex: "none" }}
          >
            <img className="mb-1" src={ArrowWhiteCircle} style={{ transform: `rotate(${rotate}deg)` }} />
          </div>
        </div>
        {selectedTopicToggle[topic.id] == undefined || selectedTopicToggle[topic.id] === false ? (
          <div className="d-flex justify-content-between mt-2 px-2 pb-2" style={{ color: "#99A2B5", fontSize: "10px" }}>
            {topic?.questions?.count > 0 ? (
              <div>
                {topic?.questions?.count} {`Question${topic?.questions?.count > 1 ? "s" : ""}`}
              </div>
            ) : (
              ""
            )}
            {topic?.feedbacks?.count > 0 ? (
              <div>
                {topic?.feedbacks?.count} {`Feedback${topic?.feedbacks?.count > 1 ? "s" : ""}`}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <>
            <hr
              style={{
                backgroundColor: "#6b7894",
                marginTop: 0,
                marginBottom: 0,
              }}
            />
            <div className="mt-2 p-2">
              {topicsResources && topicsResources[`topic_` + topic.id] !== undefined ? (
                topicsResources[`topic_` + topic.id]?.map((datum, index) => (
                  <div className="mb-2" key={index + `resource` + datum.id}>
                    {showResources(datum, topic, title)}
                  </div>
                ))
              ) : (
                <div className={`w-100 text-center`}>
                  <Loader />
                </div>
              )}
              {topicsResources && topicsResources[`topic_` + topic.id] !== undefined ? (
                <div className={`text-center`}>
                  <p
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                    onClick={(e) => {
                      e.preventDefault()
                      fetchNextTopicResources(topicsResourcesNext[`topic_` + topic.id], topic.id)
                    }}
                    className={`text-left pointer pr-3 py-2 mb-2 mt-1 ${topicsResourcesNext[`topic_` + topic.id] === null ? `d-none` : ``}`}
                  >
                    <u>See More</u>
                  </p>
                </div>
              ) : null}
              {showBreakoutSessions(topic.breakout_sessions, title, topic.id)}
              {topic.activity_workspace?.link ? showWorkSpace(topic, title) : null}
              {topic?.questions?.count > 0 && (
                <div
                  className="d-flex inner-element-box-shadow p-2 mt-2 pointer"
                  onClick={(e) => {
                    setShowQuestionsModal({ id: topic.id, name: topic.name })
                  }}
                  style={{
                    backgroundColor: "#4F6080",
                    color: "#E0E2E5",
                    borderRadius: "9px",
                  }}
                >
                  <div className="pt-1">
                    <img alt="question" className="mb-0" src={BigQuestion} />
                  </div>
                  <div className="d-flex pl-2 flex-fill flex-column">
                    <div style={{ fontSize: "16px" }}>
                      {topic?.questions?.count} {`Question${topic?.questions?.count > 1 ? "s" : ""}`}
                    </div>
                    <div style={{ fontSize: "12px", color: "#99A2B5" }}>{topic?.questions?.un_answered_count} Unanswered</div>
                  </div>
                </div>
              )}
              <div
                className="d-flex inner-element-box-shadow text-center justify-content-between p-2 mt-2"
                style={{
                  backgroundColor: "#4F6080",
                  color: "#E0E2E5",
                  borderRadius: "9px",
                  fontSize: "16px",
                }}
              >
                <div>
                  <img className="mb-0" style={{ marginRight: "2px" }} alt="Love" src={love} />
                  <span style={{ color: "#E88E79" }}>{topic.feedbacks.like}</span>
                </div>
                <div>
                  <img className="mb-0" style={{ marginRight: "2px" }} alt="happy" src={happy} />
                  <span style={{ color: "#E8E879" }}>{topic.feedbacks.wow} </span>
                </div>
                <div>
                  <img className="mb-0" style={{ marginRight: "2px" }} alt="bored" src={bored} />
                  <span style={{ color: "#89C5F8" }}>{topic.feedbacks.love} </span>
                </div>
                <div>
                  <img className="mb-0" style={{ marginRight: "2px" }} alt="sleepy" src={sleepy} />
                  <span style={{ color: "#B3BAF0" }}>{topic.feedbacks.funny} </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (props.triggerSession && Object.keys(props.triggerSession).length > 0) {
      setBreakoutSession(props.triggerSession)
      setActiveBreakoutSession(props.triggerSession)
      setShowBreakoutSessionModal(true)
      setBreakoutTimeAlterationModal(props.triggerSession.status === "0" ? "showLaunch" : "dontShow")
      fetchBreakoutSessionProgress(props.triggerSession.id)
      if (props.triggerSession.status === "1") {
        props.setDisableScroll(true)
        props.setShowAllResources(true)
      }
    }
  }, [props.triggerSession])

  const reTriggerBreakoutSession = (session, topic_id) => {
    session.status = "0"
    triggerBreakoutSession(session, topic_id)
  }

  function triggerBreakoutSession(session, topic_id = null) {
    if (session.status === "0") {
      updateBreakoutSessionStatus(session.id, 1, topic_id)
    } else if (session.status === "1" || session.status === "2") {
      setShowBreakoutSessionModal(true)
      fetchBreakoutSessionProgress(session.id)
      if (session?.status === "1") {
        props.setDisableScroll(true)
        props.setShowAllResources(true)
        setBreakoutSessionWS(true)
      }
    }
  }

  const openNewTab = async (topic, activity_type, trigger_type) => {
    let payload = {
      training: props.location.training_id,
      activity_type: activity_type,
      resource: null,
      trigger_count: 0,
      trigger_type: trigger_type,
      triggered_data: {
        id: topic.id,
        model: "topic",
        type: "mural",
        link: topic.activity_workspace?.link,
        canvas_link: topic.activity_workspace?.canvas_link,
      },
      owner: localStorage.getItem("student_id"),
    }
    let res = await postRequest(`/activity/`, payload)
    if (res.success) {
      if (activity_type === 2) {
        props.setActiveActivityData({ current_active_activity: res.data.current_active_activity, active_breakout_session: res.data.active_breakout_session })
        window.open(topic.activity_workspace?.link, "mural_canvas")
      } else {
        props.setActiveActivityData({})
      }
    } else {
      props.setActiveActivityData({ current_active_activity: res.data.current_active_activity, active_breakout_session: res.data.active_breakout_session })
      props.setShowActiveActivity(true)
    }
  }

  const showWorkSpace = (topic, title) => {
    let isMuralLink = topic.activity_workspace?.link.startsWith("https://app.mural.co")
    let activeWorkSpace =
      props.activeActivityData?.current_active_activity?.triggered_data?.link === topic.activity_workspace?.link &&
      topic.id === props.activeActivityData?.current_active_activity?.triggered_data?.id &&
      props.activeActivityData?.current_active_activity?.triggered_data?.model === "topic"
    return (
      <div
        key={`topic-workspace-${title.id}`}
        className={`inner-element-box-shadow p-2 mt-2`}
        style={{
          color: `${activeWorkSpace ? "#46C99E" : "#E0E2E5"}`,
          borderRadius: "9px",
          border: "1px dashed",
        }}
      >
        <div className="d-flex align-items-center">
          <img
            src={isMuralLink ? muralIcon : linkIcon}
            alt={`topic_workspace_link`}
            className={`my-auto`}
            style={{
              objectFit: `contain`,
              width: "20px",
              marginRight: "10px",
            }}
          />
          <p
            style={{
              color: "#E0E2E5",
              fontSize: "14px",
              marginBottom: "0px",
            }}
          >
            Lesson WorkSpace
          </p>
        </div>
        {title === "Doing" ? (
          <div className={`d-inline-flex pt-2 w-100 align-items-center`} style={{ overflowX: "scroll" }}>
            {activeWorkSpace ? (
              <Button
                onClick={(e) => {
                  openNewTab(topic, 1, 2)
                }}
                className={`${StylesA.add_button} ${StylesA.border_on_hover} d-flex ml-0`}
                variant="none"
              >
                Close
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  openNewTab(topic, 2, 2)
                }}
                className={`${StylesA.add_button} ${StylesA.border_on_hover} d-flex ml-0`}
                variant="none"
              >
                Open
              </Button>
            )}
          </div>
        ) : null}
      </div>
    )
  }

  function showBreakoutSessions(sessions, title, topic_id) {
    if (sessions?.length > 0) {
      return sessions.map((session, index) => {
        return (
          <div
            key={`breakoutSessions${index}`}
            className={`inner-element-box-shadow p-2 mt-2`}
            style={{
              color: "#E0E2E5",
              borderRadius: "9px",
              border: "1px dashed",
            }}
          >
            <div className="d-flex align-items-center">
              <img
                src={BreakoutSessionIcon}
                alt={`breakout_session_icon`}
                className={`my-auto`}
                style={{
                  objectFit: `contain`,
                  width: "20px",
                  marginRight: "10px",
                }}
              />
              <p
                style={{
                  color: "#E0E2E5",
                  fontSize: "14px",
                  marginBottom: "0px",
                }}
              >
                {session.name}
              </p>
            </div>
            {title === "Doing" ? (
              <div className={`d-inline-flex pt-2 w-100 align-items-center`} style={{ overflowX: "scroll" }}>
                <HorizontalScroll className={`d-inline-flex w-100 align-items-center`} scrollId={session.id}>
                  <Button
                    onClick={(e) => {
                      let status = session.status === "0" ? "0" : session.status
                      setBreakoutSession({ ...session, topic: topic_id, status: status })
                      setActiveBreakoutSession({ ...session, topic: topic_id, status: status })
                      setShowBreakoutSessionModal(true)
                      setBreakoutTimeAlterationModal(session.status === "0" ? "showLaunch" : "dontShow")
                      fetchBreakoutSessionProgress(session.id)
                      if (session.status === "1") {
                        props.setDisableScroll(true)
                        props.setShowAllResources(true)
                      }
                    }}
                    className={`${StylesA.add_button} ${StylesA.border_on_hover} d-flex ml-0`}
                    variant="none"
                  >
                    {session?.status === "1" ? "View Session" : session?.status === "0" ? "Start Session" : "View Session Results"}
                  </Button>
                  {session?.status === "1" ? (
                    <Button
                      onClick={(e) => {
                        setShowExtendTimeModal(true)
                      }}
                      className={`${StylesA.add_button} ${StylesA.border_on_hover} d-flex ml-2`}
                      variant="none"
                    >
                      Extend time
                    </Button>
                  ) : null}
                  {session?.status === "2" ? (
                    <Button
                      onClick={(e) => {
                        setBreakoutSession({ ...session, topic: topic_id })
                        setShowBreakoutSessionModal(true)
                        fetchBreakoutSessionProgress(session.id)
                        setBreakoutTimeAlterationModal("showReLaunch")
                        props.setDisableScroll(true)
                        props.setShowAllResources(true)
                      }}
                      className={`${StylesA.add_button} ${StylesA.border_on_hover} d-flex ml-2`}
                      variant="none"
                    >
                      Re-launch session
                    </Button>
                  ) : null}
                </HorizontalScroll>
              </div>
            ) : null}
          </div>
        )
      })
    } else {
      return null
    }
  }

  function lessonBoards(data, key) {
    return (
      <div
        key={key}
        className={`mx-2 ${data.title === "Progress" && props.minScroll ? "mt-5" : ""}`}
        style={{
          height: "inherit",
          overflow: "hidden",
          flex: "1",
          backgroundColor: "#212C42",
          borderRadius: "9px",
          boxShadow: "0px 3px 12px #00000099",
          minWidth: data.title === "Progress" ? "400px" : "auto",
        }}
      >
        <div className="p-2" style={{ borderBottom: "1px solid #42506C", color: "#E0E2E5" }}>
          {data.title}
        </div>
        <div className={`${Style.card_scrollable} h-100`}>
          {data.title === "To Do" || data.title === "Doing" || data.title === "Done" ? (
            <Droppable
              isDropDisabled={props.disableLessonBoardDnd || showBreakoutSessionModal}
              droppableId={`module_` + data.title.replaceAll(" ", "_")}
              type={`topicDraggable`}
            >
              {(provided, snapshot) => (
                <div className={`bg-transparent h-100`} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {modulesData && modulesData[`module_` + data.title.replaceAll(" ", "_")]
                    ? modulesData[`module_` + data.title.replaceAll(" ", "_")].topics.map((item, index) => {
                        return (
                          <Draggable
                            key={item.id + `_topic_name_` + index}
                            draggableId={item.id.toString() + "-topic" + "_" + index.toString()}
                            isDragDisabled={data.title === "Done"}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`bg-transparent float-none`}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                {modulesDataSet(index + `modules`, item, data.title)}
                              </div>
                            )}
                          </Draggable>
                        )
                      })
                    : null}
                  {provided.placeholder}
                  {modulesData && modulesData[`module_` + data.title.replaceAll(" ", "_")] ? (
                    <div className={`text-center`}>
                      <Button
                        variant="none"
                        onClick={(e) => {
                          e.preventDefault()
                          fetchNextCurrentTopics(modulesData[`module_` + data.title.replaceAll(" ", "_")].topics_next, data.title)
                        }}
                        className={`text-center px-3 py-2 mt-2 ${
                          modulesData[`module_` + data.title.replaceAll(" ", "_")].topics_next === null ? `d-none` : ``
                        }`}
                        style={{
                          backgroundColor: `#4b5a9b`,
                          color: `#c6cff4`,
                          borderRadius: `15px`,
                          fontSize: `12px`,
                        }}
                      >
                        See More
                      </Button>
                    </div>
                  ) : null}
                </div>
              )}
            </Droppable>
          ) : (
            <div className="p-2">
              <div
                className={`d-inline-block align-items-center p-2 w-100`}
                style={{
                  backgroundColor: "#4F6080",
                  color: "#E0E2E5",
                  borderRadius: "9px",
                }}
              >
                <div className="d-inline-flex align-items-center w-100 pointer pb-3">
                  <div className="pt-1">
                    <img alt="question" className="mb-0" src={BigQuestion} />
                  </div>
                  <div className={`pl-2`} style={{ fontSize: "16px" }}>
                    All Questions
                  </div>
                  <Btn
                    type="small"
                    style={{ backgroundColor: "#303C54" }}
                    className={`px-3 ml-auto`}
                    onClick={(e) => {
                      setShowQuestionsModal({
                        id: "all_questions",
                        name: "All Questions",
                      })
                      let quesData = { ...questionsData }
                      quesData["topic_all_questions_sidebar"] = [...quesData["topic_all_questions"]]
                      setQuestionsData(quesData)
                    }}
                  >
                    View All
                  </Btn>
                </div>
                <div className={`w-100 align-items-center`}>
                  {questionLoader === "all_questions" && (
                    <div className={`w-100 text-center`}>
                      <Loader />
                    </div>
                  )}
                  {questionsDataFetched ? (
                    questionsData[showQuestionsModal?.id == "all_questions" ? "topic_all_questions_sidebar" : "topic_all_questions"]?.length > 0 ? (
                      <React.Fragment>
                        {questionsData[showQuestionsModal?.id == "all_questions" ? "topic_all_questions_sidebar" : "topic_all_questions"]?.map(
                          (data, index) => {
                            let questionExpand2 = questionExpand && questionExpand[`ques_${data.id}`] !== undefined && questionExpand[`ques_${data.id}`]
                            return <div>Question Box component</div>
                          }
                        )}
                        {questionsNextUrl["topic_all_questions"] && (
                          <div
                            className="pointer"
                            style={{
                              fontSize: "14px",
                              textDecoration: "underline",
                            }}
                            onClick={(e) => {
                              fetchNextQuestions(questionsNextUrl["topic_all_questions"])
                            }}
                          >
                            See more
                          </div>
                        )}
                        {questionLoader === "ques_next" && (
                          <div className={`w-100 text-center`}>
                            <Loader />
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                        <p className={`mb-2`} style={{ fontSize: "14px" }}>
                          No questions from students
                        </p>
                      </Col>
                    )
                  ) : (
                    <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                      <Spinner animation="border" variant="secondary" size="sm" />
                    </Col>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  function handleDraggedItemSequence(currentItem, type, to = `internal`) {
    if (currentItem) {
      var formData = new FormData()
      // formData.append('sequence',currentItem.sequence)
      formData.append("published", true)
      if (type !== `resources`) {
        formData.append("name", currentItem.name)
      } else {
        formData.append("resource_id", currentItem.resource_id)
        formData.append("resource_type", currentItem.resource_type)
      }
      if (to === `external` && type === `topics`) {
        formData.append("module", currentItem.module)
        formData.append("moving", true)
      } else if (to === `topic_external` && type === `resources`) {
        formData.append("topic", currentItem.topic)
      } else if (to === `module_external` && type === `resources`) {
        formData.append("module", currentItem.module)
      }
      if (type === "topics") {
        // formData.append('visible_as_resource',currentItem.visible_as_resource)
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}/` + currentItem.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          if (to === `external` && type === `topics` && activeSession !== null) {
            triggerChanges("topic_" + currentItem.id, activeSession.id, res.data.topic_stage + `_$_` + currentItem.module)
          } else if (type === `topics` && to === "internal_reorder") {
            triggerChanges("lbtopicreordred", currentItem.session_id)
          }
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "topicDraggable") {
      if (props?.activeActivityData?.current_active_activity?.id && result?.destination?.droppableId === "module_Done") {
        if (props?.activeActivityData?.current_active_activity?.resource?.resource_type === "link") {
          closeRnd()
        } else {
          props.setShowActiveActivity(true)
          return
        }
      }
      const itemSubItemMap = modulesData

      const sourceParentId = result.source.droppableId
      const destParentId = result.destination.droppableId

      const sourceSubItems = itemSubItemMap[sourceParentId].topics
      const destSubItems = itemSubItemMap[destParentId].topics

      let newItems = modulesData[result.destination.droppableId]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(modulesData[result.destination.droppableId].topics, sourceIndex, destIndex)
        let currentTopic = items[result.destination.index]
        let currentData = modulesData[result.destination.droppableId].topics
        handleDraggedItemSequence(currentTopic, "topics")
        modulesData[result.destination.droppableId].topics = items
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)

        draggedItem.module = modulesIds[result.destination.droppableId]
        handleDraggedItemSequence(draggedItem, "topics", "external")
        modulesData[result.destination.droppableId] = newItems
        if (result.destination.droppableId === "module_Doing" && selectedTopicToggle[draggedItem.id] !== true) {
          setTopicsResourcesFetched(false)
          setSelectedTopic(draggedItem.id)
          toggleTopic(draggedItem.id)
        } else if (result.destination.droppableId !== "module_Doing" && selectedTopicToggle[draggedItem.id] === true) {
          setTopicsResourcesFetched(false)
          setSelectedTopic(draggedItem.id)
          toggleTopic(draggedItem.id)
        }
      }
    }
  }

  function handleSlideChange(type) {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/training-websocket-trigger/${props.location.training_id}/action/?action=slide&page=${type === "next" ? slidePage + 1 : slidePage - 1}`,
        { headers: login_credentials }
      )
      .then((res) => {})
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
    if (type === "next") {
      setSlidePage((prev) => prev + 1)
    } else {
      setSlidePage((prev) => prev - 1)
    }
  }

  function toggleGoogleDocsMode() {
    let data = googleDocsEditable ? "disable edit" : "enable edit"
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/training-websocket-trigger/${props.location.training_id}/action/?action=${data}`, {
        headers: login_credentials,
      })
      .then((res) => {
        setGoogleDocsEditable(!googleDocsEditable)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function showGoogleDocs(doc_id, doc_type) {
    //setSlidePage(0)
    let type = "document"
    if (doc_type == "5") {
      type = "document"
    } else if (doc_type == "6") {
      type = "presentation"
    } else if (doc_type == "7") {
      type = "spreadsheets"
    }
    let link = `https://docs.google.com/${type}/d/${doc_id}/edit`
    if (isBrowser()) {
      return (
        <div style={{ height: "90%" }}>
          {type === "presentation" ? (
            <React.Fragment>
              <iframe
                style={{ pointerEvents: "none", marginBottom: "0px" }}
                width={`100%`}
                height={`100%`}
                src={`https://docs.google.com/presentation/d/${doc_id}/embed?loop=true&rm=minimal&slide=${slidePage}`}
              />
              <div className={`d-flex my-1`}>
                <Button
                  className={`${StylesA.add_button} ${StylesA.border_on_hover} mx-1 fs-16px`}
                  style={{ borderRadius: "9px" }}
                  disabled={googleDocsEditable}
                  variant="none"
                  onClick={(e) => {
                    e.preventDefault()
                    handleSlideChange("prev")
                  }}
                >
                  Prev
                </Button>
                <Button
                  className={`${StylesA.add_button} ${StylesA.border_on_hover} mx-1 fs-16px`}
                  style={{ borderRadius: "9px" }}
                  disabled={googleDocsEditable}
                  variant="none"
                  onClick={(e) => {
                    e.preventDefault()
                    handleSlideChange("next")
                  }}
                >
                  Next
                </Button>
                {/*<Button className={`${StylesA.add_button} ${StylesA.border_on_hover} ml-auto fs-16px`} onClick={toggleGoogleDocsMode} variant="none">Change to {googleDocsEditable ? 'Read-Only Mode' : 'Editable Mode'} </Button>*/}
                <div
                  className={`ml-auto d-flex`}
                  style={{
                    background: "#42506C",
                    border: "1px solid #E0E2E5",
                    borderRadius: "9px",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  <Button
                    className={`${StylesA.border_on_hover} ${StylesA.switch_btn} ml-auto fs-16px`}
                    style={{ borderRadius: "9px 0 0 9px" }}
                    disabled={!googleDocsEditable}
                    onClick={toggleGoogleDocsMode}
                    variant="none"
                  >
                    Read-Only
                  </Button>
                  <Button
                    className={`${StylesA.border_on_hover} ${StylesA.switch_btn} ml-auto fs-16px`}
                    style={{ borderRadius: "0 9px 9px 0" }}
                    disabled={googleDocsEditable}
                    onClick={toggleGoogleDocsMode}
                    variant="none"
                  >
                    Editable{" "}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <iframe className={`mb-0`} src={link} style={{ width: "100%", height: draggableHash.height - 50 }} />
              <div
                className={`ml-auto d-flex`}
                style={{
                  background: "#42506C",
                  border: "1px solid #E0E2E5",
                  borderRadius: "9px",
                  boxShadow: "0px 3px 6px #00000029",
                  width: "fit-content",
                }}
              >
                <Button
                  className={`${StylesA.border_on_hover} ${StylesA.switch_btn} ml-auto fs-16px`}
                  style={{ borderRadius: "9px 0 0 9px" }}
                  disabled={!googleDocsEditable}
                  onClick={toggleGoogleDocsMode}
                  variant="none"
                >
                  Read-Only
                </Button>
                <Button
                  className={`${StylesA.border_on_hover} ${StylesA.switch_btn} ml-auto fs-16px`}
                  style={{ borderRadius: "0 9px 9px 0" }}
                  disabled={googleDocsEditable}
                  onClick={toggleGoogleDocsMode}
                  variant="none"
                >
                  Editable{" "}
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
      )
    } else {
      return null
    }
  }

  const getPopQuizDetails = async () => {
    if (gResource.version_id <= 0) return
    const response = await getRequest(`/quiz/${gResource?.version_id}/quiz_version_data/?resource=${gResource?.id}`)
    if (response.success) {
      setPopQuizDetail(response.data)
    }
  }

  useEffect(() => {
    if (gResource && gResource.resource_type === "quiz" && gResource.resource_data.data_type === "2") {
      setPopQuizDetail(null)
      getPopQuizDetails()
    }
  }, [gResource])

  function displayVideoControls() {
    if (props.videoCall === "jitsi") {
      props.videoCallActions.setMuteAllTrigger(true)
    }
    return (
      <div>
        <VideoCard2
          url={selected_resource.url === "" ? selected_resource.video_file : selected_resource.url}
          channel={selected_resource.channel}
          playing={false}
          controls={false}
          volume={0}
          muted={true}
          trainingWsConnection={props.trainingWsConnection}
          width={parseInt(draggableHash.width) - 10}
          height={draggableHash.height}
          resourceID={selected_resource.id}
        />
      </div>
    )
  }

  const displayResource = () => {
    if (resource_type_to_show === "video") {
      return displayVideoControls()
    } else if (resource_type_to_show === "image") {
      if (selected_resource.avatar.toLowerCase().indexOf(".svg") > -1) {
        return <img src={selected_resource.avatar} alt={selected_resource.name} width={"100%"} height={"100%"} style={{ objectFit: "contain" }} />
      } else {
        return <LazyLoadImage src={selected_resource.avatar} alt={selected_resource.name} width={"100%"} height={"100%"} style={{ objectFit: "contain" }} />
      }
    } else if (resource_type_to_show === "link") {
      return (
        <iframe
          src={selected_resource.link}
          title={selected_resource.name}
          loading="lazy"
          referrerPolicy="no-referrer"
          width="100%"
          height="100%"
          className="mb-0"
          style={{ borderRadius: "5px", border: "none" }}
        />
      )
    } else if (resource_type_to_show.indexOf(" Document") > -1) {
      return (
        <>
          {resource_type_to_show == "PDF Document" ? (
            <object
              data={selected_resource.avatar}
              width="100%"
              height="100%"
              className="mb-0 bg-transparent"
              style={{ borderRadius: "5px", border: "none" }}
            />
          ) : resource_type_to_show.indexOf("Google") > -1 ? (
            showGoogleDocs(selected_resource.google_doc_id, selected_resource.file_type)
          ) : (
            <div className={`w-100 h-100`}>
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${selected_resource.avatar}`}
                title={selected_resource.name}
                loading="lazy"
                referrerPolicy="no-referrer"
                width="100%"
                height={draggableHash.height}
                className="mb-0"
                style={{ borderRadius: "5px", border: "none" }}
              />
              {/*<div class="fixed-bottom bg-primary" style={{background: 'rgba(0, 0, 0, 0.43)'}}>Fixed bottom</div>*/}
            </div>
          )}
        </>
      )
    } else if (resource_type_to_show === "blog") {
      if (blog_fetched) {
        return (
          <React.Fragment>
            <div
              className={`text-white w-100 h-100 ${Style.backend_images} ${Style.backend_data}`}
              style={{ fontSize: "14px", margin: "50px 5px 5px 10px" }}
              dangerouslySetInnerHTML={{
                __html: selected_resource.description,
              }}
            />
          </React.Fragment>
        )
      } else {
        return (
          <div className="text-white w-100 h-100" style={{ fontSize: "14px" }}>
            Loading...
          </div>
        )
      }
    } else if (resource_type_to_show === "quiz") {
      if (gResource?.resource_data?.data_type != "2") {
        return (
          <div className={`w-100 h-100 ${!popQuizModal && "mb-3"}`}>
            <QuizModeration
              trainingID={props.location.training_id}
              resourceID={gResource.id}
              quizID={gResource.resource_data.id}
              triggeredCount={gResource.triggered_count}
              resource={gResource}
              trainingWsConnection={props.trainingWsConnection}
              setWsMessage={setWsMessage}
              activeActivityData={props.activeActivityData}
              setActiveActivityData={props.setActiveActivityData}
            />
          </div>
        )
      } else {
        return (
          <>
            {popQuizDetail !== null ? (
              <PopQuizResults
                trainingID={props.location.training_id}
                resourceID={gResource.id}
                quizID={gResource.resource_data.id}
                triggeredCount={gResource.triggered_count}
                resource={gResource}
                trainingWsConnection={props.trainingWsConnection}
                template_id={props.location.template_id}
                topicId={activeTopic}
                setWsMessage={setWsMessage}
                handleHide={handleHidePopQuizModal}
                duration={popQuizDetail?.quiz_time}
                setUpdatedResource={setUpdatedResource}
                setActiveActivityData={props.setActiveActivityData}
              />
            ) : (
              <div className={`w-100 text-center`}>
                <Loader />
              </div>
            )}
          </>
        )
      }
    } else if (resource_type_to_show === "casestudy") {
      return (
        <div className={`w-100 h-100 mb-3`}>
          <CaseStudyModeration
            trainingID={props.location.training_id}
            resourceID={gResource.id}
            caseStudyID={gResource.resource_data.id}
            triggeredCount={gResource.triggered_count}
            resource={gResource}
            trainingWsConnection={props.trainingWsConnection}
            setWsMessage={setWsMessage}
            activeActivityData={props.activeActivityData}
            setActiveActivityData={props.setActiveActivityData}
          />
        </div>
      )
    } else if (resource_type_to_show === "checklist") {
      return (
        <div className={`w-100 h-100 mb-3`}>
          <CheckListModeration
            trainingID={props.location.training_id}
            resourceID={gResource.id}
            checklistID={gResource.resource_data.id}
            triggeredCount={gResource.triggered_count}
            resource={gResource}
            trainingWsConnection={props.trainingWsConnection}
            setWsMessage={setWsMessage}
            activeActivityData={props.activeActivityData}
            setActiveActivityData={props.setActiveActivityData}
          />
        </div>
      )
    }
  }

  // axios.post(`${process.env.GATSBY_REACT_APP_API_HOST}/replies/create/`, formData,  { headers: login_credentials })

  const handleCloseTriggeredResourceWebsocket = async () => {
    var formData = new FormData()
    formData.append("action", "close triggered resource")
    let res = await getRequest(`/training-websocket-trigger/${props.location.training_id}/action/?action='close triggered resource'`)
    if (res.success) {
      props.setActiveActivityData({})
    }
  }

  const closeRnd = () => {
    if (!["checklist", "quiz", "casestudy"].includes(resource_type_to_show)) {
      handleCloseTriggeredResourceWebsocket()
    }

    setDisplayRnd(false)
    setSelectedResource({})
    setResourceTypeToShow("")
    setMakeCenter(false)
  }
  const displayData = () => {
    let minWidth = resource_type_to_show === "video" || resource_type_to_show === "image" ? draggableHash.width : "1100px"
    if (selected_resource && Object.keys(selected_resource).length > 0) {
      return (
        <div
          className={`box text-dark p-2 w-100 window_screen`}
          onScroll={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{
            margin: 0,
            height: "100%",
            backgroundColor: resource_type_to_show === "blog" ? "#121B2B" : `#212C41`,
            border: "1px solid #E0E2E5",
            borderRadius: "5px",
            boxShadow: "0px 3px 12px #00000099",
            overflowY: "auto",
          }}
        >
          {/**resource_type_to_show === "blog" ? null : ( */}
          {
            <>
              <div className={`d-none window_screen_icon position-absolute`} style={{ top: "16px", width: "calc(100% - 20px)", zIndex: 3 }}>
                <div className="d-flex align-items-baseline justify-content-between mx-auto" style={{ width: "98%" }}>
                  <div
                    className={`w-75`}
                    style={
                      resource_type_to_show === "video"
                        ? {}
                        : {
                            background: "rgb(65,80,107)",
                            zIndex: 2,
                            borderRadius: "20px",
                            padding: "6px 16px",
                          }
                    }
                  >
                    {resource_type_to_show === "video" ? null : (
                      <h6
                        className="mb-0 text-white"
                        style={{
                          fontSize: !window_screen ? "14px" : "18px",
                          textShadow: "0px 3px 12px #00000099",
                        }}
                      >
                        {selected_resource.name}
                      </h6>
                    )}
                  </div>
                  <div
                    className={`d-flex align-items-center justify-content-center`}
                    style={
                      resource_type_to_show === "video"
                        ? {}
                        : {
                            background: "rgba(65, 80, 107)",
                            borderRadius: "25px",
                            padding: "6px 14px",
                          }
                    }
                  >
                    <img
                      src={!window_screen || window_screen === "maximize" ? fullscreenSvg : minimizeSvg}
                      className="mb-0 pointer mr-2"
                      style={{ fontSize: "20px", width: "20px" }}
                      onClick={() => {
                        setRndDragDisableFlag(true)
                        changeWindowScreen(window_screen)
                      }}
                    />
                    <img src={CancelIcon} className="mb-0 pointer" style={{ fontSize: "20px", width: "20px" }} onClick={() => closeRnd()} />
                  </div>
                </div>
              </div>
            </>
          }
          <div style={{ minWidth: minWidth }}>{displayResource(minWidth)}</div>
        </div>
      )
    } else {
      return null
    }
  }

  const changeWindowScreen = (value) => {
    if (value === true) {
      if (props.leftNavOpen) {
        props.setLeftNavOpen(false)
      }

      let data
      if (
        (draggableHash.width === winWidth.current && draggableHash.height === winHeight.current) ||
        resource_type_to_show === "quiz" ||
        resource_type_to_show === "casestudy" ||
        resource_type_to_show === "checklist"
      ) {
        data = { x: 40, y: 0, width: winWidth.current, height: parseInt(winHeight.current) - 100 }
      } else {
        data = { ...restoreDraggableHash }
      }
      setDraggableHash(data)
      setRndDragDisableFlag(false)
      setWindowScreen(false)
      const evt = new CustomEvent("my-rnd-window-changed", {
        bubbles: true,
        detail: 1,
      })
      document.dispatchEvent(evt)
    } else {
      dragPosition()
      setWindowScreen(true)
      setRndDragDisableFlag(false)

      const evt = new CustomEvent("my-rnd-window-changed", {
        bubbles: true,
        detail: 0,
      })
      document.dispatchEvent(evt)
    }
  }

  function dragPosition() {
    if (isBrowser() && document.getElementsByClassName("parent") && document.getElementsByClassName("parent").length > 0) {
      let x = parseInt(document.getElementsByClassName("parent")[0].offsetWidth - 324)
      let y = parseInt(document.getElementsByClassName("parent")[0].offsetHeight - 321)
      setDraggableHash({ x: x, y: y, width: 324, height: 191 })
      // setDraggableHash({x: 1031, y: 8, width: 324, height:191})
    }
  }

  function onDragStart(result) {
    dragPosition()
  }

  useEffect(() => {
    if (props.location.training_id) {
      fetchQuestions("all_questions")
    }
  }, [props.location.training_id])

  useEffect(() => {}, [props.leftNavOpen])

  const setMissingMessages = (fetch_messages) => {
    try {
      if (fetch_messages == "" || fetch_messages == false) {
        let messages_ids = JSON.parse(localStorage.getItem("message-ids"))
        try {
          for (let index = 0; index < messages_ids.length; index++) {
            const element = messages_ids[index]
            let message = JSON.parse(localStorage.getItem(`message-${element}`))
            if (message?.event_type) {
              setTimeout(() => {
                const event_fn = eval(message.event_type)
                event_fn(message)
              })
            }
            localStorage.removeItem(`message-${element}`)
          }
          localStorage.removeItem(`message-ids`)
        } catch (error) {}
        connectSocket()
        SocketConnection()
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (ready) {
      setMissingMessages(resource_type_to_show)
    }
  }, [resource_type_to_show])

  useEffect(() => {
    if (ready) {
      setMissingMessages(showPollModal)
    }
  }, [showPollModal])

  useEffect(() => {
    if (wsMessage?.event_type && wsMessage?.id) {
      localStorage.setItem(`message-${wsMessage?.id}`, JSON.stringify(wsMessage))
      let messages_ids = localStorage.getItem("message-ids")
      if (messages_ids) {
        messages_ids = JSON.parse(messages_ids)
        messages_ids.push(wsMessage?.id)
      } else {
        messages_ids = []
        messages_ids.push(wsMessage?.id)
      }
      localStorage.setItem("message-ids", JSON.stringify(messages_ids))
    }
  }, [wsMessage])

  useEffect(() => {
    if (createdReply > 0 && isDrafting) {
      fetchReplyResources(createdReply)
    }
  }, [createdReply])
  useEffect(() => {
    if (questionReply !== null && questionReply?.id > 0 && questionReplyData !== null && questionReplyData["ques_" + questionReply.id]) {
      let replyID = isDrafting ? createdReply : editReplyData?.id

      if (replyID && replyID > 0) {
        fetchReplyResources(replyID)
      }
    }
  }, [isDrafting, createdReply, editReplyData])

  function fetchQuestions(object_id, refetch = false) {
    let key = "topic_" + object_id
    if (refetch || (questionsData && questionsData[key] === undefined)) {
      let url = `&object_id=${object_id}`
      setQuestionLoader("topic_" + object_id)
      if (object_id === "all_questions") {
        setQuestionLoader("all_questions")
        url = `&training=${props.location.training_id}`
      }
      if (questionAnswerSearch !== "") {
        setQuestionLoader("search_field")
        url = url + "&search=" + questionAnswerSearch
      }
      if (sessionQAIdSelected > 0) {
        url = url + "&topic__module__id=" + sessionQAIdSelected
      }
      axios
        .get(
          `${process.env.GATSBY_REACT_APP_API_HOST}/queries/?basic-data=true&content_type=topic&paginate_by=8&ordering=-id&topic_stage_name=Workshop${url}`,
          {
            headers: login_credentials,
          }
        )
        .then((res) => {
          let data = { ...questionsData }
          let urlData = { ...questionsNextUrl }
          data[key] = res.data.results
          urlData[key] = res.data.next
          setQuestionsData(data)
          setQuestionsNextUrl(urlData)
          setQuestionsDataFetched(true)
          setQuestionLoader(null)
          // setQuestionsData(res.data.results)
        })
    }
  }

  async function fetchNextQuestions(next_url) {
    setQuestionLoader("ques_next")
    let topicId = "all_questions"
    if (showQuestionsModal?.id) {
      topicId = showQuestionsModal?.id
    }

    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let urlData = { ...questionsNextUrl }
      urlData["topic_" + topicId] = res.data.next
      setQuestionsNextUrl(urlData)
      let data = { ...questionsData }
      let quesData = data["topic_" + topicId]
      quesData = [...quesData, ...res.data.results]
      data["topic_" + topicId] = quesData
      setQuestionsData(data)
      setQuestionLoader(null)
    })
  }

  function fetchDraftReply(object_id) {
    let key = "ques_" + object_id
    if (draftReply && draftReply[key] === undefined) {
      let url = `&query=${object_id}`
      axios
        .get(`${process.env.GATSBY_REACT_APP_API_HOST}/replies/?basic-data=true&published=false&ordering=-id${url}`, { headers: login_credentials })
        .then((res) => {
          let data = { ...draftReply }
          if (res.data.results.length > 0) {
            data[key] = res.data.results[0]
            setDraftReply(data)
            setCreatedReply(res.data.results[0]?.id)
            setQuestionReplyInput(res.data.results[0]?.title)
          } else {
            createBlankReply(object_id)
          }
          // setQuestionsData(res.data.results)
        })
    } else {
      setQuestionReplyInput(draftReply[key]?.title)
      setCreatedReply(draftReply[key]?.id ?? 0)
    }
  }

  function fetchReplies(object_id) {
    let key = "ques_" + object_id
    if (questionReplyData && questionReplyData[key] === undefined) {
      setQuestionLoader(key)
      let url = `&query=${object_id}`
      axios
        .get(`${process.env.GATSBY_REACT_APP_API_HOST}/replies/?basic-data=true&published=true&ordering=-id${url}`, { headers: login_credentials })
        .then((res) => {
          let data = { ...questionReplyData }
          let urlData = { ...questionReplyNextUrl }
          data[key] = res.data.results
          urlData[key] = res.data.next
          setQuestionReplyData(data)
          setQuestionReplyNextUrl(urlData)
          // setQuestionsData(res.data.results)
        })
        .finally(() => {
          setQuestionLoader(null)
        })
    }
  }

  async function fetchNextReplies(next_url, quesId) {
    //setTopicsFetching(false)
    setQuestionLoader("reply_next_" + quesId)
    axios
      .get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials })
      .then((res) => {
        let urlData = { ...questionReplyNextUrl }
        urlData["ques_" + quesId] = res.data.next
        setQuestionReplyNextUrl(urlData)
        let data = { ...questionReplyData }
        let quesData = data["ques_" + quesId]
        quesData = [...res.data.results, ...quesData]
        data["ques_" + quesId] = quesData
        setQuestionReplyData(data)
        //setTopicsFetching(true)
      })
      .finally(() => {
        setQuestionLoader(null)
      })
  }

  function handleQuestionScroll(e) {
    e.stopPropagation()
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5 &&
      questionsNextUrl["topic_" + showQuestionsModal?.id] != null &&
      questionLoader === null
    ) {
      fetchNextQuestions(questionsNextUrl["topic_" + showQuestionsModal?.id])
    }
  }

  const draftingReply = useCallback(
    _.debounce((q, rid) => updateReply(q, rid), 1000),
    []
  )

  const handleReplyInputChange = (e) => {
    if (isDrafting) {
      setReplyInputLoader(true)
      setQuestionReplyInput(e.target.value)
      let data = { ...draftReply }
      data["ques_" + questionReply.id].title = e.target.value
      setDraftReply(data)
      draftingReply(e.target.value, createdReply)
    } else {
      setQuestionReplyInput(e.target.value)
    }
  }

  function createBlankReply(quesId) {
    let formData = new FormData()
    formData.append("query_id", quesId)
    formData.append("training", props.location.training_id)
    formData.append("title", "")
    formData.append("published", false)

    axios
      .post(`${process.env.GATSBY_REACT_APP_API_HOST}/replies/create/`, formData, { headers: login_credentials })
      .then((res) => {
        let data = { ...draftReply }
        data["ques_" + quesId] = {
          ...res.data,
          owner: {
            first_name: window.localStorage.getItem("student_first_name"),
            id: window.localStorage.getItem("student_id"),
          },
        }
        setDraftReply(data)
        setCreatedReply(res.data.id)
        setUploadInTopic(res.data.id)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
      })
  }

  function updateReply(inputData, rid) {
    let formData = new FormData()
    formData.append("title", inputData)
    if (rid !== undefined) {
      axios
        .put(`${process.env.GATSBY_REACT_APP_API_HOST}/replies/` + rid + `/`, formData, { headers: login_credentials })
        .then((res) => {
          setReplyInputLoader(false)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
        })
    }
  }

  function handlePublishReply(e, editing) {
    e.preventDefault()
    let formData = new FormData()
    let replyId = isDrafting ? createdReply : editReplyData?.id
    if (isDrafting) {
      formData.append("query_id", createdReply)
    } else {
      formData.append("query_id", editing)
    }
    formData.append("training", props.location.training_id)
    formData.append("title", questionReplyInput)
    formData.append("published", true)

    axios
      .put(`${process.env.GATSBY_REACT_APP_API_HOST}/replies/` + replyId + `/`, formData, { headers: login_credentials })
      .then((res) => {
        if (isDrafting) {
          createBlankReply(questionReply.id)
          setQuestionReplyInput("")
        } else {
          setIsDrafting(true)
          setQuestionReplyInput(draftReply[`ques_${questionReply?.id}`].title ? draftReply[`ques_${questionReply?.id}`].title : "")
        }
        setShowSection("")
        setEditReplyData(null)
      })
      .catch((error) => {
        setShowSection("")
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
      })
  }

  function handleReplyClick(e, data, index) {
    e.preventDefault()
    props.setDisableScroll(true)
    props.openNavWithResourceSection("Resource", "video")
    props.setShowAllResources(true)

    setUploadInModule(data.id)
    setQuestionReply({ id: data.id, index: index })
    fetchReplies(data.id)
    //createBlankReply(data.id)
    fetchDraftReply(data.id)
  }

  function handleQuestionExpand(e, dataid) {
    e.preventDefault()
    fetchReplies(dataid)
    let key = "ques_" + dataid
    if (questionExpand) {
      let data = { ...questionExpand }
      if (questionExpand[key] === undefined) {
        data[key] = true
      } else {
        data[key] = data[key] !== true
      }
      setQuestionExpand(data)
    }
  }

  useEffect(() => {
    if (props.location.template_id) {
      getAllTrainingSessions()
      getAllTrainingSessionsTopics()
    }
  }, [props.location.template_id])

  async function getAllTrainingSessions() {
    const response = await getRequest(`/default-course-template/apis/${props.location.template_id}/all_training_sessions/`)
    if (response.success) {
      let dummy = [
        { id: 0, name: "All Sessions" },
        { id: 0, name: "Ongoing Session" },
      ]
      const ongoingSession = response.data.filter((item) => item.session_type === "1")
      dummy[1].id = ongoingSession[0]?.id ?? 0
      const allSessions = [...dummy, ...response?.data]
      setSessionsQA(allSessions)
    }
  }
  async function getAllTrainingSessionsTopics() {
    let search = ""
    if (qaSessionTopicSearch !== "") {
      search = "?search=" + qaSessionTopicSearch
    }
    const response = await getRequest(`/default-course-template/apis/${props.location.template_id}/all_training_sessions_for_questions/${search}`)
    if (response.success) {
      let workshopData = response.data.filter((session) => session?.stage.toLowerCase() === "workshop")
      setSessionsTopicsQA(workshopData)
    }
  }

  function scrollRight(id) {
    let ele = document.getElementById(id)
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      ele.scrollTo({
        top: 0,
        left: left_pos + 3 * flex_scroll_amount,
        behavior: "smooth",
      })
    }
  }

  function scrollLeft(id) {
    let ele = document.getElementById(id)
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      ele.scrollTo({
        top: 0,
        left: left_pos - 3 * flex_scroll_amount,
        behavior: "smooth",
      })
    }
  }

  function displayScrollButtons(id) {
    let ele = document.getElementById(id)
    if (ele) {
      if (ele.scrollLeft == 0) {
        setShowScrollLeft(false)
      } else {
        setShowScrollLeft(true)
      }
      if (ele.scrollLeft + ele.offsetWidth >= ele.scrollWidth) {
        setShowScrollRight(false)
      } else {
        setShowScrollRight(true)
      }
    }
  }

  useEffect(() => {
    if (showQuestionsModal) {
      fetchQuestions(showQuestionsModal?.id, true)
    } else {
      fetchQuestions("all_questions", true)
    }
  }, [sessionQAIdSelected, questionAnswerSearch])

  const nameQASearch = (searchStr) => {
    searchStr = searchStr.trim()
    if (searchStr === "") {
      setQuestionAnswerSearch("")
      //setSearching(false)
      return
    }
    setQuestionAnswerSearch(searchStr)
    //setSearching(false)
  }

  const delayedQASearch = useCallback(
    _.debounce((searchStr) => {
      nameQASearch(searchStr)
    }, 1500),
    []
  )

  function displayViewAllQuestions() {
    if (questionLoader === "topic_" + showQuestionsModal?.id) {
      return (
        <div className={`w-100 text-center`}>
          <Loader />
        </div>
      )
    }

    if (showQuestionsModal != null && questionsData["topic_" + showQuestionsModal?.id]) {
      return (
        <>
          <div className={`col-12 d-inline-flex ${StylesC.search_section} py-1 mb-2`}>
            <img src={SearchIcon} alt={`search button`} className={`my-auto`} style={{ objectFit: `contain`, width: `16px`, height: `16px` }} />
            <FormControl
              autoFocus
              className={`border-0 py-0 px-2 form-control shadow-none font-14 ${Style.search_question}`}
              placeholder={`Search questions or topics`}
              onChange={(e) => {
                e.preventDefault()
                setQuestionLoader("search_field")
                delayedQASearch(e.target.value)
              }}
              onBlur={(e) => {
                e.preventDefault()
                nameQASearch(e.target.value)
              }}
              defaultValue={questionAnswerSearch}
              autoComplete="anyRandomString"
            />
            {questionLoader === "search_field" && <Loader />}
          </div>
          {showQuestionsModal?.id === "all_questions" && (
            <div
              className={`w-100 mb-2 ${Style.horiz_list}`}
              style={{ position: "relative" }}
              onMouseOver={() => {
                displayScrollButtons("sessions_list")
              }}
              onMouseOut={() => {
                //setHoveredResourcesSection(false)
              }}
            >
              <div
                className={`px-0 pointer ${Style.right_scroll} ${showScrollRight ? `` : `d-none`}`}
                onClick={() => {
                  scrollRight("sessions_list")
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} color="#fff" />
              </div>
              <div
                className={`px-0 pointer ${Style.left_scroll} ${showScrollLeft ? `` : `d-none`}`}
                onClick={() => {
                  scrollLeft("sessions_list")
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} color="#fff" />
              </div>
              <div
                className={`d-inline-flex mx-3 hide_scrollbar`}
                id="sessions_list"
                onScroll={() => {}}
                style={{ maxWidth: "95%", width: "95%", overflowX: "scroll", fontSize: 12 }}
              >
                {sessionsQA.map((item, index) => {
                  let lowerItem = item.name.toLowerCase()
                  return (
                    <div
                      key={index.toString()}
                      className={`pointer mr-2 px-3 py-0 ${Style.name_container} ${sessionQASelected === lowerItem ? Style.active : ""}`}
                      onClick={() => {
                        setSessionQAIdSelected(item.id)
                        if (sessionQASelected !== lowerItem) {
                          setSessionQASelected(lowerItem)
                        }
                      }}
                    >
                      <p className={`${Style.name}`}>{item.name}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <div className={`position-relative`} onScroll={handleQuestionScroll} style={{ maxHeight: 400, height: 400, overflowY: "scroll" }}>
            {displayQuestions()}
          </div>
        </>
      )
    }
  }

  async function changeQuestionsTopic(selectedQATopic) {
    if (!selectedQATopic || Object.keys(selectedQATopic).length === 0 || typeof selectedQATopic.id === "undefined") {
      return
    }
    let dummy = { ...questionsData },
      questionArrObj = dummy["topic_" + selectedQATopic.questionsId][selectedQATopic.arrId],
      type
    let prev_object_id = questionArrObj.object_id
    let prev_resource_type = questionArrObj.content_type_name
    if (selectedQATopic.id > 0) {
      questionArrObj.object_id = selectedQATopic.id
      questionArrObj.content_object_name = selectedQATopic.name
      questionArrObj.content_type_name = "topic"
      type = "topic"
    } else {
      questionArrObj.object_id = props.location.training_id
      questionArrObj.content_object_name = "Unassigned"
      type = "training"
    }
    let formData = new FormData()
    formData.append("object_id", selectedQATopic.id > 0 ? selectedQATopic.id : props.location.training_id)
    formData.append("resource_type", type)
    formData.append("training", props.location.training_id)
    formData.append("title", questionArrObj.title)
    formData.append("prev_object_id", prev_object_id)
    formData.append("prev_resource_type", prev_resource_type)

    let response = await putRequest(`/queries/${questionArrObj.id}/`, formData)
    if (response.success) {
      // setQuestionsData(dummy)
    }
  }

  useEffect(() => {
    changeQuestionsTopic(selectedQATopic)
  }, [selectedQATopic])

  function displayQuestions() {
    if (!activeSession && sessionQASelected === "ongoing session") {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <p className={`mb-2`} style={{ fontSize: "14px" }}>
            No ongoing session
          </p>
        </Col>
      )
    } else if (questionsData["topic_" + showQuestionsModal?.id].length > 0) {
      return questionsData["topic_" + showQuestionsModal?.id].map((data, index) => {
        let topicName = data.content_type_name !== "training" ? data?.content_object_name : "Unassigned",
          topicId = data.content_type_name !== "training" ? data.object_id : 0,
          questionExpand2 = questionExpand && questionExpand[`ques_${data.id}`] !== undefined && questionExpand[`ques_${data.id}`]
        return <div>Question Box Componene</div>
      })
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <p className={`mb-2`} style={{ fontSize: "14px" }}>
            No questions from students
          </p>
        </Col>
      )
    }
  }

  function deleteReply(id) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/replies/" + id + "/", {
        headers: DefaultHeader,
      })
      .then((res) => {})
      .catch((error) => {
        console.log(error)
      })
  }

  function editReplyClick(id, title, index) {
    setIsDrafting(false)
    setUploadInTopic(id)
    setUploadDataSequence(index)
    setEditReplyData({ index: index, id: id })
    setQuestionReplyInput(title)
  }

  /******** */
  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg( new_files[i].name + ' has size more than 200MB.')
        setNotificationData({
          type: "error",
          title: new_files[i].name + " has size more than 200MB.",
        })
      }
    }
  }

  function fileInputClicked(event, id, module_id, seq) {
    setUploadInTopic(id)
    setUploadInModule(module_id)
    setUploadDataSequence(seq) //data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))

    window.removeEventListener("focus", handleFocusBack)
  }

  function handleFocusBack() {
    setUploadInTopic(null)
    setUploadInModule(null)
    setUploadDataSequence(null)
    window.removeEventListener("focus", handleFocusBack)
  }

  function clickedFileInput() {
    window.addEventListener("focus", handleFocusBack)
  }

  function uploadDataProps(topic_id, module_id, seq, showSection = "") {
    //let seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2);

    setUploadInTopic(topic_id)
    setUploadInModule(module_id)
    setUploadDataSequence(seq) //data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2));
    setShowSection(showSection)
    setAttrForResources({ questionReply: topic_id, mainId: topic_id })
  }
  function uploadDocs(e, topic_id, module_id, seq, upload, showSection) {
    e.preventDefault()
    e.stopPropagation()
    uploadDataProps(topic_id, module_id, seq, showSection)
    let domObj = document.getElementById("fileElem")
    if (domObj && upload) {
      if (showSection === "turientimage") {
        domObj.accept = "image/*"
      }
      domObj.click()
    }
  }
  function openNewWin(section, type, mainId, module_id, seq) {
    let wnd //, seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2);
    //let seq = data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2);
    uploadDataProps(mainId, module_id, seq)
    //let lsObj = JSON.stringify({topic_id, module_id, seq})

    if (type === "quiz") {
      //localStorage.setItem('quiz', lsObj)
      wnd = window.open("/app/quizzes/create", type + "12")
      quizWin.current = { wnd, mainId, module_id, seq, section }
    } else if (type === "casestudy") {
      wnd = window.open("/app/case-study/create", type + "12")
      casestudyWin.current = { wnd, mainId, module_id, seq, section }
    } else if (type === "checklist") {
      wnd = window.open("/app/checklist/create", type + "12")
      checklistWin.current = { wnd, mainId, module_id, seq, section }
    }
  }
  function getNewWinData(type) {
    let win = null
    if (type === "quiz") {
      win = quizWin.current
    } else if (type === "casestudy") {
      win = casestudyWin.current
    } else if (type === "checklist") {
      win = checklistWin.current
    }
    return win
  }

  function handleNewWinMessages(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      if (event.data.instruction === "ready") {
        let win = getNewWinData(event.data.type)
        if (win && win.wnd) {
          win.wnd.postMessage({ instruction: { questionReply: win.mainId, sequence: win.seq } }, window.location.origin)
        }
      } else if (event.data.instruction === "payload") {
        let win = getNewWinData(event.data.type)
        if (win) {
          setTimeout(() => {
            fetchReplyResources(win.mainId, true)
          }, 1500) //Delay because the python backend thread takes time
          const evt = new CustomEvent("my-refresh-resources", {
            bubbles: true,
            detail: event.data.type,
          })
          document.dispatchEvent(evt)
        }
      }
    }
  }

  function openModal(type, mainId, module_id, seq) {
    uploadDataProps(mainId, module_id, seq)

    if (type === "poll") {
      //setShowPollModal(true)
      //props.showPollModal && props.showPollModal(true)
      const evt1 = new CustomEvent("my-openClosePollPopQuizModal", {
        bubbles: true,
        detail: { which: "poll", openclose: true },
      })
      document.dispatchEvent(evt1)

      const evt = new CustomEvent("my-poll-data", {
        bubbles: true,
        detail: { questionReply: mainId, mainId, module_id, sequence: seq },
      })
      document.dispatchEvent(evt)
    } else if (type === "pop-quiz") {
      //setShowPopQuizModal(true)
      //props.showPopQuizModal && props.showPopQuizModal(true)
      const evt1 = new CustomEvent("my-openClosePollPopQuizModal", {
        bubbles: true,
        detail: { which: "pop-quiz", openclose: true },
      })
      document.dispatchEvent(evt1)

      const evt = new CustomEvent("my-pop-quiz-data", {
        bubbles: true,
        detail: { questionReply: mainId, mainId, module_id, sequence: seq },
      })
      document.dispatchEvent(evt)
    }
  }
  function handlePollModalMsgs(evt) {
    setTimeout(() => {
      fetchReplyResources(evt.detail.mainId ?? uploadInTopic, true)
    }, 1500) //Delay because the python backend thread takes time
  }

  function handlePopQuizModalMsgs(evt) {
    setTimeout(() => {
      fetchReplyResources(evt.detail.mainId ?? uploadInTopic, true)
    }, 1500) //Delay because the python backend thread takes time
  }

  function refetchLeftSideResourcesBar(pActiveResType = "") {
    const evt = new CustomEvent("my-refresh-resources", {
      bubbles: true,
      detail: pActiveResType,
    })
    document.dispatchEvent(evt)
    //fetchAllResources(pActiveResType)
  }

  /******** */

  function showRepliesResources(id, seq) {
    //onDrop={onDropReplyResources}
    let replyId = id
    id = "questionReply_" + id
    let data = repliesResources[id] ?? []
    let next_url = null
    let see_more_loader = false
    if (repliesResourcesNextUrl[id]) {
      next_url = repliesResourcesNextUrl[id]
    }

    if (data) {
      return (
        <Droppable key={id + `_name`} droppableId={id} type={`resourceDraggable`}>
          {(provided, snapshot) => {
            return (
              <div
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={(e) => onDrop(e, replyId, seq)}
                className={`bg-transparent px-1`}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {data.map((item, index) => {
                  let { typeId, imgObj, imgType } = getImageToBeRendered(item)
                  return (
                    <>
                      <Draggable
                        key={item.id + `_name_` + index}
                        draggableId={item.id.toString() + "_" + index.toString()}
                        index={index}
                        className={item.resource_data !== null ? "" : "d-none"}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={item.resource_data !== null ? "resource-item" : "d-none"}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div
                              className={`pr-2 d-flex`}
                              style={{
                                color: `#E0E2E5`,
                                background: "#4F6080",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="d-flex" style={{ alignItems: "center" }}>
                                <FontAwesomeIcon className={`ml-1 ${srResourcesFetched} ${item.resource_type ? `d-none` : ``}`} icon={faSpinner} size="lg" />
                                {item.resource_data ? (
                                  item.resource_data.thumbnail_url ? (
                                    <LazyLoadImage
                                      className={`my-auto`}
                                      src={item.resource_data.thumbnail_url}
                                      alt={item.name}
                                      style={{
                                        width: `28px`,
                                        height: `20px`,
                                        fontSize: `12px`,
                                      }}
                                    />
                                  ) : (
                                    <>
                                      {typeId === 1 && false ? (
                                        <FontAwesomeIcon
                                          icon={imgObj}
                                          className={`my-0`}
                                          style={{
                                            color: "#67edfd",
                                            marginBottom: 0,
                                            width: `28px`,
                                            height: `20px`,
                                          }}
                                        />
                                      ) : (
                                        <LazyLoadImage
                                          className={`my-auto`}
                                          src={imgObj}
                                          alt={item.name}
                                          style={{
                                            width: `28px`,
                                            height: `20px`,
                                            fontSize: `12px`,
                                          }}
                                        />
                                      )}
                                    </>
                                  )
                                ) : (
                                  ``
                                )}
                                <p
                                  style={{
                                    fontSize: "14px",
                                    margin: "0px 0px 0px 10px",
                                  }}
                                >
                                  {item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name}
                                </p>
                              </div>
                              <div>
                                <img
                                  src={DeleteIcon}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    const newState = data
                                    newState.splice(index, 1)
                                    setSrResourcesFetched(false)
                                    let r = { ...repliesResources }
                                    r[id] = newState
                                    setRepliesResources(r)
                                    deleteResource(item.id)
                                  }}
                                  alt={"resource delete icon"}
                                  className={`my-auto pointer`}
                                  style={{
                                    objectFit: `contain`,
                                    height: `13px`,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </>
                  )
                })}
                {next_url ? (
                  <div className={`text-center`}>
                    <Button
                      variant="none"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        fetchNextReplyResources(next_url, replyId)
                      }}
                      className={`${Styles.font_12} border_on_hover text-center px-3 py-1 mt-2`}
                      style={{
                        backgroundColor: `#212c42`,
                        color: `#c6cff4`,
                        borderRadius: `15px`,
                      }}
                    >
                      See More
                    </Button>
                  </div>
                ) : (
                  ""
                )}

                {provided.placeholder}
                <React.Fragment>
                  <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>
                    <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent  text-center ${files.length === 0 ? `` : `border-0`}`}>
                      <div className={`w-100 col-12`}>
                        <Form className="my-form mb-0">
                          <React.Fragment>
                            <p className={`${StylesM.font_14} mb-2`}>
                              Drag and drop media/document files to add content
                              {/*<label className={`button btn border-0 px-0 ${StylesM.font_14}`} style={{ color:"#E0E2E5",textDecoration:'underline'}} htmlFor="fileElem">Browse</label>*/}
                            </p>
                          </React.Fragment>
                          <input
                            className={`d-none`}
                            type="file"
                            id="fileElem"
                            multiple
                            accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              validateAndAdd(e)
                            }}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            )
          }}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function displayReplyResources(resourcesList) {
    return (
      <div className={`d-flex flex-column my-2`}>
        {resourcesList.map((item, index) => {
          let { typeId, imgObj, imgType } = getImageToBeRendered(item)
          return (
            <div
              key={`res_${item?.id}`}
              className={`pr-2 d-flex mb-1`}
              style={{
                color: `#E0E2E5`,
                background: "#4F6080",
                justifyContent: "space-between",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div className="d-flex" style={{ alignItems: "center" }}>
                <FontAwesomeIcon className={`ml-1 ${srResourcesFetched} ${item.resource_type ? `d-none` : ``}`} icon={faSpinner} size="lg" />
                {item.resource_data ? (
                  item.resource_data.thumbnail_url ? (
                    <LazyLoadImage
                      className={`my-auto`}
                      src={item.resource_data.thumbnail_url}
                      alt={item.name}
                      style={{
                        width: `38px`,
                        height: `40px`,
                        fontSize: `12px`,
                      }}
                    />
                  ) : (
                    <>
                      {typeId === 1 && false ? (
                        <FontAwesomeIcon icon={imgObj} className={`my-0 ${Style.fa_res_icon}`} />
                      ) : (
                        <LazyLoadImage className={`my-auto ${Style.image_res_icon}`} src={imgObj} alt={item.name} />
                      )}
                    </>
                  )
                ) : (
                  ``
                )}
                <p
                  style={{
                    fontSize: "14px",
                    margin: "0px 0px 0px 10px",
                    wordBreak: "break-word",
                  }}
                >
                  {item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  function handleRepliesExpandCollapse(e) {
    let replyDiv = document.querySelector("#reply-box")
    if (!replyDiv) return

    replyDiv.style.display = "none"
  }

  function displayReply() {
    let topic_id = isDrafting ? createdReply : editReplyData?.id,
      module_id = 0,
      seq = 10,
      trainerId = isBrowser() ? parseInt(window.localStorage.getItem("student_id")) : 0

    if (questionLoader === `ques_${questionReply?.id}`) {
      return (
        <div className={`w-100 text-center`}>
          <Loader />
        </div>
      )
    }
    if (questionReplyData != null && questionReplyData["ques_" + questionReply.id]) {
      let quesIndex = questionsData[`topic_${showQuestionsModal?.id}`].findIndex((q) => q.id === questionReply?.id)
      if (questionsData[`topic_${showQuestionsModal?.id}`][quesIndex]) {
        let quesKey = questionsData[`topic_${showQuestionsModal?.id}`][quesIndex],
          topicName = quesKey.content_type_name !== "training" ? quesKey?.content_object_name : "Unassigned",
          topicId = quesKey.content_type_name !== "training" ? quesKey.object_id : 0

        return (
          <div>
            <div className={`${Style.modal_box} mb-2`}>
              <div className={`d-flex justify-content-between px-3 py-2`} style={{ borderBottom: "1px solid #606778" }}>
                <div className={`d-inline-flex`}>
                  <AvatarCircle name={quesKey?.owner?.first_name} size={`45px`} />
                  <div className={`ml-2`}>
                    <div className={`fs-16px ellipsis_1_effect`}>
                      <b>{questionsData[`topic_${showQuestionsModal?.id}`] ? quesKey.title : ""}</b>
                    </div>
                    <div className={`d-inline-flex`}>
                      {showQuestionsModal?.id === "all_questions" && quesKey?.content_object_name !== "Question" && (
                        <>
                          <QASessionTopicPicklist
                            name={topicName}
                            data={sessionsTopicsQA}
                            questionsObj={{ arrId: quesIndex, questionsId: showQuestionsModal?.id }}
                            selectedQATopic={topicId}
                            setSelectedQATopic={setSelectedQATopic}
                            templateId={props.location.template_id}
                          />
                        </>
                      )}
                      <div className={`mx-2 fs-14px align-self-center`}>
                        <span style={{ color: "#A1AACD" }}> Asked by </span>
                        <span className={`underline`}>{quesKey.owner.first_name}</span>
                        <span style={{ color: "#A1AACD" }}> on </span>
                        {`${utcDateTime.utcDateTime(quesKey.updated_at, "month_name")} ${utcDateTime.utcDateTime(
                          quesKey.updated_at,
                          "date"
                        )}, ${utcDateTime.utcDateTime(quesKey.updated_at, "year")}`}
                      </div>
                      {questionsData[`topic_${showQuestionsModal?.id}`][quesIndex]?.votes?.count > 0 && (
                        <div className={`mx-0 fs-14px align-self-center px-1 py-1`}>
                          <FontAwesomeIcon className={`mr-1`} icon={faHeart} />
                          <u>{questionsData[`topic_${showQuestionsModal?.id}`][quesIndex]?.votes?.count} Likes</u>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {quesKey.reply_count > 0 && (
                  <div>
                    <FontAwesomeIcon
                      onClick={(e) => {
                        setQuestionReplyExpand((q) => !q)
                      }}
                      className={`pointer`}
                      style={{ color: "#E0E2E5" }}
                      icon={questionReplyExpand ? faChevronCircleUp : faChevronCircleDown}
                    />
                  </div>
                )}
              </div>

              {/**** */}
              <div
                id={`reply-box`}
                onScroll={(e) => {
                  e.stopPropagation()
                }}
                className={`mx-2 mt-2`}
                style={{ maxHeight: "260px", overflowY: "auto", display: `${questionReplyExpand ? "block" : "none"}` }}
              >
                {questionReplyNextUrl[`ques_${questionReply.id}`] ? (
                  <div
                    onClick={(e) => {
                      fetchNextReplies(questionReplyNextUrl[`ques_${questionReply.id}`], questionReply.id)
                    }}
                    className={`underline fs-14px pointer`}
                  >
                    Load previous replies
                  </div>
                ) : null}
                {questionLoader === `reply_next_${questionReply?.id}` && (
                  <div className={`w-100 text-center`}>
                    <Loader />
                  </div>
                )}
                {questionReplyData[`ques_${questionReply.id}`].map((data, index) => {
                  if (data.published && editReplyData?.id !== data?.id) {
                    return (
                      <div key={`reply_${index}`} style={{ backgroundColor: "#4F6080" }} className={`${Style.modal_box} fs-16px mb-2 p-2`}>
                        <div className={`d-inline-flex w-100`}>
                          <AvatarCircle name={data?.owner?.first_name ?? "U"} size={`40px`} />
                          <div className={`ml-2 fs-14px align-self-center d-flex flex-column flex-grow-1`}>
                            <div className={`d-flex`}>
                              <div style={{ color: "#E0E2E5" }}>
                                <b className="mr-2" style={{ color: "#E0E2E5" }}>
                                  {data.owner.id === trainerId ? "Your answer" : "Answered by " + data.owner.first_name}
                                </b>
                                <span style={{ opacity: "60%" }}>
                                  {`${utcDateTime.utcDateTime(data.updated_at, "time")} ${utcDateTime.utcDateTime(
                                    data.updated_at,
                                    "month_name"
                                  )} ${utcDateTime.utcDateTime(data.updated_at, "date")}, ${utcDateTime.utcDateTime(data.updated_at, "year")}`}
                                </span>
                              </div>
                              {data?.votes?.count > 0 && (
                                <div className={`mx-2 fs-14px align-self-center px-2 py-1 ${Style.like_count}`}>
                                  <FontAwesomeIcon className={`mr-1`} icon={faHeart} /> {data.votes.count}
                                </div>
                              )}
                            </div>
                            <div>{data.title}</div>
                            {displayReplyResources(data?.resources?.length > 0 ? data.resources : [])}
                            {/*{!data.published && <button onClick={(e)=>{e.preventDefault();setCreatedReply(data.id);setQuestionReplyInput(data.title);setDraftIndex(index)}} style={{width:'fit-content', backgroundColor: '#B2B2B2'}} className={`${Style.button} px-2 text-dark`}>Publish</button>}*/}
                          </div>
                          <div className={`ml-auto`}>
                            {" "}
                            {data?.owner?.id == window.localStorage.getItem("student_id") && (
                              <Dropdown className="breakout-groups pointer mr-3 ml-0">
                                <Dropdown.Toggle className="border-none p-0 shadow-none" style={{ backgroundColor: "transparent" }} id="dropdown-basic12">
                                  <div>
                                    <FontAwesomeIcon
                                      icon={faEllipsisV}
                                      style={{
                                        objectFit: `contain`,
                                        height: `20px`,
                                        color: `#E0E2E5`,
                                      }}
                                    />
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`br-9px breakout-groups ${StylesB.min_fit_width}`}>
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      e.preventDefault()
                                      editReplyClick(data.id, data.title, index)
                                    }}
                                    className={`${StylesB.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
                                    style={{ minWidth: `max-content` }}
                                  >
                                    &nbsp;Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      e.preventDefault()
                                      deleteReply(data.id)
                                    }}
                                    className={`${StylesB.dropitem} text-white fs-14px px-2 py-1 d-inline-flex`}
                                    style={{ minWidth: `max-content` }}
                                  >
                                    &nbsp;Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>

            {/***** */}
            <div className={`${Style.modal_box} mb-2 w-100 position-relative`} style={{ backgroundColor: "#4F6080", zIndex: 900 }}>
              <div className="d-inline-flex px-2 py-1 w-100">
                <AvatarCircle name={isBrowser() ? window.localStorage.getItem("student_first_name") : "U"} size={`45px`} />
                <div className={`ml-0 flex-grow-1`}>
                  <textarea
                    value={questionReplyInput}
                    onChange={(e) => {
                      handleReplyInputChange(e)
                    }}
                    rows={2}
                    className={`${Style.textArea} fs-16px p-2`}
                    placeholder={`Type text here`}
                    style={{ border: "1px solid white" }}
                  />
                  <div className={`position-absolute`} style={{ bottom: "40px", right: "5px" }}>
                    {replyInputLoader && <Loader />}
                  </div>
                </div>
              </div>
              {/** : '#42506C' 384257*/}
              <div
                key={"div2"}
                className={`px-0`}
                style={{
                  backgroundColor: "#42506C",
                  borderRadius: "8px",
                  boxShadow: "0px 3px 6px #00000029",
                }}
              >
                <div
                  style={{
                    borderRadius: "8px 8px 0px 0px",
                    borderBottom: `1px dashed #838b9e`,
                    padding: "8px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "0px",
                      color: "#E0E2E5",
                      lineHeight: 1,
                      opacity: 0.6,
                    }}
                  >
                    Resources
                  </p>
                </div>
                {showRepliesResources(topic_id)}
                <div
                  className="d-flex p-1"
                  style={{
                    borderTop: `1px dashed #838b9e`,
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <OverlayTrigger overlay={<Tooltip>Add video</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faVideo}
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "video")
                        uploadDocs(e, topic_id, module_id, seq, false, "video")
                      }}
                      title={`Add Videos`}
                      alt={`Add Videos`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add blog</Tooltip>}>
                    <img
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "blog")
                        uploadDataProps(topic_id, module_id, seq, "")
                        setAddBlog(true)
                      }}
                      src={WhiteBlogIcon}
                      title={`Add Blog`}
                      alt={`add blog button`}
                      className={`${
                        props.leftNavOpen && props.currentSelectedResourcesType === "blog" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add documents</Tooltip>}>
                    <img
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "document")
                        uploadDocs(e, topic_id, module_id, seq, false, "document")
                      }}
                      src={documents}
                      alt={`Add Documents`}
                      title={`Add Documents(Doc, Excel, PPT, PDF, Google Docs Link)`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add images</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faImage}
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "turientimage")
                        uploadDocs(e, topic_id, module_id, seq, true, "turientimage")
                      }}
                      title={`Add Images`}
                      alt={`Add Images`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add links</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faLink}
                      onClick={(e) => {
                        props.openNavWithResourceSection("Resource", "link")
                        uploadDocs(e, topic_id, module_id, seq, false, "link")
                      }}
                      title={`Add Links`}
                      alt={`Add Links`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <button
              disabled={(questionReplyInput === "" && repliesResources?.[`questionReply_${topic_id}`]?.length === 0) || replyInputLoader}
              onClick={(e) => {
                handlePublishReply(e, questionReply.id)
              }}
              className={`${Style.button} px-3`}
            >
              Publish
            </button>
          </div>
        )
      }
    }
  }

  function handleQuestionModalHide() {
    setShowQuestionsModal(null)
    setQuestionReply(null)
    setEditReplyData(null)
    setIsDrafting(true)
    setQuestionReplyInput("")
    props.setLeftNavOpen(false)
    props.setShowAllResources(false)
    props.setDisableScroll(false)
    setQuestionAnswerSearch("")
    setSessionQAIdSelected(0)
    setSessionQASelected("all sessions")
    let quesData = { ...questionsData }
    delete quesData["topic_all_questions_sidebar"]
    setQuestionsData(quesData)
  }
  function handleBreakoutSessionModal() {
    setShowBreakoutSessionModal(false)
    props.setShowAllResources(false)
    setActiveBreakoutBtn("overall_progress")
    // setActiveBreakoutSession(null)
    props.setDisableScroll(false)
    setShowBreakoutActivities(false)
    setShowActivityResources(false)
  }

  useEffect(() => {
    if (showQuestionsModal !== null && Object.keys(showQuestionsModal).length > 0) {
      props.setActiveSection("LessonBoard")
      setShowSection("")
      fetchQuestions(showQuestionsModal?.id)
    }
  }, [showQuestionsModal])

  function questionsModal() {
    if (!showQuestionsModal || Object.keys(showQuestionsModal).length <= 0) return null
    else {
      return (
        <>
          <div className={`parent h-100 ${Style.question_rnd_modal}`}>
            <div className={`modal-content1 ${Style.questions_modal}`} style={{ borderRadius: 8 }}>
              <div className={"p-2 modal-header1"} style={{ borderBottom: "1px solid rgb(146, 155, 189)" }}>
                <div className={`w-100 d-flex justify-content-between`}>
                  <div>{showQuestionsModal?.name}</div>
                  <div>
                    <FontAwesomeIcon
                      className={`pointer`}
                      onClick={() => {
                        handleQuestionModalHide()
                      }}
                      icon={faTimesCircle}
                    />
                  </div>
                </div>
              </div>
              <div className={`modal-body`}>
                {questionReply === null ? (
                  <div className={``}>
                    {displayViewAllQuestions()}
                    {questionLoader === "ques_next" && (
                      <div className={`w-100 text-center`}>
                        <Loader />
                      </div>
                    )}
                  </div>
                ) : (
                  displayReply()
                )}
              </div>
            </div>
            {/*<UploadFiles2 sequence={uploadDataSequence} module={"Question"} questionReply={{id: uploadInTopic, name: 'Reply'}} files={files} setFiles={(value)=>setFiles(value)} fetchTopicResources={(id)=>fetchReplyResources(id)} showSection={showSection} setShowSection={setShowSection} refetchLeftSideResourcesBar={refetchLeftSideResourcesBar} /> */}
            {uploadInTopic !== null && uploadDataSequence !== null ? (
              <UploadFiles2
                files={files}
                setFiles={(value) => setFiles(value)}
                fetchTopicResources={(id) => fetchReplyResources(id, true)}
                showSection={showSection}
                setShowSection={setShowSection}
                refetchLeftSideResourcesBar={refetchLeftSideResourcesBar}
                properties={{
                  module: "Question",
                  questionReply: uploadInTopic,
                  mainId: uploadInTopic,
                  itemName: "Reply",
                  sequence: uploadDataSequence,
                }}
              />
            ) : null}
            <Blog
              showBlog={addBlog}
              setShowBlog={setAddBlog}
              topicId={uploadInTopic}
              sequenceId={uploadDataSequence}
              fetchTopicResources={(id) => fetchReplyResources(id, true)}
              refetchLeftSideResourcesBar={() => refetchLeftSideResourcesBar("blog")}
              properties={attrForResources}
            />
            {/*</Rnd> : ``
      }*/}
          </div>
        </>
      )
    }
  }

  function getAllBreakOutGroups() {
    if (activeBreakoutBtn === "activity_wise_progress") {
      if (activeActivityResource && activeActivityResource.resource_type === "casestudy") {
        let key = `res_${activeActivityResource?.id}`
        return (
          <div
            onScroll={(e) => {
              e.stopPropagation()
            }}
            style={{ maxHeight: "50vh", overflowY: "scroll" }}
          >
            {activityWiseCaseStudyGroupsFetched[key] ? (
              activityWiseCaseStudyGroups[key].results?.length > 0 ? (
                activityWiseCaseStudyGroups[key].results.map((item, index) => {
                  // let report = item.report_card
                  let progress
                  if (item["finished_percentage"] === undefined) {
                    progress = 100.0
                  } else {
                    progress = item["finished_percentage"]
                  }
                  return (
                    <div
                      key={`allBreakout${index}`}
                      className={`w-100 d-inline-flex mb-2 position-relative`}
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "9px",
                        height: "60px",
                      }}
                    >
                      <p
                        className={`mb-0 px-3 poppins_font position-absolute w-100`}
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          left: "0px",
                          top: "25%",
                        }}
                      >
                        {item.title} ({item.students_count})
                        {item?.report_card && (
                          <span onClick={() => openCSReportModal(item)} className={`float-right underline pointer`}>
                            View Details
                          </span>
                        )}
                      </p>
                      <div
                        className={`py-4`}
                        style={{
                          backgroundColor: "#5D6D8D69",
                          width: `${progress}%`,
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      ></div>
                      {/*<p className={`mb-0 pr-3 pointer poppins_font position-absolute`} style={{fontSize:'16px',fontWeight:'500',right:'0px',top:'25%'}}><u>View Details</u></p>*/}
                    </div>
                  )
                })
              ) : (
                <div className={`w-100 d-inline-flex text-center`}>
                  <p className={`mb-3 py-3 mx-auto`}>No Groups Found</p>
                </div>
              )
            ) : (
              <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                <Spinner animation="border" variant="secondary" />
              </Col>
            )}
          </div>
        )
      } else if (activeActivityResource && activeActivityResource.resource_type === "quiz") {
        let key = `res_${activeActivityResource?.id}`
        return (
          <div
            onScroll={(e) => {
              e.stopPropagation()
            }}
            style={{ maxHeight: "50vh", overflowY: "scroll" }}
          >
            {activityWiseQuizGroupsFetched[key] ? (
              activityWiseQuizGroups[key].results?.length > 0 ? (
                activityWiseQuizGroups[key].results.map((item, index) => {
                  // let report = item.report_card
                  let progress
                  if (item["finished_percentage"] === undefined) {
                    progress = 100.0
                  } else {
                    progress = item["finished_percentage"]
                  }
                  return (
                    <div
                      key={`allBreakout${index}`}
                      className={`w-100 d-inline-flex mb-2 position-relative`}
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "9px",
                        height: "60px",
                      }}
                    >
                      <p
                        className={`mb-0 px-3 poppins_font position-absolute w-100`}
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          left: "0px",
                          top: "25%",
                        }}
                      >
                        {item.title} ({item.students_count})
                        {item?.report_card && (
                          <span onClick={() => openQuizReportModal(item)} className={`float-right underline pointer`}>
                            View Details
                          </span>
                        )}
                      </p>
                      <div
                        className={`py-4`}
                        style={{
                          backgroundColor: "#5D6D8D69",
                          width: `${progress}%`,
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      ></div>
                      {/*<p className={`mb-0 pr-3 pointer poppins_font position-absolute`} style={{fontSize:'16px',fontWeight:'500',right:'0px',top:'25%'}}><u>View Details</u></p>*/}
                    </div>
                  )
                })
              ) : (
                <div className={`w-100 d-inline-flex text-center`}>
                  <p className={`mb-3 py-3 mx-auto`}>No Groups Found</p>
                </div>
              )
            ) : (
              <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                <Spinner animation="border" variant="secondary" />
              </Col>
            )}
          </div>
        )
      } else if (activeActivityResource && activeActivityResource.resource_type === "checklist") {
        //need to check
        let key = `res_${activeActivityResource?.id}`
        return (
          <div
            onScroll={(e) => {
              e.stopPropagation()
            }}
            style={{ maxHeight: "50vh", overflowY: "scroll" }}
          >
            {activityWiseChecklistGroupsFetched[key] ? (
              activityWiseChecklistGroups[key]?.results?.length > 0 ? (
                activityWiseChecklistGroups[key].results.map((item, index) => {
                  return (
                    <div
                      key={`allBreakout${index}`}
                      className={`w-100 d-inline-flex mb-2 position-relative`}
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "9px",
                        height: "60px",
                      }}
                    >
                      <p
                        className={`mb-0 px-3 poppins_font position-absolute w-100`}
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          left: "0px",
                          top: "25%",
                        }}
                      >
                        {item.title} ({item.students_count})
                        {item?.report_card && (
                          <span
                            onClick={() => {
                              openChecklistReportModal(item)
                            }}
                            className={`float-right underline pointer`}
                          >
                            View Details
                          </span>
                        )}
                      </p>
                      <div
                        className={`py-4`}
                        style={{
                          backgroundColor: "#5D6D8D69",
                          width: "0",
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      ></div>
                      {/*<p className={`mb-0 pr-3 pointer poppins_font position-absolute`} style={{fontSize:'16px',fontWeight:'500',right:'0px',top:'25%'}}><u>View Details</u></p>*/}
                    </div>
                  )
                })
              ) : (
                <div className={`w-100 d-inline-flex text-center`}>
                  <p className={`mb-3 py-3 mx-auto`}>No Groups Found</p>
                </div>
              )
            ) : (
              <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                <Spinner animation="border" variant="secondary" />
              </Col>
            )}
          </div>
        )
      } else if (activeActivityResource && activeActivityResource.resource_type === "matchthefollowing") {
        let key = `res_${activeActivityResource?.id}`
        return (
          <div
            onScroll={(e) => {
              e.stopPropagation()
            }}
            style={{ maxHeight: "50vh", overflowY: "scroll" }}
          >
            {activityWiseMTFGroupsFetched[key] ? (
              activityWiseMTFGroups[key].length > 0 ? (
                activityWiseMTFGroups[key].map((item, index) => {
                  // let report = item.report_card
                  let progress
                  if (item["finished_percentage"] === undefined) {
                    progress = 100.0
                  } else {
                    progress = item["finished_percentage"]
                  }
                  return (
                    <div
                      key={`allBreakout${index}`}
                      className={`w-100 d-inline-flex mb-2 position-relative`}
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "9px",
                        height: "60px",
                      }}
                    >
                      <p
                        className={`mb-0 px-3 poppins_font position-absolute w-100`}
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          left: "0px",
                          top: "25%",
                        }}
                      >
                        {item.group_name}
                        {item?.report_card && (
                          <span onClick={() => openMTFReportModal(item)} className={`float-right underline pointer`}>
                            View Details
                          </span>
                        )}
                      </p>
                      <div
                        className={`py-4`}
                        style={{
                          backgroundColor: "#5D6D8D69",
                          width: `${progress}%`,
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      ></div>
                      {/*<p className={`mb-0 pr-3 pointer poppins_font position-absolute`} style={{fontSize:'16px',fontWeight:'500',right:'0px',top:'25%'}}><u>View Details</u></p>*/}
                    </div>
                  )
                })
              ) : (
                <div className={`w-100 d-inline-flex text-center`}>
                  <p className={`mb-3 py-3 mx-auto`}>No Groups Found</p>
                </div>
              )
            ) : (
              <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                <Spinner animation="border" variant="secondary" />
              </Col>
            )}
          </div>
        )
      } else if (
        activeActivityResource === null ||
        !(activityResources && activityResources[`act_${activeBreakoutActivity?.id}`] && activityResources[`act_${activeBreakoutActivity?.id}`].length)
      ) {
        return (
          <div className={`w-100 d-inline-flex text-center`}>
            <p className={`mb-3 py-3 mx-auto`}>No Resource found in current activity</p>
          </div>
        )
      } else {
        return (
          <div
            onScroll={(e) => {
              e.stopPropagation()
            }}
            style={{ maxHeight: "50vh", overflowY: "scroll" }}
          >
            {activityWiseProgressFetched ? (
              activityWiseBreakoutGroupsProgress.length > 0 ? (
                activityWiseBreakoutGroupsProgress.map((item, index) => {
                  let report = item.breakout_session_report
                  return (
                    <div
                      key={`allBreakout${index}`}
                      className={`w-100 d-inline-flex mb-2 position-relative`}
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "9px",
                        height: "60px",
                      }}
                    >
                      <p
                        className={`mb-0 px-3 poppins_font position-absolute w-100`}
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          left: "0px",
                          top: "25%",
                        }}
                      >
                        {item.title} ({report.members})
                      </p>
                      <div
                        className={`py-4`}
                        style={{
                          backgroundColor: "#5D6D8D69",
                          width: `${(report.completed_resources / report.resources) * 100}%`,
                          borderRadius: "9px 0px 0px 9px",
                        }}
                      ></div>
                      {/*<p className={`mb-0 pr-3 pointer poppins_font position-absolute`} style={{fontSize:'16px',fontWeight:'500',right:'0px',top:'25%'}}><u>View Details</u></p>*/}
                    </div>
                  )
                })
              ) : (
                <div className={`w-100 d-inline-flex text-center`}>
                  <p className={`mb-3 py-3 mx-auto`}>No Groups Found</p>
                </div>
              )
            ) : (
              <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                <Spinner animation="border" variant="secondary" />
              </Col>
            )}
          </div>
        )
      }
    } else {
      return (
        <div
          onScroll={(e) => {
            e.stopPropagation()
          }}
          style={{ maxHeight: "50vh", overflowY: "scroll" }}
        >
          {breakoutGroupsProgress.map((item, index) => {
            let report = item.breakout_session_report
            return (
              <div
                key={`breakout_${index}`}
                className={`w-100 d-inline-flex mb-2 position-relative`}
                style={{
                  backgroundColor: "#303C54",
                  borderRadius: "9px",
                  height: "60px",
                }}
              >
                <p
                  className={`mb-0 pl-3 poppins_font position-absolute`}
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    left: "0px",
                    top: "25%",
                  }}
                >
                  {item.title} ({report.members})
                </p>
                <div
                  className={`py-4`}
                  style={{
                    backgroundColor: "#5D6D8D69",
                    width: `${(report.completed_resources / report.resources) * 100}%`,
                    borderRadius: "9px 0px 0px 9px",
                  }}
                ></div>
                {/*<p className={`mb-0 pr-3 pointer poppins_font position-absolute`} style={{fontSize:'16px',fontWeight:'500',right:'0px',top:'25%'}}><u>View Details</u></p>*/}
              </div>
            )
          })}
        </div>
      )
    }
  }

  // Quiz group moderation code start.

  function fetchActivityWiseQuizGroupList() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/quiz-group-report-card/${activeBreakoutSession?.id}/groups_list/?breakout_activity=${activeBreakoutActivity.id}&resource=${activeActivityResource?.id}&limit=10`,
        { headers: login_credentials }
      )
      .then((res) => {
        let data = { ...activityWiseQuizGroups }
        let fetchData = { ...activityWiseQuizGroupsFetched }
        let key = `res_${activeActivityResource?.id}`
        data[key] = res.data
        fetchData[key] = true
        setActivityWiseQuizGroups(data)
        setActivityWiseQuizGroupsFetched(fetchData)
      })
      .catch((err) => {})
  }
  function updateQuizGroupReportCard(formData, cs_report_card_id) {
    axios.put(process.env.GATSBY_REACT_APP_API_HOST + `/quiz-group-report-card/${cs_report_card_id}/`, formData, { headers: login_credentials }).then((res) => {
      //update states here
      let data = { ...quizGroupData }
      data["quiz_" + cs_report_card_id].report_track.remark = res?.data?.remark
      setQuizGroupData(data)
    })
  }

  const autoSaveQuizRemark = useCallback(
    _.debounce(
      (value, resource, session, report_track, activeQuizGroupData, group_name) =>
        handleQuizRemarkUpdate(value, resource, session, report_track, activeQuizGroupData, group_name),
      1000
    ),
    [quizGroupData]
  )

  function handleQuizRemarkUpdate(value, resource_id, session, report_track, cs_report_card_id, group_name) {
    let formData = new FormData()
    let resource = resource_id
    let breakout_session = session

    formData.append("remark", value)
    formData.append("resource", resource)
    formData.append("breakout_session", breakout_session)
    formData.append("group_id", report_track.group_id)
    formData.append("group_name", group_name)
    formData.append("quiz_version", report_track.quiz_version)
    updateQuizGroupReportCard(formData, cs_report_card_id)
  }
  function openQuizReportModal(item) {
    setActiveQuizGroupData(item.report_card)
    setActiveQuizGroup(item)
    setQuizActivitiesModal(true)
  }
  function fetchQuizGroupReport(cs_report_card_id, res_id) {
    let fetchData = { ...quizGroupDataFetched }
    if (fetchData[`quiz_${cs_report_card_id}`] === undefined) {
      fetchData[`quiz_${cs_report_card_id}`] = false
    }
    // setQuizGroupDataFetched(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/quiz-group-report-card/${cs_report_card_id}/report_card_data/?limit=${quizFetchLimit}`, {
        headers: login_credentials,
      })
      .then((res) => {
        let data = { ...quizGroupData }
        let expandData = { ...quizGroupDataExpandData }
        let classAverage = { ...quizGroupClassAverage }
        classAverage[`res_${res_id}`] = res.data.class_average
        if (!fetchData[`quiz_${cs_report_card_id}`]) {
          expandData[`quiz_${cs_report_card_id}`] = Array(res.data.count).fill(true)
          fetchData[`quiz_${cs_report_card_id}`] = true
        }
        data[`quiz_${cs_report_card_id}`] = res.data
        setQuizGroupClassAverage(classAverage)
        setQuizGroupData(data)
        setQuizGroupDataExpandData(expandData)
        setQuizGroupDataFetched(fetchData)
      })
  }
  function fetchNextQuizReport(next_url) {
    setQuizGroupDataLoader(true)
    axios
      .get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials })
      .then((res) => {
        let data = { ...quizGroupData }
        data["quiz_" + activeQuizGroupData].results = [...data["quiz_" + activeQuizGroupData].results, ...res.data.results]
        data["quiz_" + activeQuizGroupData].next = res.data.next
        setQuizGroupData(data)
        setQuizFetchLimit(quizGroupData["quiz_" + activeQuizGroupData].results.length)
        setQuizGroupDataLoader(false)
      })
      .catch((error) => {
        setQuizGroupDataLoader(false)
      })
  }

  useEffect(() => {
    if (activeQuizGroupData !== null) {
      if (quizGroupData[`quiz_${activeQuizGroupData}`] === undefined || quizGroupData[`quiz_${activeQuizGroupData}`] === null) {
        fetchQuizGroupReport(activeQuizGroupData, activeActivityResource?.id)
      }
      if (quizGroupStudentList[`quiz_${activeQuizGroupData}`] === undefined || quizGroupStudentList[`quiz_${activeQuizGroupData}`] === null) {
        fetchQuizGroupMemberList(activeQuizGroupData)
      }
    }
  }, [activeQuizGroupData])

  function fetchQuizGroupMemberList(cs_report_card_id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/quiz-group-report-card/${cs_report_card_id}/students_list/?limit=15`, { headers: login_credentials })
      .then((res) => {
        let data = { ...quizGroupStudentList }
        data[`quiz_${cs_report_card_id}`] = res.data
        setQuizGroupStudentList(data)
      })
  }

  function fetchQuizGroupMemberListNext(next_url) {
    setQuizGroupStudentListLoader(true)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let data = { ...quizGroupStudentList }
      let currentData = data[`quiz_${activeQuizGroupData}`]
      currentData.results = [...currentData.results, ...res.data.results]
      currentData.next = res.data.next
      data[`quiz_${activeQuizGroupData}`] = currentData
      setQuizGroupStudentList(data)
      setQuizGroupStudentListLoader(true)
    })
  }
  function publishQuizReport(cs_report_card_id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/quiz-group-report-card/${cs_report_card_id}/submit_group_report/?publish=true`, {
        headers: login_credentials,
      })
      .then((res) => {
        let data = { ...quizGroupData }
        data[`quiz_${cs_report_card_id}`].report_track = {
          ...data[`quiz_${cs_report_card_id}`].report_track,
          published: res.data.published,
          current_status: res.data.current_status,
        }
        setQuizGroupData(data)
        setPublishMsg(true)
      })
  }

  function expandQuizToggle(id, index) {
    let data = { ...quizGroupDataExpandData }
    data[`quiz_${id}`][index] = !data[`quiz_${id}`][index]
    setQuizGroupDataExpandData(data)
  }

  function showQuestionsQuiz() {
    let alphabets = "ABCDEFGHIJKLMNOPQRSTUWXYZ"
    let csReport = quizGroupData["quiz_" + activeQuizGroupData]?.report_track
    if (quizGroupData && quizGroupData["quiz_" + activeQuizGroupData] && quizGroupData["quiz_" + activeQuizGroupData].results.length > 0) {
      return (
        <>
          {quizGroupData["quiz_" + activeQuizGroupData].results.map((data, index) => {
            let expand = false
            if (quizGroupDataExpandData[`quiz_${activeQuizGroupData}`]) {
              expand = quizGroupDataExpandData[`quiz_${activeQuizGroupData}`][index]
            }
            if (data.question_type === "2") {
              return (
                <div key={`csquestion${index}`} className={`d-flex mb-3`}>
                  <div
                    className={`font-weight-bold text-center`}
                    style={{
                      fontSize: "32px",
                      color: "#9BA1C4",
                      width: "45px",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div className={`inner-element-box-shadow w-100 ml-2 fs-16px ${Style.cs_ques_box}`}>
                    <div className={`d-flex justify-content-between m-3`}>
                      <div className={`w-100 d-flex flex-column mr-3`}>
                        <div className={`p-2 ${expand ? "mb-2 pl-0" : `${Style.cs_ques_box}`} `} style={{ backgroundColor: expand ? "" : "#42506C" }}>
                          {data?.title}
                        </div>
                        {expand ? (
                          <React.Fragment>
                            {data?.answer !== "" ? (
                              <div className={`mt-1 mb-3`}>
                                {/*<div style={{opacity: '60%'}}>Answers</div>*/}
                                <div className={`w-100 p-2 ${Style.cs_ques_box}`} style={{ backgroundColor: "#42506C" }}>
                                  <p
                                    className={`mb-0`}
                                    dangerouslySetInnerHTML={{
                                      __html: data?.answer,
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className={`mt-1`}>
                                <div style={{ opacity: "70%" }}>No Answers yet</div>
                              </div>
                            )}
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className={`${expand ? "pt-2" : "align-self-center"}`}>
                        <FontAwesomeIcon
                          onClick={(e) => {
                            expandQuizToggle(activeQuizGroupData, index)
                          }}
                          className={`pointer`}
                          transform={`grow-4`}
                          icon={expand ? faChevronCircleUp : faChevronCircleDown}
                        />
                      </div>
                    </div>
                    {data?.answer_map !== null && csReport?.grading && (
                      <React.Fragment>
                        <div className={`d-flex align-items-center px-2 py-3`} style={{ borderTop: "1px solid #6b7894" }}>
                          <div>Score : </div>
                          <div className={`mx-2`}>
                            <input
                              type="number"
                              min="0"
                              max={data?.answer_map?.total_marks}
                              defaultValue={data?.answer_map === null ? 0 : parseInt(data?.answer_map?.marks_gained)}
                              onChange={(e) => {
                                updateGradeGroupActivityQuiz(e, data?.answer_map, data?.id, index)
                              }}
                              className={`${StylesA.session_input_field} mx-auto form-control shadow-none text-center px-2 ${StylesA.border_on_hover}`}
                              style={{
                                width: "60px",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            />
                          </div>
                          <div>Out of {data?.answer_map?.total_marks} marks </div>
                        </div>
                        {errorMsg !== null && errorMsg?.id === data?.answer_map?.id && (
                          <div className={`mb-2 px-0 `}>
                            <div className="d-inline-flex px-2 py-0" style={{ borderRadius: "23px" }}>
                              <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "14px",
                                  color: "#F4F411",
                                }}
                              >
                                {errorMsg?.msg}
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )
            } else if (data.question_type === "1") {
              return (
                <div key={`csquestion${index}`} className={`d-flex mb-3`}>
                  <div
                    className={`font-weight-bold text-center`}
                    style={{
                      fontSize: "32px",
                      color: "#9BA1C4",
                      width: "45px",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div className={`inner-element-box-shadow w-100 ml-2 fs-16px ${Style.cs_ques_box}`}>
                    <div className={`d-flex justify-content-between m-3`}>
                      <div className={`w-100 d-flex flex-column mr-3`}>
                        <div className={`p-2 ${expand ? "mb-2" : ""} ${Style.cs_ques_box}`} style={{ backgroundColor: "#42506C" }}>
                          {data?.title}
                        </div>
                        {expand ? (
                          <React.Fragment>
                            {data?.options.map((ans, i) => (
                              <div key={`csans${i}`} className={`d-flex align-items-center my-1`}>
                                <div className={`ml-2`}>
                                  <FontAwesomeIcon
                                    style={{
                                      opacity: ans.is_selected ? "1" : "0",
                                    }}
                                    icon={faPlay}
                                  />
                                </div>
                                <div
                                  className={`font-weight-bold mx-2`}
                                  style={{
                                    fontSize: "20px",
                                    color: "#9BA1C4",
                                  }}
                                >
                                  {alphabets[i]}
                                </div>
                                <div
                                  className={`flex-grow-1 p-2 ${Style.cs_ques_box}`}
                                  style={{
                                    backgroundColor: ans.is_correct ? "#197C7C" : "#42506C",
                                  }}
                                >
                                  {ans?.name}
                                </div>
                              </div>
                            ))}
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className={`${expand ? "pt-2" : "align-self-center"}`}>
                        <FontAwesomeIcon
                          onClick={(e) => {
                            expandQuizToggle(activeQuizGroupData, index)
                          }}
                          className={`pointer`}
                          transform={`grow-4`}
                          icon={expand ? faChevronCircleUp : faChevronCircleDown}
                        />
                      </div>
                    </div>
                    {expand && data?.answer_map !== null && csReport?.grading ? (
                      <div className={`d-flex align-items-center font-weight-bold px-2 py-3 mt-3`} style={{ borderTop: "1px solid #6b7894" }}>
                        <div>Score : </div>
                        <div className={`mx-1`}>
                          {" "}
                          {data?.answer_map?.marks_gained}/{data?.answer_map?.total_marks} marks
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}
          {quizGroupData["quiz_" + activeQuizGroupData].next !== null && (
            <div className="ml-3 mb-3">
              <Button
                variant={`none`}
                disabled={quizGroupDataLoader}
                className={`${StylesA.add_button} ${StylesA.border_on_hover}`}
                onClick={() => {
                  fetchNextQuizReport(quizGroupData["quiz_" + activeQuizGroupData].next)
                }}
              >
                See more
              </Button>
            </div>
          )}
        </>
      )
    } else {
      return null
    }
  }

  function showQuizReports() {
    let data = quizGroupData["quiz_" + activeQuizGroupData]
    let results = "Ongoing "
    let status = data?.report_track?.current_status
    let avg = quizGroupClassAverage[`res_${activeActivityResource?.id}`]
    if (status === "2") {
      let perc = (data?.report_track?.score / data?.report_track?.total_score) * 100
      if (perc >= data?.report_track?.passing_marks) {
        results = "Passed"
      } else {
        results = "Fail"
      }
    }
    return (
      <div className={`d-flex mb-4 justify-content-between`}>
        <ReportBox header={"Total Attempts"} footer={avg !== null ? `Class Avg. : ${avg?.avg_attempts}` : null}>
          <div className={`font-weight-bolder flex-fill py-4`}>{data?.report_track?.total_attempts}</div>
        </ReportBox>
        <ReportBox header={"Results"} footer={avg !== null ? `Class Success Rate : ${avg?.is_class_pass_percentage}%` : null}>
          <div className={`font-weight-bolder flex-fill py-4 ${results === "Fail" && "text-danger"}`}>{results}</div>
        </ReportBox>
        <ReportBox
          header={`${status === "2" ? "Final" : "Current"} Score`}
          footer={avg !== null ? `Class Avg. : ${avg?.avg_marks} (${avg?.avg_marks_percent}%)` : null}
        >
          <div className={`font-weight-bolder flex-fill py-4`}>
            {data?.report_track?.score}/{data?.report_track?.total_score}
          </div>
        </ReportBox>
        <ReportBox header={"Time Taken"} footer={avg !== null ? `Class Avg : ${convertToTime(avg?.time_taken)}` : null}>
          <div className={`font-weight-bolder flex-fill py-4`}>{convertToTime(data?.report_track?.time_taken)}</div>
        </ReportBox>
      </div>
    )
  }

  function updateGradeGroupActivityQuiz(e, answer_map, question, index) {
    let marks = e.target.value
    if (marks > answer_map?.total_marks || marks < 0) {
      setErrorMsg({
        msg: `Please enter marks between 0 and ${answer_map?.total_marks}`,
        id: answer_map?.id,
      })
    } else {
      setErrorMsg(null)
      if (marks == "") {
        marks = 0
      }
      let formData = new FormData()
      formData.append("marks_gained", marks)
      formData.append("question", question)
      formData.append("group_report_card", activeQuizGroupData)
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/quiz-group-answer-submit/${answer_map?.id}/`, formData, { headers: login_credentials })
        .then((res) => {
          let data = { ...quizGroupData }
          data[`quiz_${activeQuizGroupData}`].results[index].answer_map.marks_gained = res.data.marks_gained
          setQuizGroupData(data)
        })
    }
  }

  function quizModalActivities() {
    return (
      <Modal
        show={quizActivitiesModal}
        onHide={() => {
          setQuizActivitiesModal(false)
          setActiveQuizGroupData(null)
          setActiveQuizGroup({})
          setActiveRemarkText("")
          setErrorMsg(null)
        }}
        onShow={() => {
          //fetch case study report
        }}
        size="lg"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName={`${Style.breakout_activity_modal_dialog}`}
        contentClassName={`${Style.breakout_activity_modal}`}
        scrollable={true}
      >
        <Modal.Header className={`p-2`} style={{ borderBottom: "1px solid #929BBD" }}>
          <div className={`w-100 d-flex justify-content-between`}>
            <div className={`d-flex fs-16px align-items-center`}>
              <div>{activeActivityResource?.resource_data?.name}</div>
              <div>
                {activeQuizGroupData && quizGroupStudentList[`quiz_${activeQuizGroupData}`] && (
                  <StudentListDropdown
                    title={activeQuizGroup?.title}
                    studentListData={quizGroupStudentList[`quiz_${activeQuizGroupData}`]}
                    fetchGroupMemberListNext={{
                      fetchQuizGroupMemberListNext,
                    }}
                    type={"quiz"}
                  />
                )}{" "}
              </div>
              {/*<div><FontAwesomeIcon onClick={()=>{fetchQuizGroupReport(activeQuizGroupData)}} className={`pointer`} icon={faRedo}/></div>*/}
            </div>
            <div>
              <FontAwesomeIcon
                className={`pointer`}
                onClick={() => {
                  setQuizActivitiesModal(false)
                }}
                icon={faTimesCircle}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          onScroll={(e) => {
            e.stopPropagation()
          }}
        >
          <div className={`d-flex flex-column`}>
            {activeQuizGroupData && quizGroupData && quizGroupData["quiz_" + activeQuizGroupData]?.report_track?.grading ? showQuizReports() : null}
            {showQuestionsQuiz()}
            {activeQuizGroupData && quizGroupData["quiz_" + activeQuizGroupData]?.report_track?.current_status === "2" ? (
              <div>
                <div className={` ${Style.cs_ques_box} ml-4 p-2`}>
                  <div style={{ opacity: "0.6" }} className={`fs-14px font-weight-bold`}>
                    Add Remarks
                  </div>
                  <textarea
                    defaultValue={quizGroupData["quiz_" + activeQuizGroupData]?.report_track?.remark}
                    className={`${Styles.font_16} ${Style.text_area} p-2 border-none`}
                    onChange={(e) => {
                      setActiveRemarkText(e.target.value)
                      autoSaveQuizRemark(
                        e.target.value,
                        activeActivityResource?.id,
                        activeBreakoutActivity.breakout_session,
                        quizGroupData["quiz_" + activeQuizGroupData]?.report_track,
                        activeQuizGroupData,
                        activeQuizGroup.title
                      )
                    }}
                  />
                </div>
                <div className={`ml-4 mt-2 d-inline-flex`}>
                  {activeQuizGroupData && quizGroupData["quiz_" + activeQuizGroupData]?.report_track?.published ? (
                    <Button
                      onClick={() => {
                        setQuizActivitiesModal(false)
                      }}
                      className={`${StylesA.add_button_large} ${StylesA.border_on_hover}`}
                      variant="none"
                    >
                      Done
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        publishQuizReport(activeQuizGroupData)
                      }}
                      className={`${StylesA.add_button_large} ${StylesA.border_on_hover}`}
                      variant="none"
                    >
                      Publish
                    </Button>
                  )}
                  {publishMsg && (
                    <div className={`mb-0 ml-3 fs-12px align-self-center`}>
                      <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                      All changes are saved
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
  // Quiz group moderation code end.

  async function fetchActivityWiseProgressData() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/breakoutgroups-activity-progress/${activeBreakoutActivity.id}/`, { headers: login_credentials })
      .then((res) => {
        setActivityWiseBreakoutGroupsProgress(res.data.results)
        setActivityWiseProgressFetched(true)
      })
      .catch((err) => {})
  }

  function fetchActivityWiseCaseStudyGroupList() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/case-study-group-report-card/${activeBreakoutSession?.id}/groups_list/?breakout_activity=${activeBreakoutActivity.id}&resource=${activeActivityResource?.id}&limit=10`,
        { headers: login_credentials }
      )
      .then((res) => {
        let data = { ...activityWiseCaseStudyGroups }
        let fetchData = { ...activityWiseCaseStudyGroupsFetched }
        let key = `res_${activeActivityResource?.id}`
        data[key] = res.data
        fetchData[key] = true
        setActivityWiseCaseStudyGroups(data)
        setActivityWiseCaseStudyGroupsFetched(fetchData)
      })
      .catch((err) => {})
  }

  function fetchActivityWiseChecklistGroupList() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/checklist/group/report_card/list_report_cards/?resource_id=${activeActivityResource?.id}&breakout_activity=${activeBreakoutActivity.id}&limit=10`,
        { headers: login_credentials }
      )
      .then((res) => {
        let data = { ...activityWiseChecklistGroups }
        let fetchData = { ...activityWiseChecklistGroupsFetched }
        let key = `res_${activeActivityResource?.id}`
        data[key] = res.data
        fetchData[key] = true
        setActivityWiseChecklistGroups(data)
        setActivityWiseChecklistGroupsFetched(fetchData)
      })
      .catch((err) => {})
  }

  function fetchActivityWiseMTFGroupList() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/match-the-followings/reportcard/breakout_group_reports/?breakout_activity=${activeBreakoutActivity?.id}&resource=${activeActivityResource?.id}`,
        { headers: login_credentials }
      )
      .then((res) => {
        let data = { ...activityWiseMTFGroups }
        let fetchData = { ...activityWiseMTFGroupsFetched }
        let key = `res_${activeActivityResource?.id}`
        data[key] = res.data
        fetchData[key] = true
        setActivityWiseMTFGroups(data)
        setActivityWiseMTFGroupsFetched(fetchData)
      })
      .catch((err) => {})
  }

  function updateGroupReportCard(formData, cs_report_card_id) {
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/case-study-group-report-card/${cs_report_card_id}/`, formData, { headers: login_credentials })
      .then((res) => {
        //update states here
        let data = { ...caseStudyGroupData }
        data["cs_" + cs_report_card_id].report_track.remark = res?.data?.remark
        setCaseStudyGroupData(data)
      })
  }

  const autoSaveRemark = useCallback(
    _.debounce(
      (value, resource, session, group_id, activeCaseStudyGroupData, group_name) =>
        handleCaseStudyRemarkUpdate(value, resource, session, group_id, activeCaseStudyGroupData, group_name),
      1000
    ),
    [caseStudyGroupData]
  )

  function handleCaseStudyRemarkUpdate(value, resource_id, session, groupid, cs_report_card_id, group_name) {
    let formData = new FormData()
    let resource = resource_id
    let breakout_session = session
    let group_id = groupid

    formData.append("remark", value)
    formData.append("group_name", group_name)
    formData.append("resource", resource)
    formData.append("breakout_session", breakout_session)
    formData.append("group_id", group_id)
    updateGroupReportCard(formData, cs_report_card_id)
  }

  function openCSReportModal(item) {
    setActiveCaseStudyGroupData(item.report_card)
    setActiveCaseStudyGroup(item)
    setCaseStudyActivitiesModal(true)
  }

  function fetchCaseStudyGroupReport(cs_report_card_id, res_id) {
    let fetchData = { ...caseStudyGroupDataFetched }
    if (fetchData[`cs_${cs_report_card_id}`] === undefined) {
      fetchData[`cs_${cs_report_card_id}`] = false
    }
    // setCaseStudyGroupDataFetched(false)
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/case-study-group-report-card/${cs_report_card_id}/report_card_data/?limit=${caseStudyFetchLimit}`, {
        headers: login_credentials,
      })
      .then((res) => {
        let data = { ...caseStudyGroupData }
        let expandData = { ...caseStudyGroupDataExpandData }
        let classAverage = { ...caseStudyGroupClassAverage }
        classAverage[`res_${res_id}`] = res.data.class_average
        if (!fetchData[`cs_${cs_report_card_id}`]) {
          expandData[`cs_${cs_report_card_id}`] = Array(res.data.count).fill(true)
          fetchData[`cs_${cs_report_card_id}`] = true
        }
        data[`cs_${cs_report_card_id}`] = res.data
        setCaseStudyGroupClassAverage(classAverage)
        setCaseStudyGroupData(data)
        setCaseStudyGroupDataExpandData(expandData)
        setCaseStudyGroupDataFetched(fetchData)
      })
  }

  function fetchNextCaseStudyReport(next_url) {
    setCaseStudyGroupDataLoader(true)
    axios
      .get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials })
      .then((res) => {
        let data = { ...caseStudyGroupData }
        data["cs_" + activeCaseStudyGroupData].results = [...data["cs_" + activeCaseStudyGroupData].results, ...res.data.results]
        data["cs_" + activeCaseStudyGroupData].next = res.data.next
        setCaseStudyGroupData(data)
        setCaseStudyFetchLimit(caseStudyGroupData["cs_" + activeCaseStudyGroupData].results.length)
        setCaseStudyGroupDataLoader(false)
      })
      .catch((error) => {
        setCaseStudyGroupDataLoader(false)
      })
  }

  useEffect(() => {
    if (activeCaseStudyGroupData !== null) {
      if (caseStudyGroupData[`cs_${activeCaseStudyGroupData}`] === undefined || caseStudyGroupData[`cs_${activeCaseStudyGroupData}`] === null) {
        fetchCaseStudyGroupReport(activeCaseStudyGroupData, activeActivityResource?.id)
      }
      if (caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`] === undefined || caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`] === null) {
        fetchCaseStudyGroupMemberList(activeCaseStudyGroupData)
      }
    }
  }, [activeCaseStudyGroupData])

  function fetchCaseStudyGroupMemberList(cs_report_card_id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/case-study-group-report-card/${cs_report_card_id}/students_list/?limit=15`, { headers: login_credentials })
      .then((res) => {
        let data = { ...caseStudyGroupStudentList }
        data[`cs_${cs_report_card_id}`] = res.data
        setCaseStudyGroupStudentList(data)
      })
  }

  function fetchCaseStudyGroupMemberListNext(next_url) {
    setCaseStudyGroupStudentListLoader(true)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let data = { ...caseStudyGroupStudentList }
      let currentData = data[`cs_${activeCaseStudyGroupData}`]
      currentData.results = [...currentData.results, ...res.data.results]
      currentData.next = res.data.next
      data[`cs_${activeCaseStudyGroupData}`] = currentData
      setCaseStudyGroupStudentList(data)
      setCaseStudyGroupStudentListLoader(true)
    })
  }

  //Group Checklist Student List
  useEffect(() => {
    if (activeChecklistGroupData !== null) {
      if (checklistGroupStudentList[`ck_${activeChecklistGroupData}`] === undefined || checklistGroupStudentList[`ck_${activeChecklistGroupData}`] === null) {
        fetchChecklistGroupMemberList(activeChecklistGroupData)
      }
    }
  }, [activeChecklistGroupData])

  function fetchChecklistGroupMemberList(ck_report_card_id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/checklist/group/report_card/${ck_report_card_id}/student_list/?limit=2`, { headers: login_credentials })
      .then((res) => {
        let data = { ...checklistGroupStudentList }
        data[`ck_${ck_report_card_id}`] = res.data
        setChecklistGroupStudentList(data)
      })
  }

  function fetchChecklistGroupMemberListNext(next_url) {
    setChecklistGroupStudentListLoader(true)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let data = { ...checklistGroupStudentList }
      let currentData = data[`ck_${activeChecklistGroupData}`]
      currentData.results = [...currentData.results, ...res.data.results]
      currentData.next = res.data.next
      data[`ck_${activeChecklistGroupData}`] = currentData
      setChecklistGroupStudentList(data)
      setChecklistGroupStudentListLoader(true)
    })
  }

  useEffect(() => {
    if (activeBreakoutActivity && activeBreakoutActivity.id) {
      setActivityWiseProgressFetched(false)
      fetchActivityWiseProgressData()
      let key = `act_${activeBreakoutActivity?.id}`
      if (activityResources && activityResources[key] === undefined) {
        fetchActivityWiseResources()
      } else if (activityResources && activityResources[key] && activityResources[key].length > 0) {
        setActiveActivityResource(activityResources[key][0])
      }
    }
  }, [activeBreakoutActivity])

  useEffect(() => {
    if (activeActivityResource && activeActivityResource.resource_type === "casestudy") {
      let key = `res_${activeActivityResource?.id}`
      if (activityWiseCaseStudyGroupsFetched[key] === undefined) {
        fetchActivityWiseCaseStudyGroupList()
      }
    }
    if (activeActivityResource && activeActivityResource.resource_type === "checklist") {
      //fetch checklist  group list
      let key = `res_${activeActivityResource?.id}`
      if (activityWiseChecklistGroupsFetched[key] === undefined) {
        fetchActivityWiseChecklistGroupList()
      }
    }
    if (activeActivityResource && activeActivityResource.resource_type === "quiz") {
      let key = `res_${activeActivityResource?.id}`
      if (activityWiseQuizGroupsFetched[key] === undefined) {
        fetchActivityWiseQuizGroupList()
      }
    }
    if (activeActivityResource && activeActivityResource.resource_type === "matchthefollowing") {
      let key = `res_${activeActivityResource?.id}`
      if (activityWiseMTFGroupsFetched[key] === undefined) {
        fetchActivityWiseMTFGroupList()
      }
    }
  }, [activeActivityResource])

  function openChecklistReportModal(item) {
    setActiveChecklistGroupData(item.report_card)
    setChecklistActivitiesModal(true)
  }

  function openMTFReportModal(item) {
    setActiveMTFGroupData(item.report_card)
    setActiveMTFGroup(item)
    setShowViewDetailsModal(true)
  }

  function fetchReplyResources(replyId, refetch = false) {
    if (replyId && replyId <= 0) return
    if (props?.trainingWsConnection) {
      SocketConnection()
    }
    if (refetch === true && repliesResources["questionReply_" + replyId] === undefined) return

    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?reply__id=${replyId}&paginate_by=8`, { headers: login_credentials })
      .then((res) => {
        let replyRes = { ...repliesResources }
        let replyResNextUrl = { ...repliesResourcesNextUrl }
        replyRes["questionReply_" + replyId] = res.data.results
        replyResNextUrl["questionReply_" + replyId] = res.data.next
        setRepliesResources(replyRes)
        setRepliesResourcesNextUrl(replyResNextUrl)
      })
  }
  function fetchNextReplyResources(next_url, replyId) {
    if ((next_url && next_url === "") || replyId <= 0) return

    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let replyRes = { ...repliesResources }
      let replyResNextUrl = { ...repliesResourcesNextUrl }
      replyResNextUrl["questionReply_" + replyId] = res.data.next

      replyRes["questionReply_" + replyId] = [...replyRes["questionReply_" + replyId], ...res.data.results]

      setRepliesResources(replyRes)
      setRepliesResourcesNextUrl(replyResNextUrl)
    })
  }

  function fetchActivityWiseResources() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST + `/resources-names-list/?breakout_activity__id=${activeBreakoutActivity?.id}&paginate_by=8&is_dropdown=true`,
        { headers: login_credentials }
      )
      .then((res) => {
        let key = `act_${activeBreakoutActivity?.id}`
        let actRes = { ...activityResources }
        let actResNextUrl = { ...activityResourcesNextUrl }
        actRes[key] = res.data.results
        actResNextUrl[key] = res.data.next
        setActivityResourcesFetched(true)
        setActivityResources(actRes)
        setActivityResourcesNextUrl(actResNextUrl)
        if (res.data.results.length > 0) {
          setActiveActivityResource(res.data.results[0])
        } else {
          setActiveActivityResource(null)
        }
      })
  }

  function fetchNextActivityWiseResources(next_url) {
    setActivityResourcesLoader(true)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let key = `act_${activeBreakoutActivity?.id}`
      let actRes = { ...activityResources }
      let actResNextUrl = { ...activityResourcesNextUrl }
      actResNextUrl[key] = res.data.next
      setActivityResourcesNextUrl(actResNextUrl)
      let currentActivityData = actRes[key]
      currentActivityData = [...currentActivityData, ...res.data.results]
      actRes[key] = currentActivityData
      setActivityResources(actRes)
      setActivityResourcesLoader(false)
    })
  }

  function handleActivityResourcesScroll(e) {
    e.stopPropagation()
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5 &&
      activityResourcesNextUrl[`act_${activeBreakoutActivity?.id}`] != null &&
      !activityResourcesLoader
    ) {
      fetchNextActivityWiseResources(activityResourcesNextUrl[`act_${activeBreakoutActivity?.id}`])
    }
  }

  function selectActivityFilter() {
    return (
      <div
        className={`mb-2`}
        style={{
          backgroundColor: "#42506C",
          borderRadius: "9px",
          border: "1px solid white",
        }}
      >
        {isBreakoutActivitiesFetched ? (
          breakoutActivities && breakoutActivities.length > 0 ? (
            <React.Fragment>
              <div
                className={`w-100 pl-2 py-1 pr-3 pointer d-inline-flex align-items-center position-relative`}
                onClick={() => setShowBreakoutActivities(!showBreakoutActivities)}
              >
                <div>
                  <p className={`mb-1 poppins_font`} style={{ fontSize: "14px", fontWeight: "500" }}>
                    Select Activity
                  </p>
                  <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
                    {activeBreakoutActivity ? activeBreakoutActivity.name : null}
                  </p>
                </div>
                <div className={`ml-auto`}>
                  <FontAwesomeIcon
                    className={`pointer`}
                    icon={showBreakoutActivities ? faAngleUp : faAngleDown}
                    color="#E0E2E5"
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              </div>
              <div className={`px-2`} style={{}}>
                {showBreakoutActivities ? (
                  <div className={`d-inline-block w-100 align-items-center `} style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {breakoutActivities.map((activity, index) => {
                      return (
                        <div
                          key={`activity_${index}`}
                          className={`mb-2 px-3 py-2 pointer`}
                          onClick={() => {
                            setShowBreakoutActivities(false)
                            setActiveBreakoutActivity(activity)
                          }}
                          style={{
                            backgroundColor: "#4F6080",
                            borderRadius: "9px",
                          }}
                        >
                          <p className={`mb-0`}>{activity.name}</p>
                        </div>
                      )
                    })}
                    {breakoutActivitiesNextUrl !== null && (
                      <div className="ml-3 mb-3">
                        <Button
                          variant={`none`}
                          disabled={!breakoutActivitiesNextFetched}
                          className={`${StylesA.add_button} ${StylesA.border_on_hover}`}
                          onClick={() => {
                            fetchBreakoutActivitiesNext(breakoutActivitiesNextUrl)
                          }}
                        >
                          See more
                        </Button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : (
            <div className={`px-3 text-center`}>No Activities Found</div>
          )
        ) : (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <Spinner animation="border" variant="secondary" />
          </Col>
        )}
      </div>
    )
  }

  function selectResourceFilter() {
    if (activeActivityResource !== null) {
      let key = `act_${activeBreakoutActivity?.id}`
      let activeImg = getImageToBeRendered(activeActivityResource)
      if (activityResourcesFetched) {
        if (activityResources && activityResources[key] && activityResources[key].length > 0) {
          return (
            <div
              className={`mb-2`}
              style={{
                backgroundColor: "#42506C",
                borderRadius: "9px",
                border: "1px solid white",
              }}
            >
              <div
                className={`w-100 pl-2 py-1 pr-3 pointer d-inline-flex align-items-center position-relative`}
                onClick={() => setShowActivityResources(!showActivityResources)}
              >
                <div>
                  <p className={`mb-1 poppins_font`} style={{ fontSize: "14px", fontWeight: "500" }}>
                    Select Resource
                  </p>
                  <div className={`d-flex`}>
                    <div>
                      <img
                        alt={`image_${activeImg.imgType}`}
                        src={activeImg.imgObj}
                        className={`mb-0`}
                        style={{
                          height: "25px",
                          width: "35px",
                          contain: "content",
                        }}
                      />
                    </div>
                    <div className={`ml-2 poppins_font`}>{activeActivityResource ? activeActivityResource?.resource_data?.name : null}</div>
                  </div>
                </div>
                <div className={`ml-auto`}>
                  <FontAwesomeIcon
                    className={`pointer`}
                    icon={showActivityResources ? faAngleUp : faAngleDown}
                    color="#E0E2E5"
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              </div>
              <div className={`px-2`} style={{}}>
                {showActivityResources ? (
                  <div
                    className={`d-inline-block w-100 align-items-center`}
                    onScroll={handleActivityResourcesScroll}
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {activityResources[key].map((resource, index) => {
                      let image = getImageToBeRendered(resource)
                      return (
                        <div
                          key={`resource_${index}`}
                          className={`mb-2 px-3 py-2 pointer d-flex`}
                          onClick={() => {
                            setShowActivityResources(false)
                            setActiveActivityResource(resource)
                          }}
                          style={{
                            backgroundColor: "#4F6080",
                            borderRadius: "9px",
                          }}
                        >
                          <div>
                            <img
                              alt={`image_${image.imgType}`}
                              src={image.imgObj}
                              className={`mb-0`}
                              style={{
                                height: "25px",
                                width: "35px",
                                contain: "content",
                              }}
                            />
                          </div>
                          <div className={`ml-2`}>{resource?.resource_data?.name}</div>
                        </div>
                      )
                    })}
                    {activityResourcesLoader && <Loader />}
                  </div>
                ) : null}
              </div>
            </div>
          )
        } else {
          return null
        }
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <Spinner animation="border" variant="secondary" />
          </Col>
        )
      }
    } else {
      return null
    }
  }

  function getSharedResources() {
    if (showInstructions) {
      if (sessionInstructionsFetched) {
        return (
          <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
            {sessionInstructionsList.map((item, index) => {
              return (
                <div key={`resource_${index}`}>
                  <p className={`mb-2 poppins_font`} style={{ fontSize: "14px" }}>
                    Shared : {utcDateTime.utcDateTime(item.created, "time")}
                  </p>
                  <div style={{ fontSize: "16px", color: "#E0E2E5" }} dangerouslySetInnerHTML={{ __html: item.title }} />
                </div>
              )
            })}
          </div>
        )
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <Spinner animation="border" variant="secondary" />
          </Col>
        )
      }
    } else {
      if (sharedResources && sharedResources[`as_${activeBreakoutSession?.id}`]) {
        return (
          <div onScroll={handleSharedResourcesScroll} style={{ maxHeight: "400px", overflowY: "scroll" }}>
            {sharedResources[`as_${activeBreakoutSession?.id}`].map((item, index) => {
              if (item?.resources.length > 0) {
                return (
                  <React.Fragment>
                    <p className={`mb-2 poppins_font`} style={{ fontSize: "14px" }}>
                      {item?.resources.length} resources shared : {utcDateTime.utcDateTime(item?.updated_at, "time")}
                    </p>
                    {item.resources.map((res, index) => {
                      return (
                        <div
                          key={`sharedRes${index}`}
                          className={`w-100 py-2 mb-2 align-items-center d-inline-flex`}
                          style={{
                            backgroundColor: "#4F6080",
                            borderRadius: "9px",
                            boxShadow: "0px 3px 6px #00000029",
                          }}
                        >
                          <img className="mb-0" style={{ height: "30px", width: "80px" }} src={BreakoutSessionIcon} />
                          <p className={`mb-0 poppins_font`} style={{ fontSize: "14px" }}>
                            {res?.resource_data?.name}
                          </p>
                          {/*<p className={`mb-0 ml-auto pr-3 poppins_font`} style={{fontSize:'12px'}}>23 Min</p>*/}
                        </div>
                      )
                    })}
                  </React.Fragment>
                )
              }
            })}
          </div>
        )
      }
    }
  }

  function onDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  function onDrop(event, id = 0, seq = 0) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
    handleDrop(event)
    setShowSection("video")
    if (id > 0) setUploadInTopic(id)
    if (seq > 0) setUploadDataSequence(seq)
  }

  function handleDrop(e) {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg( new_files[i].name + ' has size more than 200MB.')
        setNotificationData({
          type: "error",
          title: new_files[i].name + " has size more than 200MB.",
        })
      }
    }
    // handleFiles(files)
  }

  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        // hideMessage.displayErrorMsg( new_files[i].name + ' has size more than 200MB.')
        setNotificationData({
          type: "error",
          title: new_files[i].name + " has size more than 200MB.",
        })
      }
    }
  }

  function deleteResource(id) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        setSrResourcesFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()

          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function createResource(source, destination, type, id = 0) {
    const itemSubItemMap = props.allResources

    let newItems = props.allResources[props.currentSelectedResourcesType]

    let newSourceSubItems = itemSubItemMap[props.currentSelectedResourcesType]

    const [draggedItem] = newSourceSubItems.splice(source.index, 1)

    let newDestSubItems = []
    var formData = new FormData()
    if (type === "reply") {
      newDestSubItems = repliesResources[destination.droppableId] ?? []
      let [str, replyId] = destination.droppableId.split("_")
      formData.append("add_to_reply", replyId)
    } else {
      newDestSubItems = currentSharedResources[destination.droppableId].resources
      formData.append("add_to_instruction", currentSharedResources[`active_${activeBreakoutSession.id}`].id)
    }

    let destSubItems = newDestSubItems

    if (destination.index === 0 && destSubItems.length === 0) {
      draggedItem.sequence = destination.index
    } else if (destination.index === 0) {
      if (draggedItem) {
        draggedItem.sequence = (parseFloat(destSubItems[destination.index].sequence) - 10).toFixed(20)
      }
    } else if (destination.index === destSubItems.length) {
      draggedItem.sequence = (parseFloat(destSubItems[destination.index - 1].sequence) + 10).toFixed(20)
    } else {
      draggedItem.sequence = (
        (parseFloat(destSubItems[destination.index].sequence) + parseFloat(destSubItems[parseFloat(destination.index) - 1].sequence)) /
        2
      ).toFixed(20)
    }

    formData.append("sequence", draggedItem.sequence)
    if (props.currentSelectedResourcesType === "poll" || props.currentSelectedResourcesType === "pop-quiz") {
      formData.append("resource_type", "quiz")
    } else {
      if (props.currentSelectedResourcesType === "document") {
        formData.append("resource_type", "turientimage")
      } else {
        formData.append("resource_type", props.currentSelectedResourcesType)
      }
    }
    formData.append("resource_id", draggedItem.id)

    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
      .then((res) => {
        if (res.data) {
          draggedItem.id = res.data.id
          draggedItem.resource_data = res.data.resource_data
          draggedItem.resource_id = res.data.resource_id
          draggedItem.resource_type = res.data.resource_type
          draggedItem.sequence = res.data.sequence
          delete draggedItem["name"]
        }
        setSrResourcesFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
    newDestSubItems.splice(destination.index, 0, draggedItem)
    let temp_data = props.allResources
    temp_data[props.currentSelectedResourcesType] = newItems
    props.setHomepageAllResource(temp_data)
  }

  useEffect(() => {
    if (props.dragEnded && props.draggedItem) {
      setSrResourcesFetched(false)

      onDragEndResources(props.draggedItem)
      props.resetDragState()
    }
  }, [props.dragEnded])

  function handlePollSave(currentItem, value, show_results, topic_id, type, increase_trigger_count = true) {
    if (currentItem) {
      var index = topicsResources[`topic_` + topic_id].findIndex((res) => res.id === currentItem.id)
      var formData = new FormData()
      formData.append("sequence", currentItem.sequence)
      formData.append("resource_id", currentItem.resource_id)
      formData.append("resource_type", currentItem.resource_type)
      formData.append("template", props.location.template_id)
      formData.append("is_triggered", value)
      if (currentItem.resource_type === "quiz" && increase_trigger_count) {
        formData.append("triggered_count", parseInt(currentItem.triggered_count) + 1)
      }
      formData.append("update_version", true)
      formData.append("show_results", show_results)
      if (value || show_results) {
        formData.append("created", new Date().toISOString())
      }
      if (currentItem.resource_data.data_type === "2") {
        formData.append("end_activity", false)
      }
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/resources/` + currentItem.id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          let dummy = { ...topicsResources }
          dummy[`topic_` + topic_id][index] = res.data
          setTopicsResources(dummy)
          if (type === "1") {
            setShowPollModal(true)
          } else if (type === "2") {
            setPopQuizModal(true)
          }

          // triggerResource(currentItem.id)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    } else {
    }
  }

  function handleSaveResource(resource, type) {
    setSrResourcesFetched(false)
    var formData = new FormData()
    formData.append("sequence", resource.sequence)
    formData.append("resource_id", resource.resource_id)
    formData.append("resource_type", resource.resource_type)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/resources/${resource.id}/`, formData, { headers: login_credentials })
      .then((res) => {
        setSrResourcesFetched(true)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function fetchInstructionResources(id) {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/basic-resources-list/?instruction=${currentSharedResources[`active_${activeBreakoutSession.id}`].id}&paginate_by=-1`,
        { headers: login_credentials }
      )
      .then((res) => {
        let current_data = { ...currentSharedResources }
        current_data[`active_` + activeBreakoutSession.id]["resources"] = res.data.results
        setCurrentSharedResources(current_data)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  function onDragEndResources(result) {
    if (!result.destination) {
      return
    }
    const source = result.source
    const destination = result.destination
    if (destination.droppableId === "resource_video") {
      return null
    } else {
      let rc = destination.droppableId.indexOf("questionReply_", 0)
      let obj
      if (rc >= 0) {
        //If these resources are dropped/ dont accept it for Q&A
        switch (source.droppableId) {
          case "resource_quiz":
          case "resource_poll":
          case "resource_pop-quiz":
          case "resource_casestudy":
          case "resource_checklist":
            return
          default:
            break
        }
        obj = repliesResources[destination.droppableId]
      } else {
        if (currentSharedResources && currentSharedResources[destination.droppableId] && currentSharedResources[destination.droppableId]?.resources) {
          obj = currentSharedResources[destination.droppableId]?.resources
        } else {
          return
        }
      }
      if (source.droppableId === destination.droppableId) {
        const items = reorder(obj, source.index, destination.index)
        let currentResource = items[destination.index]
        let currentData = obj
        if (destination.index === 0) {
          currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (destination.index === currentData.length - 1) {
          currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (source.index > destination.index) {
            currentResource.sequence = (
              (parseFloat(currentData[destination.index - 1].sequence) + parseFloat(currentData[destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (source.index < destination.index) {
            currentResource.sequence = (
              (parseFloat(currentData[destination.index].sequence) + parseFloat(currentData[destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        if (rc >= 0) {
          handleSaveResource(currentResource, "reply")
          repliesResources[destination.droppableId] = items
        } else {
          handleSaveResource(currentResource, "resources")
          setTopicsResourcesFetched(!topicsResourcesFetched)
          currentSharedResources[destination.droppableId].resources = items
        }
      } else {
        if (rc >= 0) {
          createResource(source, destination, "reply")
        } else {
          createResource(source, destination, "resources")
        }
      }
    }
  }

  function getFooterData() {
    if (activeFotterBtn === "extend_time") {
      return (
        <div className={`w-100 mb-5 d-inline-flex align-items-center`}>
          <div className={`pr-3`}>
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              Extend Session by
            </p>
            <div className={`d-inline-flex align-items-center`}>
              {/* <InputGroup className="py-1 align-items-center" style={{width:'90px',borderRadius:'9px',backgroundColor:'#303C54', border: '1px solid transparent'}}>
                <InputGroup.Prepend  className='border-0' style={{width:'80px',backgroundColor:'#303C54'}}>
                  <InputGroup.Text  className='border-0 d-flex justify-content-center' style={{backgroundColor:'#303C54',color:'#E0E2E5',borderRadius:'0px 9px 9px 0px',fontSize:'16px'}} id="basic-addon1" >30 Mins</InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
              <p className={`px-1`} style={{fontSize:'16px',fontWeight:'500',margin:'0px'}}>Or</p> */}
              <InputGroup
                className="py-1 align-items-center"
                style={{
                  borderRadius: "9px",
                  width: "270px",
                  backgroundColor: "#303C54",
                  border: "1px solid transparent",
                }}
              >
                <FormControl
                  className={`d-flex border-0 justify-content-center shadow-none pr-1 text-white`}
                  style={{
                    maxWidth: "200px",
                    backgroundColor: "#303C54",
                    fontSize: "16px",
                  }}
                  type="number"
                  name="time"
                  id="time"
                  min="1"
                  defaultValue={extendDurationValue}
                  onChange={(e) => {
                    setExtendDurationValue(e.target.value)
                    setShowNegativeError(false)
                  }}
                  placeholder="Provide custom timings"
                  required
                />
                <InputGroup.Prepend className="border-0" style={{ backgroundColor: "#303C54" }}>
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center"
                    style={{
                      backgroundColor: "#303C54",
                      color: "#E0E2E5",
                      borderRadius: "0px 9px 9px 0px",
                      fontSize: "16px",
                    }}
                    id="basic-addon1"
                  >
                    Mins
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </div>
            {showNegativeError ? (
              <div className="d-inline-flex px-3 py-0" style={{ borderRadius: "23px" }}>
                <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                <p className="mb-0" style={{ fontSize: "14px", color: "#F4F411" }}>
                  Enter valid time (Positive)
                </p>
              </div>
            ) : null}
            <p className={`mb-0 poppins_font`} style={{ fontSize: "14px", color: "#E0E2E570" }}>
              Recommended
            </p>
          </div>
          <div className={`ml-auto mr-2`}>
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              {hourLeft}:{minLeft}:{secLeft} Mins remaining
            </p>
            <p className={`mb-0 poppins_font`} style={{ fontSize: "14px" }}>
              3 Activities to finish
            </p>
            <p className={`mb-0 poppins_font`} style={{ fontSize: "14px" }}>
              <u>Average rate of completion of task : 10 mins</u>
            </p>
          </div>
        </div>
      )
    } else if (activeFotterBtn === "send_instructions") {
      return (
        <div className={`w-100 mb-3 d-inline-flex align-items-center`}>
          <SunEditor
            id="instructions"
            placeholder="Add Instructions"
            onChange={setSessionLevelInstructions}
            setContents={sessionLevelInstructions}
            setOptions={{
              height: "auto",
              maxHeight: "200px",
              minHeight: "200px",
              backgroundColor: "#212C42",
              buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link"]],
            }}
          />
        </div>
      )
    } else if (activeFotterBtn === "share_resources" && currentSharedResources[`active_${activeBreakoutSession.id}`]) {
      let data = currentSharedResources[`active_${activeBreakoutSession.id}`].resources
      return (
        <div className={`w-100 mb-3`}>
          <Droppable droppableId={`active_${activeBreakoutSession.id}`} type={`resourceDraggable`}>
            {(provided, snapshot) => (
              <div
                id={`drop-area`}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                className={`bg-transparent px-4`}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {currentSharedResources[`active_${activeBreakoutSession.id}`].resources.map((item, index) => {
                  let { typeId, imgObj, imgType } = getImageToBeRendered(item)
                  return (
                    <Draggable
                      key={item.id + `_name_` + index}
                      draggableId={item.id.toString() + "_" + index.toString()}
                      index={index}
                      className={item.resource_data !== null ? "" : "d-none"}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={item.resource_data !== null ? "resource-item" : "d-none"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            className={`pr-2 d-flex`}
                            style={{
                              color: `#E0E2E5`,
                              background: "#4F6080",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="d-flex" style={{ alignItems: "center" }}>
                              <FontAwesomeIcon className={`ml-1 ${srResourcesFetched} ${item.resource_type ? `d-none` : ``}`} icon={faSpinner} size="lg" />
                              {item.resource_data ? (
                                item.resource_data.thumbnail_url ? (
                                  <LazyLoadImage
                                    className={`my-auto`}
                                    src={item.resource_data.thumbnail_url}
                                    alt={item.name}
                                    style={{
                                      width: `38px`,
                                      height: `30px`,
                                      fontSize: `12px`,
                                    }}
                                  />
                                ) : (
                                  //: item.resource_data.avatar ? <LazyLoadImage className={`my-auto`} src={getImageToBeRendered(item)} alt={item.name} style={{ width: `38px`, height: `30px`, fontSize: `12px` }} /> //
                                  <>
                                    {typeId === 1 && false ? (
                                      <FontAwesomeIcon
                                        icon={imgObj}
                                        className={`my-0`}
                                        style={{
                                          color: "#67edfd",
                                          marginBottom: 0,
                                          width: `38px`,
                                          height: `30px`,
                                        }}
                                      />
                                    ) : (
                                      <LazyLoadImage
                                        className={`my-auto`}
                                        src={imgObj}
                                        alt={item.name}
                                        style={{
                                          width: `38px`,
                                          height: `30px`,
                                          fontSize: `12px`,
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              ) : (
                                ``
                              )}
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: "0px 0px 0px 10px",
                                }}
                              >
                                {item.resource_data?.display_name ?? item.resource_data?.name ?? item.display_name ?? item.name} {item.id}
                              </p>
                            </div>
                            <div>
                              <img
                                src={DeleteIcon}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  const newState = data
                                  newState.splice(index, 1)
                                  setSrResourcesFetched(false)
                                  currentSharedResources[`active_${activeBreakoutSession.id}`].resources = newState
                                  deleteResource(item.id)
                                }}
                                alt={"resource delete icon"}
                                className={`my-auto pointer`}
                                style={{ objectFit: `contain`, height: `13px` }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                <React.Fragment>
                  <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>
                    <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent  text-center ${files.length === 0 ? `` : `border-0`}`}>
                      <div className={`w-100 col-12`}>
                        <Form className="my-form mb-0">
                          <React.Fragment>
                            <p className={`${StylesM.font_14} mb-2`}>
                              Drag and drop media/document files to add content /&nbsp;
                              <label
                                className={` btn border-0 px-0 ${StylesM.font_14}`}
                                style={{
                                  color: "#E0E2E5",
                                  textDecoration: "underline",
                                }}
                                htmlFor="fileElem"
                              >
                                Browse
                              </label>
                            </p>
                          </React.Fragment>
                          <input
                            className={`d-none`}
                            type="file"
                            id="fileElem"
                            multiple
                            accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              validateAndAdd(e)
                            }}
                          />
                        </Form>
                      </div>
                      <UploadFiles
                        sequence={data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2)}
                        topic={currentSharedResources[`active_${activeBreakoutSession.id}`]}
                        files={files}
                        setFiles={(value) => setFiles(value)}
                        fetchTopicResources={(id) => fetchInstructionResources(id)}
                        parentType={`instruction`}
                      />
                    </div>
                  </div>
                </React.Fragment>
              </div>
            )}
          </Droppable>
          {activeFotterBtn === "share_resources" && isShareResActive ? (
            <Col
              className="px-0 border_on_hover pl-0"
              style={{
                backgroundColor: "#303C54",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "9px",
                border: "1px solid #303C54",
                marginBottom: "12px",
                marginTop: "12px",
              }}
            >
              <InputGroup
                className="py-0 d-block"
                style={{
                  borderRadius: "9px",
                  border: "1px solid transparent",
                }}
              >
                <InputGroup.Prepend
                  className="border-0"
                  style={{
                    backgroundColor: "#303C54",
                    borderRadius: "9px 0px 0px 9px",
                  }}
                >
                  <InputGroup.Text
                    className="border-0 d-flex justify-content-center"
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "9px 0px 0px 9px",
                      color: "#E0E2E5",
                      opacity: "41%",
                      fontSize: "16px",
                    }}
                    id="basic-addon1"
                    onClick={() => {
                      document.getElementById("resource_instructions").focus()
                    }}
                  >
                    Type instructions
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  className={`border-0 d-flex w-100 justify-content-center shadow-none ${StylesB.course_create} text-white`}
                  style={{
                    backgroundColor: "#303C54",
                    borderRadius: "0px",
                    fontSize: "16px",
                  }}
                  as="textarea"
                  type="text"
                  rows="4"
                  name="resource_instructions"
                  id="resource_instructions"
                  placeholder=""
                  onChange={(e) => {
                    setSharedResInstruction(e.target.value)
                  }}
                />
              </InputGroup>
            </Col>
          ) : (
            ""
          )}
        </div>
      )
    }
  }

  function handleSubmitInstructions() {
    let formData = new FormData()
    formData.append("training", props.location.training_id)
    formData.append("title", sessionLevelInstructions)
    formData.append("published", true)
    formData.append("object_id", activeBreakoutSession.id)
    formData.append("resource_type", "breakoutsession")
    axios
      .post(`${process.env.GATSBY_REACT_APP_API_HOST}/instructions/create/`, formData, { headers: login_credentials })
      .then((res) => {
        let dummy = [...sessionInstructionsList]
        dummy.unshift(res.data)
        setSessionInstructionsList(dummy)
        setSessionLevelInstructions("")
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
      })
  }

  function handleExtendTime() {
    if (activeFotterBtn === "extend_time") {
      // document.getElementById('session_time').onSubmit = handleSubmitExtendTime();
      if (parseInt(extendDurationValue) > 0) {
        handleSubmitExtendTime()
      } else {
        // setShowNegativeError(true)
      }
    } else {
      setActiveFotterBtn("extend_time")
    }

    setActiveFotterBtn("extend_time")
  }

  function handleClickInstructions() {
    if (activeFotterBtn === "send_instructions") {
      handleSubmitInstructions()
      setActiveFotterBtn(null)
    } else {
      setActiveFotterBtn("send_instructions")
    }
  }

  function handleSubmitExtendTime(extendedValue = null) {
    let session_id = activeBreakoutSession.id
    let duration = activeBreakoutSession.duration
    let new_duration = parseInt(duration) + parseInt(extendDurationValue)
    let dummy = { ...modulesData }
    let session_topic = dummy.module_Doing.topics.filter((topic) => topic.id === activeBreakoutSession.topic)[0]
    if (session_topic) {
      var index = session_topic.breakout_sessions.findIndex((res) => res.id === parseInt(session_id))
      session_topic.breakout_sessions[index].duration = new_duration
      setModulesData(dummy)
    }
    let dummy1 = { ...activeBreakoutSession }
    dummy1.duration = new_duration
    setActiveBreakoutSession(dummy1)
    let dummy2 = { ...breakoutSessionActive }
    dummy2.duration = new_duration
    setBreakoutSessionActive(dummy2)
    var formData = new FormData()
    formData.append("duration", new_duration)
    formData.append("template", props.location.template_id)
    if (session_id != null) {
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-session/` + session_id + "/", formData, { headers: DefaultHeader })
        .then((res) => {
          setActiveFotterBtn(null)
          setShowExtendTimeModal(false)
          setExtendDurationValue(30)
          setNotificationData({
            type: "success",
            title: "Extended Time Sucessfully",
          })
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
          document.getElementById("fixed-error-div").innerHTML =
            '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
            '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
            '<span aria-hidden="true">×</span></button>' +
            error_messages +
            "</div>"
        })
    }
  }

  function handleUpdateInstruction() {
    var formData = new FormData()
    // add your instruction title here in form data
    formData.append("training", props.location.training_id)
    formData.append("published", true)
    formData.append("object_id", activeBreakoutSession.id)
    formData.append("title", sharedResInstruction)
    formData.append("resource_type", "breakoutsession")
    formData.append("instruction_type", "1")
    axios
      .put(`${process.env.GATSBY_REACT_APP_API_HOST}/instructions/${currentSharedResources[`active_${activeBreakoutSession.id}`].id}/`, formData, {
        headers: login_credentials,
      })
      .then((res) => {
        let current_data = { ...currentSharedResources }
        delete current_data[`active_` + activeBreakoutSession.id]
        setCurrentSharedResources(current_data)
        setActiveFotterBtn(null)
        setSharedResInstruction("")
        fetchSharedResources()
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
      })
  }

  function createSharedInstruction(type) {
    if (type === "share_resources" && currentSharedResources[`active_${activeBreakoutSession.id}`] === undefined) {
      let formData = new FormData()
      formData.append("training", props.location.training_id)
      formData.append("published", false)
      formData.append("object_id", activeBreakoutSession.id)
      formData.append("resource_type", "breakoutsession")
      formData.append("instruction_type", "1")
      axios
        .post(`${process.env.GATSBY_REACT_APP_API_HOST}/instructions/create/`, formData, { headers: login_credentials })
        .then((res) => {
          let current_data = { ...currentSharedResources }
          current_data[`active_` + activeBreakoutSession.id] = res.data
          current_data[`active_` + activeBreakoutSession.id]["resources"] = []
          setCurrentSharedResources(current_data)
          setActiveFotterBtn(`share_resources`)
        })
        .catch((error) => {
          let error_messages = ""
          for (var key in error.response.data) {
            error_messages += error.response.data[key] + "<br>"
          }
          if (error_messages === "Invalid token.<br>") {
            authClient.signOut()
            localStorage.removeItem("authenticity_token")
            localStorage.removeItem("username")
            window.location.reload(true)
          }
        })
    } else {
      handleUpdateInstruction()
    }
  }

  function getBreakoutModalFooter() {
    return (
      <div className={`w-100`}>
        <div className={`w-100 align-items-center`}>
          {activeFotterBtn ? (
            <div className={`w-100 d-inline-flex`}>
              <FontAwesomeIcon
                className={`pointer ml-auto`}
                onClick={() => {
                  setActiveFotterBtn(null)
                }}
                icon={faTimes}
              />
            </div>
          ) : null}
          {getFooterData()}
        </div>
        <div className={`w-100 d-inline-flex align-items-center`}>
          <button
            className={`poppins_font ${
              activeFotterBtn === "share_resources" ? `${Style.breakout_modal_fotter_btn_active} py-2` : `${Style.breakout_modal_fotter_btn_inactive} py-1`
            } ${activeFotterBtn === "share_resources" && !isShareResActive ? Style.btn_inactive : ""} border_on_hover px-3 mr-3`}
            disabled={activeFotterBtn === "share_resources" && !isShareResActive}
            onClick={(e) => {
              e.preventDefault()
              createSharedInstruction(activeFotterBtn === "share_resources" ? null : "share_resources")
            }}
          >
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              Share Resources
            </p>
          </button>
          <button
            className={`poppins_font ${
              activeFotterBtn === "send_instructions" ? `${Style.breakout_modal_fotter_btn_active} py-2` : `${Style.breakout_modal_fotter_btn_inactive} py-1`
            } border_on_hover px-3 mr-3`}
            onClick={(e) => {
              e.preventDefault()
              handleClickInstructions()
            }}
          >
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              Send Instructions
            </p>
          </button>
          <button
            className={`poppins_font ${
              activeFotterBtn === "extend_time" ? `${Style.breakout_modal_fotter_btn_active} py-2` : `${Style.breakout_modal_fotter_btn_inactive} py-1`
            } border_on_hover px-4 mr-3`}
            onClick={(e) => {
              e.preventDefault()
              handleExtendTime()
            }}
          >
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
              Extend Time
            </p>
          </button>
          <button
            className={`poppins_font ml-auto ${Style.breakout_modal_fotter_btn_active} border_on_hover px-3 py-1`}
            onClick={() => {
              setShowEndBreakoutSessionModal(true)
            }}
            style={{ backgroundColor: "#E88373" }}
          >
            <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500", color: "#121B2B" }}>
              End Session
            </p>
          </button>
        </div>
      </div>
    )
  }

  function fetchSessionInstructions() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/instructions/?training=${props.location.training_id}&object_id=${activeBreakoutSession?.id}&content_type=breakoutsession&instruction_type=0`,
        { headers: login_credentials }
      )
      .then((res) => {
        if (res.data.results && res.data.results.length > 0) setSessionInstructionsList(res.data.results)
        setSessionInstructionsFetched(true)
        setSessionInstructionsCount(res?.data?.count)
      })
      .catch((error) => {
        let error_messages = ""
        for (var key in error.response.data) {
          error_messages += error.response.data[key] + "<br>"
        }
        if (error_messages === "Invalid token.<br>") {
          authClient.signOut()
          localStorage.removeItem("authenticity_token")
          localStorage.removeItem("username")
          window.location.reload(true)
        }
        document.getElementById("fixed-error-div").innerHTML =
          '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
          '<span aria-hidden="true">×</span></button>' +
          error_messages +
          "</div>"
      })
  }

  useEffect(() => {
    if (activeBreakoutBtn === "shared_items") {
      fetchSessionInstructions()
    } else if (activeBreakoutBtn === "activity_wise_progress") {
      fetchBreakoutActivities()
    }
  }, [activeBreakoutBtn])

  useEffect(() => {
    if (showEndBreakoutSessionModal == false) {
      SocketConnection()
    }
  }, [showEndBreakoutSessionModal])

  async function fetchBreakoutActivities() {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/breakout-activities/?breakout_session=${activeBreakoutSession?.id}&paginate_by=15`, {
        headers: login_credentials,
      })
      .then((res) => {
        let data = res.data?.results
        setBreakoutActivities(data)
        setBreakoutActivitiesNextUrl(res.data.next)
        setActiveBreakoutActivity(data[0])
        setIsBreakoutActivitiesFetched(true)
      })
      .catch((err) => {})
  }

  function fetchBreakoutActivitiesNext(next_url) {
    setBreakoutActivitiesNextFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let data = [...breakoutActivities, ...res.data?.results]
      setBreakoutActivities(data)
      setBreakoutActivitiesNextUrl(res.data.next)
      setBreakoutActivitiesNextFetched(true)
    })
  }

  useEffect(() => {
    if (props.currentNavTab === "BreakoutSession") {
      handleBreakoutSessionModal()
    }
  }, [props.currentNavTab])

  function updateBreakoutSessionModulesData(duration, startTime, session_id, topic_id) {
    let dummy = { ...modulesData }
    let session_topic = dummy.module_Doing.topics.filter((topic) => topic.id === topic_id)[0]
    if (session_topic) {
      var index = session_topic.breakout_sessions.findIndex((res) => res.id === parseInt(session_id))
      if (index >= 0) {
        session_topic.breakout_sessions[index].duration = duration
        if (startTime != null) {
          session_topic.breakout_sessions[index].start_time = startTime
        }
        setModulesData(dummy)
      }
    }
  }

  async function updateBreakoutSession(formData, sessionId, func = null) {
    if (breakoutSession.id != null) {
      const response = await putRequest(`/breakout-session/${sessionId}/`, formData)
      if (response.success) {
        func && func(response.data)
      } else {
        let err = response.msg.trim().length > 0 ? response.msg.trim() : "An error occurred!"
        setNotificationData({ type: "error", title: err })
      }
    }
  }

  function displayBreakoutSessionStart() {
    if (breakoutTimeAlterationModal === "dontShow") {
      return null
    }

    let breakoutSessName = breakoutSession.name,
      launchOrRelaunchName = breakoutTimeAlterationModal === "showReLaunch" ? "re-launch" : "launch",
      cssClass = props.leftNavOpen ? Style.breakout_session_detail_open1 : Style.breakout_session_detail_closed1

    return (
      <>
        <div style={{}}>
          <div className={`${cssClass}`}>
            <div
              className={`${Style.breakout_modal} pl-4 pr-1 pt-2 pb-3`}
              style={{
                borderRadius: "8px",
                maxHeight: "90vh",
                width: "27vw",
                overflowY: "auto",
                border: "1px solid #929BBD", //breakoutSession.status === "1" ? "2px solid #3FBDAC" :
              }}
            >
              <div className={""}>
                <div className={`d-flex justify-content-end mb-1`}>
                  <FontAwesomeIcon className={`pointer`} onClick={() => handleBreakoutSessionModal()} icon={faTimesCircle} />
                </div>
                <div className={``} style={{ fontSize: 14 }}>
                  <div className={`d-flex w-100 justify-content-center`}>
                    <p style={{ fontSize: 16, fontWeight: "bold" }}>{breakoutSessName}</p>
                  </div>
                  <div className="d-flex w-100 justify-content-center">
                    <span>
                      You are going to {launchOrRelaunchName} {breakoutSessName}.
                    </span>
                  </div>
                  <div className="d-flex w-100 justify-content-center">
                    <span>You can edit the allotted time if required</span>
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    <div
                      className={`d-flex justify-content-between`}
                      style={{ backgroundColor: "#212C42", border: "1px solid #E0E2E565", borderRadius: 9, width: "70%" }}
                    >
                      <span className="py-3 px-2" style={{ width: "50%", color: "#E0E2E5" }}>
                        Allotted Time:
                      </span>
                      <span
                        className="d-flex-inline py-3 px-2"
                        style={{ width: "50%", backgroundColor: "#42506C", color: "#E0E2E5", borderLeft: "1px solid #E0E2E565" }}
                      >
                        <input
                          autoFocus
                          defaultValue={breakoutSession.duration ?? 30}
                          id="allottedTime"
                          size={4}
                          min={0}
                          max={100000}
                          style={{ backgroundColor: "transparent", color: "#fff", padding: "0px 0px 0px 5px", border: 0 }}
                        />
                        <span>Mins</span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      style={{ backgroundColor: "#586886", borderRadius: 23, border: "1px solid #fff", padding: "10px 60px", color: "#fff" }}
                      className={`mt-3`}
                      onClick={(e) => {
                        e.preventDefault()
                        let time = document.getElementById("allottedTime").value
                        if (time > 0) {
                          setBreakoutSession({ ...breakoutSession, duration2: time })
                          setBreakoutTimeAltered(true)
                        } else {
                          setNotificationData({ type: "error", title: "Please set the Allotted Time in minutes" })
                        }
                      }}
                    >
                      Start
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  function breakoutSessionModal() {
    let session = activeBreakoutSession?.name ? activeBreakoutSession : breakoutSession
    if (showBreakoutSessionModal) {
      let cssClass = props.leftNavOpen ? Style.breakout_session_detail_open : Style.breakout_session_detail_closed
      if (breakoutSessWindowScreen === "minimize") {
        cssClass = Style.breakout_session_detail_minimized
      }

      return (
        <div style={{ position: "relative" }}>
          <div className={`${cssClass}`}>
            <div
              className={`${Style.breakout_modal}`}
              style={{
                borderRadius: "8px",
                maxHeight: "90vh",
                overflowY: "auto",
                border: session?.status === "1" ? "2px solid #3FBDAC" : "1px solid #929BBD",
              }}
            >
              {showBreakoutSessionModal ? (
                <React.Fragment>
                  <div className="pt-2 px-0" style={breakoutTimeAlterationModal !== "dontShow" ? { opacity: 0.2 } : {}}>
                    <div className={`w-100 px-2 pb-2 d-inline-flex justify-content-between`} style={{ borderBottom: "1px solid #929BBD" }}>
                      <div className={`w-100 d-inline-flex align-items-center justify-content-between ${activeBreakoutSession?.name ? `` : `d-none`}`}>
                        <div className={`d-flex align-items-center`}>
                          <p className={`mb-0 mr-2 poppins_font`} style={{ fontSize: "19px", fontWeight: "500" }}>
                            {session.name}
                          </p>
                          <p className={`poppins_font mb-0`} style={{ fontSize: "16px", fontWeight: "500" }}>
                            ({session?.activity_count} Activities )
                          </p>
                        </div>
                        <p className={`mb-0 mr-3 ${session.status === "2" ? `d-none` : ``}`} style={{ fontSize: "16px", fontWeight: "500" }}>
                          Ends in{" "}
                          <span
                            className={`px-2 py-1`}
                            style={{
                              backgroundColor: "#3B82F4",
                              borderRadius: "4px",
                            }}
                          >
                            {hourLeft === "0" ? "" : hourLeft + ":"}
                            {minLeft}:{secLeft}
                          </span>{" "}
                          Mins.
                        </p>
                      </div>
                      <div className={`d-flex align-items-center justify-content-center`}>
                        <FontAwesomeIcon
                          className={`pointer mr-2`}
                          onClick={() => {
                            setBreakoutSessWindowScreen((bws) => (bws === "minimize" ? "maximize" : "minimize"))
                          }}
                          icon={breakoutSessWindowScreen === "minimize" ? faWindowMaximize : faWindowMinimize}
                        />
                        <FontAwesomeIcon
                          className={`pointer`}
                          onClick={() => {
                            handleBreakoutSessionModal()
                          }}
                          icon={faTimesCircle}
                        />
                      </div>
                    </div>
                    {breakoutSessWindowScreen === "maximize" && (
                      <Col className={`p-2 pb-0 w-100 m-0 poppins_font`}>
                        <div className={`w-100 pb-3 d-inline-flex align-items-center`}>
                          <button
                            className={`poppins_font ${
                              activeBreakoutBtn === "overall_progress" ? Style.breakout_modal_btn_active : Style.breakout_modal_btn_inactive
                            } border_on_hover py-2 px-3 mr-3`}
                            onClick={() => {
                              setActiveBreakoutBtn("overall_progress")
                            }}
                          >
                            Overall Progress
                          </button>
                          <button
                            className={`poppins_font ${
                              activeBreakoutBtn === "activity_wise_progress" ? Style.breakout_modal_btn_active : Style.breakout_modal_btn_inactive
                            } border_on_hover py-2 px-3 mr-3`}
                            onClick={() => {
                              setActiveBreakoutBtn("activity_wise_progress")
                            }}
                          >
                            Activity wise Progress
                          </button>
                          <button
                            className={`poppins_font ${
                              activeBreakoutBtn === "shared_items" ? Style.breakout_modal_btn_active : Style.breakout_modal_btn_inactive
                            } border_on_hover py-2 px-3 mr-3`}
                            onClick={() => {
                              setActiveBreakoutBtn("shared_items")
                              setActiveFotterBtn(null)
                            }}
                          >
                            Shared Items
                          </button>
                        </div>
                        <div
                          onScroll={(e) => {
                            e.stopPropagation()
                          }}
                          style={{
                            maxHeight: activeFotterBtn ? "200px" : "50vh",
                            overflowY: "scroll",
                          }}
                        >
                          {activeBreakoutBtn === "activity_wise_progress" ? selectActivityFilter() : null}
                          {activeBreakoutBtn === "activity_wise_progress" && activityResourcesFetched && selectResourceFilter()}
                          {activeBreakoutBtn === "shared_items" ? (
                            <Col
                              className={`pt-2`}
                              style={{
                                backgroundColor: "#303C54",
                                borderRadius: "9px",
                              }}
                            >
                              <div className={`w-100 mb-3 d-inline-flex align-items-center`}>
                                <p
                                  className={`poppins_font pointer mb-0 mr-3`}
                                  onClick={() => {
                                    setShowInstructions(false)
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    borderBottom: !showInstructions ? "1px solid white" : "0px",
                                  }}
                                >
                                  Resources ({sharedResourcesCount[`as_${session?.id}`]})
                                </p>
                                <p
                                  className={`poppins_font pointer mb-0`}
                                  onClick={() => {
                                    setShowInstructions(true)
                                  }}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    borderBottom: showInstructions ? "1px solid white" : "0px",
                                  }}
                                >
                                  Instructions ({sessionInstructionsCount})
                                </p>
                              </div>
                              {getSharedResources()}
                            </Col>
                          ) : (
                            breakoutGroupsProgress && getAllBreakOutGroups()
                          )}
                        </div>
                      </Col>
                    )}
                  </div>
                  {breakoutSessWindowScreen === "maximize" && (
                    <div
                      className={`p-2 ${session.status === "2" ? `d-none` : ``}`}
                      style={{ borderTop: "1px solid #929BBD", opacity: `${breakoutTimeAlterationModal !== "dontShow" ? 0.2 : 1}` }}
                    >
                      <Col className={`w-100 px-0 align-items-center`}>{getBreakoutModalFooter()}</Col>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                  <Spinner animation="border" variant="secondary" />
                </Col>
              )}
              {displayBreakoutSessionStart()}
            </div>
            {/*</Col>*/}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  function clearBreakoutSessionTimer() {
    if (breakoutSessionTimer.current !== null) {
      clearInterval(breakoutSessionTimer.current)
      breakoutSessionTimer.current = null
      setMinLeft("00")
      setSecLeft("00")
      setHourLeft("0")
    }
  }

  const initBreakoutSessionTimer = (session) => {
    clearBreakoutSessionTimer()
    let today = new Date()
    let duration = session.duration
    let start_date = new Date(session.start_time)
    duration = duration * 60 * 1000
    let total_time_in_sec = new Date(parseInt(start_date.getTime() + duration))
    let total_time = total_time_in_sec - start_date
    let suggestionModalTriggered = false
    let percentageForTriggered = 20
    let remainingTime = total_time_in_sec.getTime() - today.getTime()

    if (props.location.training_id && today.getTime() < total_time_in_sec.getTime()) {
      breakoutSessionTimer.current = setInterval(() => {
        let tod = new Date()
        let remainingTime = total_time_in_sec.getTime() - tod.getTime()

        if (remainingTime > 0) {
          let remainingMaxTime = Math.max(0, total_time_in_sec - new Date())
          let completedPercentage = parseInt((remainingMaxTime / total_time) * 100)
          if (completedPercentage === percentageForTriggered && remainingTime > 2 * 60 && !suggestionModalTriggered) {
            setShowSuggestionModal(true)
            suggestionModalTriggered = true
          }
          let sec = Math.floor(remainingTime / 1000)
          let min = Math.floor(sec / 60)
          let hour = Math.floor(min / 60)
          min %= 60
          sec %= 60
          hour %= 24

          setMinLeft(min < 10 ? "0" + min : min)
          setSecLeft(sec < 10 ? "0" + sec : sec)
          setHourLeft(hour < 1 ? "0" : hour)
          if (min === "00" && sec === "00" && hour === "0") {
            clearBreakoutSessionTimer()
          }
        } else {
          //end breakout session
          if (session !== null) {
            // updateBreakoutSessionStatus(breakoutSessionActive.id, 2, breakoutSessionActive.topic)
            // clearBreakoutSessionTimer()
            // setMinLeft("00")
            // setSecLeft("00")
            // setHourLeft("0")
            setShowSuggestionModal(true)
          }
        }
      }, 100)
    } else {
      if (session !== null) {
        // updateBreakoutSessionStatus(breakoutSessionActive.id, 2, breakoutSessionActive.topic)
        // clearBreakoutSessionTimer()
        // setMinLeft("00")
        // setSecLeft("00")
        // setHourLeft("0")
        setShowSuggestionModal(true)
        setShowBreakoutSessionModal(false)
      }
    }
  }

  useEffect(() => {
    if (breakoutSessionActive !== null && breakoutSessionActive?.start_time && breakoutSessionActive?.duration && modulesData) {
      initBreakoutSessionTimer(breakoutSessionActive)
    }

    if (breakoutSessionActive == null && breakoutSessionTimer.current) {
      clearBreakoutSessionTimer()
    }
    return () => clearBreakoutSessionTimer()
  }, [breakoutSessionActive])

  function updateBreakoutSessionSuccess(data, duration, startTime) {
    let bSessionActive = { ...data }
    updateBreakoutSessionModulesData(duration, startTime, bSessionActive.id, bSessionActive?.topic)
    bSessionActive.duration = duration
    bSessionActive.start_time = startTime
    setActiveBreakoutSession({ ...bSessionActive })
    setBreakoutSessionActive({ ...bSessionActive })
    setBreakoutSession({ ...bSessionActive })
    calcBreakoutSessionDateTime(bSessionActive)
    setShowSuggestionModal(false)
    let func = breakoutTimeAlterationModal === "showLaunch" ? triggerBreakoutSession : reTriggerBreakoutSession
    func(breakoutSession, breakoutSession?.topic ?? selectedTopic)
    setBreakoutTimeAlterationModal("dontShow")
    setBreakoutTimeAltered(false)
  }

  useEffect(() => {
    if (breakoutTimeAltered) {
      if (parseInt(breakoutSession.duration2) !== parseInt(breakoutSession.duration)) {
        updateBreakoutSession({ duration: breakoutSession.duration2, template: props.location.template_id }, breakoutSession.id, (data) => {
          updateBreakoutSessionSuccess(data, breakoutSession.duration2, data.updated_at)
        })
      } else {
        let startDate = new Date().toISOString()
        updateBreakoutSessionSuccess(breakoutSession, breakoutSession.duration, startDate)
      }
    }
  }, [breakoutTimeAltered])

  useEffect(() => {
    if (publishMsg) {
      setTimeout(() => {
        setPublishMsg(false)
      }, 5000)
    }
  }, [publishMsg])

  function publishCaseStudyReport(cs_report_card_id) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/case-study-group-report-card/${cs_report_card_id}/submit_group_report/?publish=true`, {
        headers: login_credentials,
      })
      .then((res) => {
        let data = { ...caseStudyGroupData }
        data[`cs_${cs_report_card_id}`].report_track = {
          ...data[`cs_${cs_report_card_id}`].report_track,
          published: res.data.published,
          case_study_status: res.data.case_study_status,
        }
        setCaseStudyGroupData(data)
        setPublishMsg(true)
      })
  }

  function expandToggle(id, index) {
    let data = { ...caseStudyGroupDataExpandData }
    data[`cs_${id}`][index] = !data[`cs_${id}`][index]
    setCaseStudyGroupDataExpandData(data)
  }

  function showQuestionsCaseStudy() {
    let alphabets = "ABCDEFGHIJKLMNOPQRSTUWXYZ"
    let csReport = caseStudyGroupData["cs_" + activeCaseStudyGroupData]?.report_track
    if (caseStudyGroupData && caseStudyGroupData["cs_" + activeCaseStudyGroupData] && caseStudyGroupData["cs_" + activeCaseStudyGroupData].results.length > 0) {
      return (
        <>
          {caseStudyGroupData["cs_" + activeCaseStudyGroupData].results.map((data, index) => {
            let expand = false
            if (caseStudyGroupDataExpandData[`cs_${activeCaseStudyGroupData}`]) {
              expand = caseStudyGroupDataExpandData[`cs_${activeCaseStudyGroupData}`][index]
            }
            if (data.question_type === "2") {
              return (
                <div key={`csquestion${index}`} className={`d-flex mb-3`}>
                  <div
                    className={`font-weight-bold text-center`}
                    style={{
                      fontSize: "32px",
                      color: "#9BA1C4",
                      width: "45px",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div className={`inner-element-box-shadow w-100 ml-2 fs-16px ${Style.cs_ques_box}`}>
                    <div className={`d-flex justify-content-between m-3`}>
                      <div className={`w-100 d-flex flex-column mr-3`}>
                        <div className={`p-2 ${expand ? "mb-2 pl-0" : `${Style.cs_ques_box}`} `} style={{ backgroundColor: expand ? "" : "#42506C" }}>
                          {data?.title}
                        </div>
                        {expand ? (
                          <React.Fragment>
                            {data?.subjective_answers?.length > 0 ? (
                              <div className={`my-1`}>
                                {/*<div style={{opacity: '60%'}}>Answers</div>*/}
                                {data?.subjective_answers?.map((ans, index) => {
                                  return (
                                    <div
                                      key={`cs_subjective_answer_${index}`}
                                      className={`w-100 p-2 mb-2 ${Style.cs_ques_box}`}
                                      style={{ backgroundColor: "#42506C" }}
                                    >
                                      <p
                                        className={`mb-0`}
                                        dangerouslySetInnerHTML={{
                                          __html: ans?.answer,
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            ) : (
                              <div className={`mt-1`}>
                                <div style={{ opacity: "70%" }}>No Answers yet</div>
                              </div>
                            )}
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className={`${expand ? "pt-2" : "align-self-center"}`}>
                        <FontAwesomeIcon
                          onClick={(e) => {
                            expandToggle(activeCaseStudyGroupData, index)
                          }}
                          className={`pointer`}
                          transform={`grow-4`}
                          icon={expand ? faChevronCircleUp : faChevronCircleDown}
                        />
                      </div>
                    </div>
                    {data?.answer_map !== null && csReport?.grading && (
                      <React.Fragment>
                        <div className={`d-flex align-items-center px-2 py-3`} style={{ borderTop: "1px solid #6b7894" }}>
                          <div>Score : </div>
                          <div className={`mx-2`}>
                            <input
                              type="number"
                              min="0"
                              max={data?.answer_map?.total_marks}
                              defaultValue={data?.answer_map === null ? 0 : parseInt(data?.answer_map?.marks_gained)}
                              onChange={(e) => {
                                updateGradeGroupActivityCaseStudy(e, data?.answer_map, data?.id, index)
                              }}
                              className={`${StylesA.session_input_field} mx-auto form-control shadow-none text-center px-2 ${StylesA.border_on_hover}`}
                              style={{
                                width: "60px",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            />
                          </div>
                          <div>Out of {data?.answer_map?.total_marks} marks </div>
                        </div>
                        {errorMsg !== null && errorMsg?.id === data?.answer_map?.id && (
                          <div className={`mb-2 px-0 `}>
                            <div className="d-inline-flex px-2 py-0" style={{ borderRadius: "23px" }}>
                              <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "13px" }} />
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "14px",
                                  color: "#F4F411",
                                }}
                              >
                                {errorMsg?.msg}
                              </p>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )
            } else if (data.question_type === "1") {
              return (
                <div key={`csquestion${index}`} className={`d-flex mb-3`}>
                  <div
                    className={`font-weight-bold text-center`}
                    style={{
                      fontSize: "32px",
                      color: "#9BA1C4",
                      width: "45px",
                    }}
                  >
                    {index + 1}
                  </div>
                  <div className={`inner-element-box-shadow w-100 ml-2 fs-16px ${Style.cs_ques_box}`}>
                    <div className={`d-flex justify-content-between m-3`}>
                      <div className={`w-100 d-flex flex-column mr-3`}>
                        <div className={`p-2 ${expand ? "mb-2" : ""} ${Style.cs_ques_box}`} style={{ backgroundColor: "#42506C" }}>
                          {data?.title}
                        </div>
                        {expand ? (
                          <React.Fragment>
                            {data?.student_options.map((ans, i) => (
                              <div key={`csans${i}`} className={`d-flex align-items-center my-1`}>
                                <div className={`ml-2`}>
                                  <FontAwesomeIcon
                                    style={{
                                      opacity: ans.is_selected ? "1" : "0",
                                    }}
                                    icon={faPlay}
                                  />
                                </div>
                                <div
                                  className={`font-weight-bold mx-2`}
                                  style={{
                                    fontSize: "20px",
                                    color: "#9BA1C4",
                                  }}
                                >
                                  {alphabets[i]}
                                </div>
                                <div
                                  className={`flex-grow-1 p-2 ${Style.cs_ques_box}`}
                                  style={{
                                    backgroundColor: ans.is_correct ? "#197C7C" : "#42506C",
                                  }}
                                >
                                  {ans?.name}
                                </div>
                              </div>
                            ))}
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className={`${expand ? "pt-2" : "align-self-center"}`}>
                        <FontAwesomeIcon
                          onClick={(e) => {
                            expandToggle(activeCaseStudyGroupData, index)
                          }}
                          className={`pointer`}
                          transform={`grow-4`}
                          icon={expand ? faChevronCircleUp : faChevronCircleDown}
                        />
                      </div>
                    </div>
                    {expand && data?.answer_map !== null && csReport?.grading ? (
                      <div className={`d-flex align-items-center font-weight-bold px-2 py-3 mt-3`} style={{ borderTop: "1px solid #6b7894" }}>
                        <div>Score : </div>
                        <div className={`mx-1`}>
                          {" "}
                          {data?.answer_map?.marks_gained}/{data?.answer_map?.total_marks} marks
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}
          {caseStudyGroupData["cs_" + activeCaseStudyGroupData].next !== null && (
            <div className="ml-3 mb-3">
              <Button
                variant={`none`}
                disabled={caseStudyGroupDataLoader}
                className={`${StylesA.add_button} ${StylesA.border_on_hover}`}
                onClick={() => {
                  fetchNextCaseStudyReport(caseStudyGroupData["cs_" + activeCaseStudyGroupData].next)
                }}
              >
                See more
              </Button>
            </div>
          )}
        </>
      )
    } else {
      return null
    }
  }

  function showReports() {
    let data = caseStudyGroupData["cs_" + activeCaseStudyGroupData]
    let results = " -- "
    let status = data?.report_track?.case_study_status
    let avg = caseStudyGroupClassAverage[`res_${activeActivityResource?.id}`]
    if (status === "2") {
      let perc = (data?.report_track?.marks_gained / data?.report_track?.total_marks) * 100
      if (perc >= data?.report_track?.passing_marks) {
        results = "Passed"
      } else {
        results = "Fail"
      }
    }
    return (
      <div className={`d-flex mb-4 justify-content-between`}>
        <ReportBox header={"Total Attempts"} footer={avg !== null ? `Class Avg. : ${avg?.avg_attempts}` : null}>
          <div className={`font-weight-bolder flex-fill py-4`}>{data?.report_track?.total_attempts}</div>
        </ReportBox>
        <ReportBox header={"Results"} footer={avg !== null ? `Class Success Rate : ${avg?.is_class_pass_percentage}%` : null}>
          <div className={`font-weight-bolder flex-fill py-4 ${results === "Fail" && "text-danger"}`}>{results}</div>
        </ReportBox>
        <ReportBox
          header={`${status === "2" ? "Final" : "Current"} Score`}
          footer={avg !== null ? `Class Avg. : ${avg?.avg_marks} (${avg?.avg_marks_percent}%)` : null}
        >
          <div className={`font-weight-bolder flex-fill py-4`}>
            {data?.report_track?.marks_gained}/{data?.report_track?.total_marks}
          </div>
        </ReportBox>
        <ReportBox header={"Time Taken"} footer={avg !== null ? `Class Avg : ${convertToTime(avg?.time_taken)}` : null}>
          <div className={`font-weight-bolder flex-fill py-4`}>{convertToTime(data?.report_track?.time_taken)}</div>
        </ReportBox>
      </div>
    )
  }

  function updateGradeGroupActivityCaseStudy(e, answer_map, question, index) {
    let marks = e.target.value
    if (marks > answer_map?.total_marks || marks < 0) {
      setErrorMsg({
        msg: `Please enter marks between 0 and ${answer_map?.total_marks}`,
        id: answer_map?.id,
      })
    } else {
      setErrorMsg(null)
      if (marks == "") {
        marks = 0
      }
      let formData = new FormData()
      formData.append("marks_gained", marks)
      formData.append("question", question)
      formData.append("case_study_group_report_card", activeCaseStudyGroupData)
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + `/case-study-group-answer-submit/${answer_map?.id}/`, formData, { headers: login_credentials })
        .then((res) => {
          let data = { ...caseStudyGroupData }
          data[`cs_${activeCaseStudyGroupData}`].results[index].answer_map.marks_gained = res.data.marks_gained
          setCaseStudyGroupData(data)
        })
    }
  }

  //will delete later
  function showStudentDropdown() {
    return (
      <Dropdown className={"session-time-drop-down px-0"}>
        <Dropdown.Toggle as="div" id="dropdown-custom-components">
          <div
            className={`inner-element-box-shadow px-2 mx-2 pointer border_on_hover`}
            style={{
              position: "relative",
              borderRadius: "6px",
              backgroundColor: "#586886",
              color: "#E0E2E5",
            }}
          >
            {activeCaseStudyGroup?.title}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Style.active_input_drop_down}`}>
          <div className={`${Style.dropdown_list} font-weight-bold py-2`} style={{ fontSize: "16px" }}>
            {activeCaseStudyGroup?.title}
          </div>
          <div style={{ maxHeight: "300px", overflowY: "auto" }}>
            {caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`].results &&
            caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`].results.length > 0
              ? caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`].results.map((student, index) => {
                  return (
                    <div key={`student_${index}`} className={`d-flex align-items-center ${Style.dropdown_list}`}>
                      <div
                        className={`circle text-center font-weight-bolder`}
                        style={{
                          height: "35px",
                          width: "35px",
                          padding: "6px",
                          backgroundColor: "#212C42",
                        }}
                      >
                        {student?.first_name?.charAt(0) ?? "S"}
                      </div>
                      <div className={`ml-2`}>{student?.first_name}</div>
                    </div>
                  )
                })
              : null}
            {caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`].next !== null && (
              <div
                style={{
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingBottom: "0px",
                }}
              >
                <div
                  className={`underline pointer`}
                  onClick={() => {
                    fetchCaseStudyGroupMemberListNext(caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`].next)
                  }}
                >
                  See More
                </div>
              </div>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  function caseStudyModalActivities() {
    return (
      <Modal
        show={caseStudyActivitiesModal}
        onHide={() => {
          setCaseStudyActivitiesModal(false)
          setActiveCaseStudyGroupData(null)
          setActiveCaseStudyGroup({})
          setActiveRemarkText("")
          setErrorMsg(null)
        }}
        onShow={() => {
          //fetch case study report
        }}
        size="lg"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName={`${Style.breakout_activity_modal_dialog}`}
        contentClassName={`${Style.breakout_activity_modal}`}
        scrollable={true}
      >
        <Modal.Header className={`p-2`} style={{ borderBottom: "1px solid #929BBD" }}>
          <div className={`w-100 d-flex justify-content-between`}>
            <div className={`d-flex fs-16px align-items-center`}>
              <div>{activeActivityResource?.resource_data?.name}</div>
              <div>
                {activeCaseStudyGroupData && caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`] && (
                  <StudentListDropdown
                    title={activeCaseStudyGroup?.title}
                    studentListData={caseStudyGroupStudentList[`cs_${activeCaseStudyGroupData}`]}
                    fetchGroupMemberListNext={{
                      fetchCaseStudyGroupMemberListNext,
                    }}
                    type={"casestudy"}
                  />
                )}{" "}
              </div>
              {/*<div><FontAwesomeIcon onClick={()=>{fetchCaseStudyGroupReport(activeCaseStudyGroupData)}} className={`pointer`} icon={faRedo}/></div>*/}
            </div>
            <div>
              <FontAwesomeIcon
                className={`pointer`}
                onClick={() => {
                  setCaseStudyActivitiesModal(false)
                }}
                icon={faTimesCircle}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          onScroll={(e) => {
            e.stopPropagation()
          }}
        >
          <div className={`d-flex flex-column`}>
            {activeCaseStudyGroupData && caseStudyGroupData && caseStudyGroupData["cs_" + activeCaseStudyGroupData]?.report_track?.grading
              ? showReports()
              : null}
            {showQuestionsCaseStudy()}
            <div className={` ${Style.cs_ques_box} ml-4 p-2`}>
              <div style={{ opacity: "0.6" }} className={`fs-14px font-weight-bold`}>
                Add Remarks
              </div>
              <textarea
                defaultValue={caseStudyGroupData["cs_" + activeCaseStudyGroupData]?.report_track?.remark}
                onChange={(e) => {
                  setActiveRemarkText(e.target.value)
                  autoSaveRemark(
                    e.target.value,
                    activeActivityResource?.id,
                    activeBreakoutActivity.breakout_session,
                    caseStudyGroupData["cs_" + activeCaseStudyGroupData]?.report_track?.group_id,
                    activeCaseStudyGroupData,
                    activeCaseStudyGroup?.title
                  )
                }}
                className={`${Styles.font_16} ${Style.text_area} p-2 border-none`}
              />
            </div>
            <div className={`ml-4 mt-2 d-inline-flex`}>
              {activeCaseStudyGroupData && caseStudyGroupData["cs_" + activeCaseStudyGroupData]?.report_track?.published ? (
                <Button
                  onClick={() => {
                    setCaseStudyActivitiesModal(false)
                  }}
                  className={`${StylesA.add_button_large} ${StylesA.border_on_hover}`}
                  variant="none"
                >
                  Done
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    publishCaseStudyReport(activeCaseStudyGroupData)
                  }}
                  className={`${StylesA.add_button_large} ${StylesA.border_on_hover}`}
                  variant="none"
                >
                  Publish
                </Button>
              )}
              {publishMsg && (
                <div className={`mb-0 ml-3 fs-12px align-self-center`}>
                  <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                  All changes are saved
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function checklistModalActivities() {
    return (
      <Modal
        show={checklistActivitiesModal}
        onHide={() => {
          setChecklistActivitiesModal(false)
        }}
        size="lg"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName={`${Style.question_modal_dialog}`}
        contentClassName={`${Style.questions_modal}`}
        scrollable={true}
      >
        <Modal.Header className={`p-2`} style={{ borderBottom: "1px solid #929BBD" }}>
          <div className={`w-100 d-flex justify-content-between`}>
            <div className={`d-flex fs-16px align-items-center`}>
              <div>{activeActivityResource?.resource_data?.name}</div>
              <div>
                {activeChecklistGroupData && checklistGroupStudentList[`ck_${activeChecklistGroupData}`] && (
                  <StudentListDropdown
                    title={activeActivityResource?.resource_data?.name}
                    studentListData={checklistGroupStudentList[`ck_${activeChecklistGroupData}`]}
                    fetchGroupMemberListNext={{
                      fetchChecklistGroupMemberListNext,
                    }}
                    type={"checklist"}
                  />
                )}{" "}
              </div>
              <div>
                <FontAwesomeIcon
                  className={`pointer`}
                  onClick={() => {
                    setActiveChecklistGroupRefresh((prev) => prev + 1)
                  }}
                  icon={faRedo}
                />
              </div>
            </div>
            <div>
              <FontAwesomeIcon
                className={`pointer`}
                onClick={() => {
                  setChecklistActivitiesModal(false)
                }}
                icon={faTimesCircle}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          className={`p-0`}
          onScroll={(e) => {
            e.stopPropagation()
          }}
        >
          {activeChecklistGroupData !== null && activeActivityResource !== null && activeActivityResource?.resource_type === "checklist" && (
            <CheckListReportCard
              name={activeActivityResource?.resource_data?.name}
              resource_id={activeActivityResource?.id}
              report_card_id={activeChecklistGroupData}
              type={"group"}
              refresh={activeChecklistGroupRefresh}
              trainingWsConnection={props.trainingWsConnection}
              setWsMessage={setWsMessage}
            />
          )}
        </Modal.Body>
      </Modal>
    )
  }

  const calcBreakoutSessionDateTime = (breakoutSessionActive) => {
    let duration = breakoutSessionActive.duration
    let start_date = utcDateTime.utcDateTime(breakoutSessionActive.start_time)
    let sData = new Date(start_date)
    let val = sData.getTime()
    let sec_to_add = duration * 60 * 1000
    val = val + sec_to_add
    let end_date = utcDateTime.utcDateTime(new Date(val))
    let current_date = utcDateTime.utcDateTime("today")
    if (end_date > current_date) {
      let time_difference = end_date.getTime() - current_date.getTime()
      setTimeDifference(time_difference / 1000)
    } else if (current_date >= end_date) {
      //end breakout session
      // updateBreakoutSessionStatus(breakoutSessionActive.id, 2)
      setShowSuggestionModal(true)
    }
  }

  const stopBreakoutTimer = () => {
    if (breakoutSessionTimer.current) {
      clearInterval(breakoutSessionTimer.current)
      setMinLeft("0")
      setSecLeft("0")
      setHourLeft("0")
      setTimeDifference(0)
    }
  }

  useEffect(() => {
    if (breakoutSessionActive?.id && modulesData) {
      calcBreakoutSessionDateTime(breakoutSessionActive)
    }
    if (breakoutSessionActive == null && breakoutSessionTimer.current) {
      stopBreakoutTimer()
    }
  }, [breakoutSessionActive, modulesData])

  useEffect(() => {
    if (!showNotificationsModal) {
      setNotificationsData([])
    }
  }, [showNotificationsModal])

  function notificationsModal() {
    return (
      <Modal
        show={showNotificationsModal}
        style={{ zIndex: "10000" }}
        centered
        dialogClassName={`${Style.seek_support_modal_dialog}`}
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal p-0`}
      >
        <Modal.Header
          className={`py-1 px-1 border-none`}
          style={{
            backgroundColor: "#0D131F",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <div className={`d-inline-flex px-2 w-100`} style={{ borderRadius: "8px 8px 0px 0px" }}>
            <p className={`mb-0`} style={{ fontWeight: "500", fontSize: "19px", color: "#E0E2E5" }}>
              Notifications
            </p>
            {/* <p className={`mb-0 ml-auto`} style={{fontWeight:'500',fontSize:'19px',color:'#E0E2E5'}}><u>Mark all as read</u></p> */}
            <img
              onClick={(e) => {
                setShowNotificationsModal(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`ml-auto mr-1 mb-0 pointer`}
              style={{ objectFit: `contain`, height: `20px` }}
            />
          </div>
        </Modal.Header>
        <Modal.Body
          className="p-0"
          style={{
            backgroundColor: "#0D131F",
            borderRadius: "0px 0px 8px 8px",
            maxHeight: "400px",
            overflowY: "scroll",
          }}
        >
          <div className={`d-flex flex-column-reverse px-2 mt-1 w-100`} style={{ maxHeight: "350px", overflowY: "scroll" }}>
            {notificationsData.map((item, index) => {
              if (item.event_type === "request_more_time") {
                return (
                  <div
                    key={item.group_name + index + item.student_name}
                    className={`mb-2 w-100 d-inline-block p-2`}
                    style={{ backgroundColor: "#19253b", borderRadius: "4px" }}
                  >
                    <React.Fragment>
                      <div className={`d-inline-flex mb-1 w-100 align-items-center`}>
                        <FontAwesomeIcon className={`mb-0 mb-auto mt-1`} icon={faClock} color="#9097A0" style={{ height: "30px", width: "30px" }} />
                        <div className={`w-100 px-2`}>
                          <div className={`d-inline-flex w-100`}>
                            <p
                              className={`mb-0`}
                              style={{
                                color: "#E0E2E5",
                                fontSize: "16px",
                                marginBottom: "10px",
                              }}
                            >
                              {item.student_name} needs {item.value} Mins more{" "}
                            </p>
                            {/* <p className={`mb-0 ml-auto`} style={{color: '#E0E2E570',fontSize:'14px',fontWeight:'500'}} >45 Mins</p> */}
                          </div>
                          <p
                            className={`mb-3`}
                            style={{
                              color: "#E0E2E570",
                              fontSize: "16px",
                              marginBottom: "10px",
                            }}
                          >
                            <u style={{ fontWeight: "bold" }}>{item.student_name}</u> From{" "}
                            <span style={{ fontWeight: "bold" }}>
                              <u>{item.group_name}</u>
                            </span>{" "}
                            is requesting for additional time{" "}
                          </p>
                          <ExtendTimeReply
                            openNotification={setNotificationData}
                            updateModulesData={(val) => {
                              setModulesData(val)
                            }}
                            modulesData={modulesData}
                            breakoutSessionActive={breakoutSessionActive}
                            handleBreakoutSessionActive={(arg) => {
                              setBreakoutSessionActive(arg)
                            }}
                            activeBreakoutSession={activeBreakoutSession}
                            handleActiveBreakoutSession={(arg) => {
                              setActiveBreakoutSession(arg)
                            }}
                            template_id={props.location.template_id}
                            student_id={item.student_id}
                            duration={item.value}
                            session_id={item.session_id}
                            group_id={item.group_id}
                            group_name={item.group_name}
                            dateTime={calcBreakoutSessionDateTime}
                            setShowSuggestionModal={setShowSuggestionModal}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                )
              } else {
                return (
                  <div
                    key={item.group_name + index + item.student_name}
                    className={`mb-2 w-100 d-inline-block p-2`}
                    style={{ backgroundColor: "#19253b", borderRadius: "4px" }}
                  >
                    <React.Fragment>
                      <div className={`d-inline-flex w-100 align-items-center`}>
                        <FontAwesomeIcon className={`mb-0 mb-auto mt-1`} icon={faCommentAlt} color="#9097A0" style={{ height: "30px", width: "30px" }} />
                        <div className={`w-100 px-2`}>
                          <div className={`d-inline-flex w-100`}>
                            <div
                              className={`${Style.dangerous_data}`}
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#E0E2E5",
                              }}
                              dangerouslySetInnerHTML={{ __html: item.value }}
                            />
                            {/* <p className={`mb-0 ml-auto`} style={{color: '#E0E2E570',fontSize:'14px',fontWeight:'500'}} >45 Mins</p> */}
                          </div>
                          <p
                            className={`mb-3`}
                            style={{
                              color: "#E0E2E570",
                              fontSize: "16px",
                              marginBottom: "10px",
                            }}
                          >
                            <u style={{ fontWeight: "bold" }}>{item.student_name}</u> From{" "}
                            <span style={{ fontWeight: "bold" }}>
                              <u>{item.group_name}</u>
                            </span>{" "}
                            is asking a question{" "}
                          </p>
                          <SeekSupportInsrtuctionsReply data={item} />
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                )
              }
            })}
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function handleHidePopQuizModal(isEnd) {
    setPopQuizModal(false)
    isEnd && handleCloseTriggeredResourceWebsocket()
    setSelectedResource({})
    setResourceTypeToShow("")
  }

  function displayPopQuizResultModal() {
    return (
      <Modal
        show={popQuizModal}
        onHide={handleHidePopQuizModal}
        backdrop={`static`}
        centered={true}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        className="classroom-poll-modal"
        style={{ paddingTop: "2rem", border: "none", color: "#E0E2E5" }}
      >
        <Modal.Body
          style={{
            borderRadius: "8px",
            backgroundColor: "#212C42",
            padding: "0",
            border: updatedResource?.end_activity ?? gResource?.end_activity ? "1px solid #929BBD" : "2px solid #1CAAAA",
            boxShadow: "0px 3px 12px #00000099",
            overflowY: "auto",
          }}
        >
          {displayResource()}
        </Modal.Body>
      </Modal>
    )
  }

  function extendBreakoutSessionTimeModal() {
    return (
      <Modal
        show={showExtendTimeModal}
        onHide={() => {
          setShowExtendTimeModal(false)
        }}
        style={{ zIndex: "10000" }}
        centered
        size="md"
        className={`${StylesA.classroom_delete_modal} classroom-resource-delete-modal p-0`}
      >
        <Modal.Body
          className="float-left text-center p-3"
          style={{
            backgroundColor: "#212C42",
            borderRadius: "8px 8px 8px 8px",
          }}
        >
          <img
            onClick={(e) => {
              setShowExtendTimeModal(false)
            }}
            src={CancelWhite}
            alt={`Cancel`}
            className={`ml-auto mr-1 mb-0 pointer`}
            style={{
              objectFit: `contain`,
              height: `15px`,
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          />
          <div
            className={`d-inline-block text-center py-3 px-5`}
            style={{
              backgroundColor: "#42506C",
              borderRadius: "9px",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <p className={`mb-2`} style={{ color: "#E0E2E570", fontSize: "14px" }}>
              Extend time by
            </p>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                if (parseInt(extendDurationValue) > 0) {
                  handleSubmitExtendTime()
                }
              }}
            >
              <input
                type="number"
                min="1"
                defaultValue={extendDurationValue}
                onChange={(e) => {
                  setExtendDurationValue(e.target.value)
                  setShowNegativeError(false)
                }}
                className={`${StylesA.session_input_field} mx-auto form-control shadow-none px-2 ${StylesA.border_on_hover}`}
                style={{ width: "65px", fontSize: "36px", fontWeight: "500" }}
              />
              <p className={`mb-3`} style={{ color: "#E0E2E570", fontSize: "16px" }}>
                Mins
              </p>
              <button type="submit" className={`poppins_font px-4 py-1 ${Style.breakout_modal_fotter_btn_inactive} border_on_hover`}>
                <p className={`mb-0 poppins_font`} style={{ fontSize: "16px", fontWeight: "500" }}>
                  Extend{" "}
                </p>
              </button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div
      className={`w-100 h-100 parent ${props.minScroll ? Style.mt_90px : "mt-2"} ${
        props.videoCall === "jitsi" ? Style.wrapper_with_vc : Style.wrapper_without_vc
      }`}
    >
      {sessionBoard()}
      {sessionDetailModal()}
      {sessionEndModal()}
      {deleteItemModal()}
      {questionsModal()}
      {breakoutSessionModal()}
      {notificationsModal()}
      {caseStudyModalActivities()}
      {quizModalActivities()}
      {checklistModalActivities()}
      {displayPopQuizResultModal()}
      {extendBreakoutSessionTimeModal()}
      {activeMTFGroup && (
        <ViewDetailsModal
          activityWiseGroupsData={activityWiseMTFGroups}
          sessionData={activeBreakoutSession}
          training_id={props.location.training_id}
          resourceData={activeActivityResource}
          report_card_id={activeMTFGroupData}
          activeMTFGroup={activeMTFGroup}
          setActiveMTFGroup={setActiveMTFGroup}
          setActiveMTFGroupData={setActiveMTFGroupData}
          showViewDetailsModal={showViewDetailsModal}
          activeResourceId={props.activeActivityData?.current_active_activity?.resource?.id}
          setShowViewDetailsModal={setShowViewDetailsModal}
        />
      )}
      {props.showLiveDiscussionModal && (
        <LiveDiscussion
          show={props.showLiveDiscussionModal}
          setDiscussionBoard={props.setDiscussionBoard}
          liveboard_count={props?.liveboard_count}
          location={props.location}
          setLiveDiscussionModal={props.setLiveDiscussionModal}
          setLiveboardCount={props.setLiveboardCount}
          setActiveSection={props.setActiveSection}
          breakoutSessionActive={breakoutSessionActive}
          setActiveActivityData={props.setActiveActivityData}
          setShowActiveActivity={props.setShowActiveActivity}
        />
      )}
      {activeBreakoutSession && showEndBreakoutSessionModal ? (
        <Modal
          show={showEndBreakoutSessionModal}
          onHide={() => {
            setShowEndBreakoutSessionModal(false)
          }}
          centered
          className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
        >
          <div
            className={``}
            style={{
              backgroundColor: "#19253b",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <div className="w-100 align-items-center px-3 py-2">
              <div className={`w-100 d-inline-flex  mb-2`}>
                <FontAwesomeIcon
                  className={`pointer ml-auto`}
                  onClick={() => {
                    setShowEndBreakoutSessionModal(false)
                  }}
                  icon={faTimesCircle}
                  color="white"
                />
              </div>
              <div className={`w-100 d-inline-flex mb-2`}>
                <div className={`mx-auto d-inline-flex`}>
                  <FontAwesomeIcon className="" icon={faExclamationTriangle} color="#E0E2E5" size="sm" style={{ height: "25px" }} />
                  <p className="mb-0 ml-2" style={{ fontSize: "18px", color: "#E0E2E5" }}>
                    You still have{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {minLeft}:{secLeft}
                    </span>{" "}
                    mins left for the session to end
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Modal.Body
            className="text-center"
            style={{
              backgroundColor: "#121b2b",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <p className={`mb-0`} style={{ color: "#E0E2E5", fontSize: "18px", fontWeight: "500" }}>
              {" "}
              Are you sure you want to end
            </p>
            <p className={`mb-3`} style={{ color: "#E0E2E5", fontSize: "18px", fontWeight: "500" }}>
              {" "}
              {activeBreakoutSession.name}
            </p>
            <div className="d-flex mb-3 justify-content-center">
              <Button
                className={`${StylesB.add_button_large} py-1 px-3 border_on_hover mr-2`}
                onClick={(e) => {
                  updateBreakoutSessionStatus(activeBreakoutSession.id, 2, activeBreakoutSession?.topic ?? selectedTopic)
                }}
                variant="secondary"
                style={{
                  border: "1px solid transparent",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Yes, end now
              </Button>
              <Button
                className={`${StylesB.add_button_large} py-1 px-4 border_on_hover`}
                variant="secondary"
                onClick={() => {
                  setShowEndBreakoutSessionModal(false)
                }}
                style={{
                  border: "1px solid transparent",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      {showPollModal ? (
        <ClassRoomPoll
          create_new_poll={createNewPoll}
          topic={activeTopic}
          reset_create_poll={() => setCreateNewPoll(false)}
          live_classroom={true}
          template_id={props.location.template_id}
          poll={triggeredResource.id}
          resource={triggeredResource}
          showPollResult={true}
          show={showPollModal}
          closeModal={closePollModal}
          trainingWsConnection={props.trainingWsConnection}
          setUpdatedResource={setUpdatedResource}
          setWsMessage={setWsMessage}
          training_id={props.location.training_id}
          setActiveActivityData={props.setActiveActivityData}
        />
      ) : null}
      {activeBreakoutSession && breakoutSessionActive && showSuggestionModal ? (
        <Modal
          show={showSuggestionModal}
          onHide={() => {
            if (minLeft !== "00" && secLeft !== "00") setShowSuggestionModal(false)
          }}
          centered
          className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
        >
          <div
            className={``}
            style={{
              backgroundColor: "#19253b",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <div className="w-100 align-items-center px-3 py-2">
              {minLeft === "00" && secLeft === "00" ? null : (
                <div className={`w-100 d-inline-flex  mb-2`}>
                  <FontAwesomeIcon
                    className={`pointer ml-auto`}
                    onClick={() => {
                      setShowSuggestionModal(false)
                    }}
                    icon={faTimesCircle}
                    color="white"
                  />
                </div>
              )}
              <div className={`w-100 d-inline-flex mb-2`}>
                <div className={`mx-auto d-inline-flex`}>
                  <FontAwesomeIcon className="" icon={faExclamationTriangle} color="#E0E2E5" size="sm" style={{ height: "25px" }} />
                  <p className="mb-0 ml-2" style={{ fontSize: "18px", color: "#E0E2E5" }}>
                    {minLeft === "00" && secLeft === "00" ? (
                      `Breakout Session time ended.`
                    ) : (
                      <>
                        Your session ends in{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {minLeft}:{secLeft}
                        </span>{" "}
                        mins
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Modal.Body
            className="text-center"
            style={{
              backgroundColor: "#121b2b",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <p className={`mb-0`} style={{ color: "#E0E2E5", fontSize: "18px", fontWeight: "500" }}>
              {" "}
              {minLeft === "00" && secLeft === "00" ? ` Do you want to extend time or end it?` : ` Do you want to extend time ?`}
            </p>
            <p className={`mb-3`} style={{ color: "#E0E2E5", fontSize: "18px", fontWeight: "500" }}>
              {" "}
              {activeBreakoutSession.name}
            </p>
            <div className="d-flex mb-3 justify-content-center">
              {/* <Button className={`${StylesB.add_button_large} py-1 px-3 border_on_hover mr-2`} onClick={(e)=>{updateBreakoutSessionStatus(activeBreakoutSession.id,2);}} variant="secondary" style={{border:'1px solid transparent',fontWeight:'500',fontSize:'16px'}} >Yes, end now</Button> */}
              <ExtendTimeReply
                type="suggestion"
                openNotification={setNotificationData}
                updateModulesData={(val) => {
                  setModulesData(val)
                }}
                modulesData={modulesData}
                breakoutSessionActive={breakoutSessionActive}
                handleBreakoutSessionActive={(arg) => {
                  setBreakoutSessionActive(arg)
                }}
                activeBreakoutSession={activeBreakoutSession}
                handleActiveBreakoutSession={(arg) => {
                  setActiveBreakoutSession(arg)
                }}
                template_id={props.location.template_id}
                duration={5}
                session_id={breakoutSessionActive.session_id ? breakoutSessionActive.session_id : activeBreakoutSession.id}
                dateTime={calcBreakoutSessionDateTime}
                setShowSuggestionModal={setShowSuggestionModal}
              />
              {minLeft === "00" && secLeft === "00" ? (
                <div
                  className={`px-3 pointer border_on_hover py-1 float-left`}
                  style={{
                    marginBottom: "0px",
                    fontSize: "16px",
                    color: "#E0E2E5",
                    backgroundColor: "#303C54",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "4px",
                  }}
                  onClick={(e) => {
                    updateBreakoutSessionStatus(activeBreakoutSession.id, 2, activeBreakoutSession.topic ?? selectedTopic)
                  }}
                >
                  End
                </div>
              ) : (
                <div
                  className={`px-3 pointer border_on_hover py-1 float-left`}
                  style={{
                    marginBottom: "0px",
                    fontSize: "16px",
                    color: "#E0E2E5",
                    backgroundColor: "#303C54",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    setShowSuggestionModal(false)
                  }}
                >
                  Ignore
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div
          style={sessions && sessions.length > 0 ? { top: "150px" } : { top: "0px" }}
          className={`my-2 w-100 p-2 ${props?.trainingBreak?.status === "1" ? Style.break_lesson_board : "h-100"}`}
        >
          <div
            className="d-flex w-100 pb-3 position-absolute"
            onScroll={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            style={{ overflowX: "auto", height: "inherit" }}
          >
            {/**minWidth: "1340px",  */}
            {boardData.map((data, index) => lessonBoards(data, index + `listing`))}
          </div>
          {!displayRnd ? null : (
            <>
              <div style={{ display: "absolute", top: 0, left: 0 }}>
                {isBrowser() ? (
                  <Rnd
                    minWidth={324}
                    minHeight={191}
                    style={{ zIndex: 500 }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    bounds=".parent"
                    //default={{ x: 0, y: 0, width: 324, height: 191 }}
                    position={{ x: draggableHash.x, y: draggableHash.y }}
                    //position={{ x: 10, y: -100 }}
                    size={{
                      width: draggableHash.width,
                      height: draggableHash.height,
                    }}
                    disableDragging={rndDragDisableFlag}
                    onDragStop={(e, d) => {
                      setDraggableHash({
                        x: d.x,
                        y: d.y,
                        width: draggableHash.width,
                        height: draggableHash.height,
                      })
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                      setDraggableHash({
                        width: ref.style.width,
                        height: ref.style.height,
                        x: position.x,
                        y: position.y,
                      })
                      setRestoreDraggableHash({
                        width: ref.style.width,
                        height: ref.style.height,
                        x: position.x,
                        y: position.y,
                      })
                      if (props.leftNavOpen) props.setLeftNavOpen(false)
                    }}
                  >
                    {displayData()}
                  </Rnd>
                ) : (
                  ``
                )}
              </div>
            </>
          )}
        </div>
      </DragDropContext>
      <Notification data={notificationData} setData={setNotificationData} />
    </div>
  )
}

export default LessonBoard
